@import "../../../theme.styles.scss";

.item__blogpost {
  border-radius: 8px;
  box-shadow: $boxShadow-small;
  details {
    font-size: 14px;
    line-height: 30px;
  }
  h5 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
  }
}

.blogpost__socials {
  display: flex;
  width: 200px;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  button {
    color: $primary;
  }
  svg {
    width: 1.4em;
    height: 1.4em;
    &:hover {
      cursor: pointer;
      color: #1a82c4;
    }
  }
}
