.meet_meeting-footer {
  /* background: rgba(0, 0, 0, 0.5);  */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.meet_meeting-footerFS {
  /* background: rgba(0, 0, 0, 0.8);  */
  background: #101113;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.meet_meeting-icons {
  color: black;
  border-radius: 50%;
  background: #ebebeb;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.meet_meeting-iconsFS {
  color: white;
  border-radius: 50%;
  background: #3c4043;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.meet_active {
  background-color: #ea4335;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.3;
}

@media (max-width: 800px) {
  .meet_meeting-icons {
    width: 56px;
    height: 56px;
    svg {
      width: 22px;
      height: 22px;
    }
  }
  .meet_meeting-iconsFS {
    width: 60px;
    height: 60px;
  }
  .meet_meeting-iconsFS:nth-child(3) {
    display: none;
  }
  .meet_meeting-footerFS {
    justify-content: flex-start;
  }
}
