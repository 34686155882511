@import "../../../theme.styles.scss";
.assign {
  position: relative;

  .retroBox {
    padding: 5px !important;
    background: white !important;
    box-shadow: $boxShadow-small !important;
    border: 1px solid $bodyGray;
  }
  .avatar {
    width: 25px;
    height: 25px;
    cursor: pointer;
    box-shadow: none;
  }
  &:hover {
    .assign__nameHover {
      opacity: 1;
    }
  }
}
.assign__assigned {
  position: relative;
  display: flex;
  justify-content: center;
  &:hover {
    .tooltip {
      opacity: 1;
      width: 60px;
    }
  }
}
.assign__choose {
  // position: absolute;
  // z-index: 5;
  .bll__content {
    left: -50%;
    margin-left: -85px;
    margin-top: 5px;
  }
}
.assign__member {
  width: 120px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 8px;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    background-color: $body;
  }
  .avatar {
    margin-right: 10px;
    min-width: 35px;
    height: 35px;
  }
}
.assign__nameHover {
  position: absolute;
  width: 100px;

  text-align: center;
  background: white;
  border-radius: 8px;
  top: -15px;
  opacity: 0;
  z-index: 100;
  transition: 0.2s ease opacity;
}
.assign__remove {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  cursor: pointer;

  svg {
    opacity: 0.3;
    transition: 0.2s ease;
    &:hover {
      transform: scale(1.1);
      opacity: 0.5;
    }
  }
}
