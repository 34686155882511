@import "../../theme.styles.scss";
.space {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: 60px;
  align-items: center;
  background: $secondary2;
  z-index: 90;
}
.workspace {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 60px;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  // background: $secondary2;
  z-index: 90;
  box-sizing: border-box;
  max-height: calc(100vh - 115px);
  // overflow-y: auto;
  // overflow-x: hidden;
  // border-right: 1px solid $primary;
  // box-shadow: 1px 1px 1px rgba(54, 54, 54, 0.1),
  //   2px 2px 2px rgba(54, 54, 54, 0.2), 3px 3px 3px rgba(54, 54, 54, 0.3);
}

.Focus__timer {
  position: absolute;
  // left: 0px;
  bottom: 0px;
  // left:300px;
  z-index: 0;
}

.timer__hide {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 5px;
  // width: 40px;
  // left: -57px;
  // bottom: 140px;
  padding-bottom: 15px;
  color: $primary;
  cursor: pointer;
  // border-radius: 0px 6px 6px 0px;
  // border: 1px solid $primary;
  z-index: 150;
  // background-color: $body;
  // box-shadow: 1px 0 1px rgba(100, 100, 100, 0.2),
  //   2px 0 2px rgba(100, 100, 100, 0.3), 4px 0 4px rgba(100, 100, 100, 0.4);
  svg {
    cursor: pointer;
    transition: all 0.2s ease;
    &:hover {
      transform: scale(1.1);
      transition: all 0.2s ease;
    }
  }
}

.user__settings {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 90;
  .themeToggle {
    padding-bottom: 15px;
    background: none;
  }
}

@media (max-width: 500px) {
  .space {
    display: none;
    // display: flex;
    flex-direction: row;
    height: 90px;
    width: 100vw;
    align-items: flex-end;
    padding-top: 5px;
    border-bottom: 1px solid black;
    z-index: 86;
  }
  .workspace {
    display: flex;
    flex-direction: column-reverse;
    height: 50px;
    width: 100%;
    align-items: flex-start;
    padding-bottom: 0px;
  }
  .user__settings {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    padding-right: 15px;
    column-gap: 10px;
    .themeToggle {
      padding: 10px 15px;
    }
  }

  .timer__hide {
    // display: none;
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    position: fixed;
    bottom: 7px;
    right: 10px;
    padding-top: 15px;
    background: white;
    border-radius: 6px;
    // border: 1px solid $secondary2;
    padding: 5px 10px;
    height: 36px;
    align-items: center;
    justify-content: center;
    z-index: 201;
    width: 72px;
    font-size: 14px;
    svg {
      width: 26px;
      height: 26px;
    }
    div {
      // display: none;
      color: $primary;
      font-size: 14px;
      position: absolute;
      right: 56px;
    }
  }

  .Focus__timer {
    position: fixed;
    max-width: 60dwv;
    // display: flex;
    // position: relative;

    // right: 100px;
    // bottom: 200px;
    // right: 20px;
    // z-index: 95;
  }
}
