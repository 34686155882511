.avatar-container {
  width: 120px;
  height: 240px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.custom-avatar-btn {
  width: 100px;
  height: 50px;
}

.avatar-body,
.avatar-head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.avatar-head > * {
  position: absolute;
  margin-left: 50px;
  z-index: 1;
}

.avatar-face {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 86px;
  top: 20px;
  position: absolute;
  z-index: 2;
}
.avatar-facialhair {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 77px;
  position: absolute;
  top: 38px;
  z-index: 3;
}
.avatar-accessories {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin-left: 59px;
  top: 27px;
  z-index: 4;
}

// .avatar-preview {
//   width: 120px;
//   height: 240px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
// }

.svg-wrapper {
  width: 100%;
  height: auto;
}

.svg-wrapper svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
