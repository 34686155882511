.PlaygroundEditorTheme__ol1 {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-position: inside;
}
.PlaygroundEditorTheme__ol2 {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-type: upper-alpha;
  list-style-position: inside;
}
.PlaygroundEditorTheme__ol3 {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-type: lower-alpha;
  list-style-position: inside;
}
.PlaygroundEditorTheme__ol4 {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-type: upper-roman;
  list-style-position: inside;
}
.PlaygroundEditorTheme__ol5 {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-type: lower-roman;
  list-style-position: inside;
}
.PlaygroundEditorTheme__ul {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-position: inside;
}
.PlaygroundEditorTheme__listItem {
  margin: 0 32px;
  margin-bottom: 6px;
}
.PlaygroundEditorTheme__listItemChecked,
.PlaygroundEditorTheme__listItemUnchecked {
  position: relative;
  margin-left: 8px;
  margin-right: 8px;
  padding-left: 24px;
  padding-right: 24px;
  list-style-type: none;
  outline: none;
}
.PlaygroundEditorTheme__listItemChecked {
  text-decoration: line-through;
}
.PlaygroundEditorTheme__listItemUnchecked:before,
.PlaygroundEditorTheme__listItemChecked:before {
  content: "";
  width: 16px;
  height: 16px;
  top: 2px;
  left: 0;
  cursor: pointer;
  display: block;
  background-size: cover;
  position: absolute;
}
.PlaygroundEditorTheme__listItemUnchecked[dir="rtl"]:before,
.PlaygroundEditorTheme__listItemChecked[dir="rtl"]:before {
  left: auto;
  right: 0;
}
.PlaygroundEditorTheme__listItemUnchecked:focus:before,
.PlaygroundEditorTheme__listItemChecked:focus:before {
  box-shadow: 0 0 0 2px #a6cdfe;
  border-radius: 2px;
}
.PlaygroundEditorTheme__listItemUnchecked:before {
  border: 1px solid #999;
  border-radius: 2px;
}
.PlaygroundEditorTheme__listItemChecked:before {
  border: 1px solid rgb(61, 135, 245);
  border-radius: 2px;
  background-color: #3d87f5;
  background-repeat: no-repeat;
}
.PlaygroundEditorTheme__listItemChecked:after {
  content: "";
  cursor: pointer;
  border-color: #fff;
  border-style: solid;
  position: absolute;
  display: block;
  top: 6px;
  width: 3px;
  left: 7px;
  height: 6px;
  transform: rotate(45deg);
  border-width: 0 2px 2px 0;
}
.PlaygroundEditorTheme__nestedListItem {
  list-style-type: none;
}
.PlaygroundEditorTheme__nestedListItem:before,
.PlaygroundEditorTheme__nestedListItem:after {
  display: none;
}
