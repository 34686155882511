@import "../../theme.styles.scss";

.userProfile__page {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.userProfile {
  .avatar {
    width: 45px;
    height: 45px;
    cursor: pointer;
  }
  .bl__content {
    width: 40%;
    max-width: 450px;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.userProfile__heading {
  display: flex;
  // align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-left: 10px;
  h2 {
  font-weight: 500;
}
}
.userProfile__info {
  display: flex;
}
.userProfile__info-avatar {
  position: relative;
  .avatar {
    width: 120px;
    height: 120px;
  }
}
.userProfile__info-user {
  display: flex;
  margin-left: 30px;

  width: 100%;
  p {
    margin-bottom: 5px;
    margin-left: 10px;
  }
}
.userProfile__info-row {
  margin-right: 30px;
  width: 100%;
  &:last-child {
    margin-right: 0;
  }
}
.userProfile__logout {
  margin-right: 10px;

  svg {
    margin-right: 5px;
  }
}
.userProfile__buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.userProfile__info-email {
  padding: 5px 0px;
  border: 1px solid #f6f8f9;
  border-radius: 8px;
  cursor: not-allowed;
}
.userPorfile__setImage {
  display: flex;
  align-items: center;
  .avatar {
    margin-right: 20px;
  }
}
.userProfile__info-input {
  position: absolute;
  background: blue;
  opacity: 0;
  top: 0;
  height: 100px;
  cursor: pointer;
  width: 100px;
}
.userProfile__plan {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  // align-items: center;
  p {
    text-align: center;
    padding: none;
    margin: none;
  }
}
.userProfile__planRow {
  display: flex;
  column-gap: 5px;
  align-items: center;
  p {
    text-align: center;
    padding: none;
    margin: none;
  }
}
.userProfile__badge {
  display: flex;
  // align-items: center;
}
.userProfile__planUpgrade {
  text-align: end;
  font-size: 10px;
  color: $secondary2;
  &:hover {
    // transform: scale(1.05);
    transition: all 0.3s ease;
    color: black;
  }
}

.userSettings {
  // text-align: end;
  display: flex;
  flex-direction: column;
  h2 {
    padding-left: 10px;
  }
  h3 {
    padding-left: 10px;
    font-weight: 550;
  }
  // row-gap: 5px;
  table {
    width: 100%;
    th[scope="col"] {
      padding: 5px 10px;
      align-items: center;
      text-align: center;
    }
    th[scope="row"] {
      padding: 5px 10px;
      align-items: start;
      text-align: start;
    }
    td {
      padding: 5px 10px;
      align-items: center;
      text-align: center;
    }
  }
}

.userSettings details {
  width: 100%;
  margin: 0 auto;
  // background: #282828;
  margin-bottom: 0.5rem;
  box-shadow: 0 0.1rem 0.8rem -0.5rem rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  overflow: hidden;
  .habitline {
    padding-left: 2rem;
  }
}

.userSettings summary {
  padding: 0.5rem 1rem;
  display: flex;
  width: 100%;
  // background: #333;
  padding-left: 2.2rem;
  position: relative;
  cursor: pointer;
}

.userSettings summary:before {
  content: "\203A";
  align-items: center;
  // color: black;
  // border-width: 0.4rem;
  // border-style: solid;
  // border-color: transparent transparent transparent black;
  position: absolute;
  // top: 1.3rem;
  left: 1rem;
  transform: rotate(0);
  transform-origin: 0.2rem 50%;
  transition: 0.5s transform ease;
}

/* THE MAGIC 🧙‍♀️ */
.userSettings details[open] > summary:before {
  transform: rotate(90deg);
}

.userSettings details summary::-webkit-details-marker {
  display: none;
}

.userSettings details > ul {
  padding-bottom: 1rem;
  margin-bottom: 0;
}

.settingsTheme__buttongroup {
  display: inline-flex;
  flex-wrap: wrap; /* Allows elements to wrap onto the next line if there's not enough space */
  // flex-direction: column;
  align-items: center;
  // justify-content: space-between;
  padding: 10px 20px 30px 20px;
  column-gap: 10px;
  row-gap: 10px;
  // width: 135px;
  //   margin-right: 30px;
}

.settingsTheme__button {
  display: flex;
  justify-content: flex-start;
  column-gap: 5px;
  background: transparent;
  color: #000000;
  border: 1px solid $secondary2;
  font-size: 14px;
  text-align: center;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  svg {
    margin-right: 5px;
  }
  &:hover {
    transform: scale(1.05);
    transition: all 0.1s ease;
    background-color: $secondary3;
  }
}

.theme__silver {
  input:checked {
    background-color: #7b7b7b;
  }
  &:hover {
    transition: all 0.1s ease;
    background-color: #7b7b7b;
  }
}

.theme__craft {
  input:checked {
    background-color: #e7c078;
  }
  &:hover {
    transition: all 0.1s ease;
    background-color: #e7c078;
  }
}

.theme__paper {
  input:checked {
    background-color: #cecece;
  }
  &:hover {
    transition: all 0.1s ease;
    background-color: #cecece;
  }
}

.theme__dark {
  input:checked {
    background-color: #434343;
  }
  &:hover {
    color: white;
    transition: all 0.1s ease;
    background-color: #434343;
  }
}

@media (max-width: 500px) {
  .userProfile {
    .avatar {
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
    // .boxLayer {
    //   width: 50vw;
    //   height: 50vw;
    // }
    .bl__content {
      width: 90vw;
      height: 90vw;
    }
    .retroBox {
      width: 90vw;
      padding: 10px;
    }
  }
  .userProfile__info-user {
    flex-direction: column-reverse;
  }
}
