@import "../../../theme.styles.scss";
.footer__link {
  color: black;
  margin-bottom: 20px;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #1a82c4;
    transition: 200ms ease-in;
  }
}
