@import "../../theme.styles.scss";

// * {
//   margin: 0px;
//   box-sizing: border-box;
//   font-family: "Raleway", sans-serif;
//   font-weight: 300;
// }

.notes-app {
  /* width: 100%; */
  height: calc(100vh - 91px);
  overflow-y: auto;
}

.note-groups {
  display: flex;
  height: 100%;
  // flex-direction: column;
  // padding: 16px 0px;
  // padding: 10px 10px;
  // max-width: 1200px;
  // justify-content: space-between;
  //   columns: 4;
  //   column-gap: 6px;
  // margin: 0px auto;
  // overflow-y: auto;
  // overflow-x: hidden;
  h4 {
    padding-left: 18px;
    font-size: 1em;
    color: $secondary2;
  }
  //   @media (max-width: 1024px) {
  //     columns: 3;
  //   }
  //   @media (max-width: 768px) {
  //     columns: 2;
  //   }
  //   @media (max-width: 320px) {
  //     columns: 1;
  //   }
}

.notes__content-container {
  display: flex;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 100px);
  padding: 5px 5px;
  background: white;
  box-shadow: 2px 2px 8px 1px #00000021;
  border-radius: 0 8px 8px 8px;
}

.notes__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: white;
  // border: 1px solid $secondary2;
  margin-left: 5px;
  border-radius: 8px 0px 0px 8px;
  padding-top: 10px;
  max-height: calc(100vh - 110px);
  overflow-y: auto;
  overflow-x: hidden;
}

// .notes__content::-webkit-scrollbar {
//   display: none;
// }
// .notes__content::-webkit-scrollbar-track {
//   width: 0px;
// }

.notes__container {
  padding: 10px 18px;
  max-width: 1000px;
  columns: 4;
  column-gap: 20px;
  margin: 0px auto;
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    columns: 3;
  }
  @media (max-width: 768px) {
    columns: 2;
  }
  @media (max-width: 320px) {
    columns: 1;
  }
}

.notes-labels__container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  // height: 30px;
  // width: 120px;
  min-width: 100px;
  // align-items: flex-end;
  padding-top: 30px;
  // padding: 0px 20px;
  // margin-bottom: -1px;
  // max-width: 1200px;
  // background: white;
  // border-radius: 8px;
  // box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 6px 0px;
  // border-bottom: 1px solid $secondary2;
  // position: absolute;
  // left: 30px;
  // bottom: 40px;
  z-index: 10;
}

.notes-innerlabels {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  // align-self: center;
  // justify-self: flex-end;
  padding-right: 5px;
  border-radius: 10px;
  button {
    display: flex;
    column-gap: 10px;
  }
}

.label_filter_btn {
  background: $labelDown;
  color: $primary;
  border: 1px solid $secondary2;
  border-left: none;
  height: 24px;
  padding: 3px 8px;
  border-radius: 0px 5px 5px 0px;
  width: fit-content;
}

.label_filter_btn-active {
  background: $labelUp;
  border: 1px solid $secondary2;
  height: 24px;
  padding: 3px 8px;
  border-radius: 0px 5px 5px 0px;
  width: fit-content;
  color: black;
  font-weight: 600;
  margin-left: -10px;
  box-shadow: 2px -2px 4px -2px #00000021;
}

.labelcolumn__hide {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  height: 80px;
  width: 30px;
  right: 100px;
  top: 300px;
  padding: 6px 0px;
  color: $primary;
  border-radius: 6px 0px 0px 6px;
  border: 1px solid $secondary2;
  cursor: pointer;
  z-index: 50;
  background-color: $body;
  box-shadow: 2px 2px 8px 1px #00000021;
  svg {
    opacity: 1;
    align-self: center;
    &:hover {
      opacity: 0.7;
    }
  }
  span {
    margin-bottom: 12px;
    display: inline-block;
    transform: rotate(-90deg);
    font-size: 12px;
    font-weight: 600;
  }
  &:hover {
    transform: scale(1.05);
    transition: all 0.1s ease;
    background-color: white;
  }
}

@media (max-width: 500px) {
  .notes-app {
    height: calc(100vh - 91px);
  }
  .notes__container {
    width: calc(100vw - 20px);
  }
  .notes-labels__container {
    position: absolute;
    background: white;
    right: 0px;
    box-shadow: 2px 2px 8px 1px #00000021;
    height: 100%;
    width: 100px;
    z-index: 10;
  }
  .labelcolumn__hide {
    right: unset;
    left: -30px;
    top: 150px;
  }
}
