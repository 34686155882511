.anteditor body {
  margin: 0;
  background: #eee;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular",
    sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.anteditor {

.iframe {
  aspect-ratio: 16 / 9;
}

.other h2 {
  font-size: 18px;
  color: #444;
  margin-bottom: 7px;
}

.other a {
  color: #777;
  text-decoration: underline;
  font-size: 14px;
}

.other ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

h1 {
  font-size: 24px;
  color: #333;
}

.ltr {
  text-align: left;
}

.rtl {
  text-align: right;
}

.editor-container {
  margin: 5px auto 5px auto;
  border-radius: 2px;
  /* max-width: 600px; */
  color: #000;
  position: relative;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 5;
  display: flex;
  flex-direction: column;
  height: calc(100% - 120px);
}

.editor-inner {
  background: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.editor-input {
  /* min-height: 150px; */
  resize: none;
  font-size: 15px;
  caret-color: rgb(5, 5, 5);
  position: relative;
  tab-size: 1;
  outline: 0;
  /* padding: 10px 15px; */
  padding: 15px 10px;
  caret-color: #444;
}

.editor-placeholder {
  color: #999;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 15px;
  left: 10px;
  font-size: 15px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}

.editor-text-bold {
  font-weight: bold;
}

.editor-text-italic {
  font-style: italic;
}

.editor-text-underline {
  text-decoration: underline;
}

.editor-text-strikethrough {
  text-decoration: line-through;
}

.editor-text-underlineStrikethrough {
  text-decoration: underline line-through;
}

.editor-text-code {
  background-color: rgb(240, 242, 245);
  padding: 1px 0.25rem;
  font-family: Menlo, Consolas, Monaco, monospace;
  font-size: 94%;
}

.editor-link {
  color: rgb(33, 111, 219);
  text-decoration: none;
}

.tree-view-output {
  display: block;
  background: #222;
  color: #fff;
  padding: 5px;
  font-size: 12px;
  white-space: pre-wrap;
  margin: 1px auto 10px auto;
  max-height: 250px;
  position: relative;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: auto;
  line-height: 14px;
}

.editor-code {
  background-color: rgb(240, 242, 245);
  font-family: Menlo, Consolas, Monaco, monospace;
  display: block;
  padding: 8px 8px 8px 52px;
  line-height: 1.53;
  font-size: 13px;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  tab-size: 2;
  /* white-space: pre; */
  overflow-x: auto;
  position: relative;
}

.editor-code:before {
  content: attr(data-gutter);
  position: absolute;
  background-color: #eee;
  left: 0;
  top: 0;
  border-right: 1px solid #ccc;
  padding: 8px;
  color: #777;
  white-space: pre-wrap;
  text-align: right;
  min-width: 25px;
}
.editor-code:after {
  content: attr(data-highlight-language);
  top: 0;
  right: 3px;
  padding: 3px;
  font-size: 10px;
  text-transform: uppercase;
  position: absolute;
  color: rgba(0, 0, 0, 0.5);
}

.editor-tokenComment {
  color: slategray;
}

.editor-tokenPunctuation {
  color: #999;
}

.editor-tokenProperty {
  color: #905;
}

.editor-tokenSelector {
  color: #690;
}

.editor-tokenOperator {
  color: #9a6e3a;
}

.editor-tokenAttr {
  color: #07a;
}

.editor-tokenVariable {
  color: #e90;
}

.editor-tokenFunction {
  color: #dd4a68;
}

.editor-paragraph {
  margin: 0;
  margin-bottom: 8px;
  position: relative;
}

.editor-paragraph img {
  max-width: 60vw;
  border-radius: 4px;
}

.editor-paragraph:last-child {
  margin-bottom: 0;
}

.editor-heading-h1 {
  font-size: 24px;
  color: rgb(5, 5, 5);
  font-weight: 400;
  margin: 0;
  margin-bottom: 12px;
  padding: 0;
}
.editor-heading-h2 {
  font-size: 20px;
  color: rgb(5, 5, 5);
  font-weight: 400;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
}
.editor-heading-h3 {
  font-size: 18px;
  color: rgb(5, 5, 5);
  font-weight: 400;
  margin: 0;
  margin-bottom: 8px;
  padding: 0;
}
.editor-heading-h4 {
  font-size: 16px;
  color: rgb(5, 5, 5);
  font-weight: 400;
  margin: 0;
  margin-bottom: 6px;
  padding: 0;
}
.editor-heading-h5 {
  font-size: 14px;
  color: rgb(5, 5, 5);
  font-weight: 400;
  margin: 0;
  margin-bottom: 4px;
  padding: 0;
}

/* .editor-heading-h2 {
  font-size: 15px;
  color: rgb(101, 103, 107);
  font-weight: 700;
  margin: 0;
  margin-top: 10px;
  padding: 0;
  text-transform: uppercase;
} */

.editor-quote {
  margin: 0;
  margin-left: 20px;
  font-size: 15px;
  color: rgb(101, 103, 107);
  border-left-color: rgb(206, 208, 212);
  border-left-width: 4px;
  border-left-style: solid;
  padding-left: 16px;
}

.editor-list-ol {
  padding: 0;
  margin: 0;
  margin-left: 16px;
}

.editor-list-ul {
  padding: 0;
  margin: 0;
  margin-left: 16px;
}

.editor-list-check {
  padding: 0;
  margin: 0;
  margin-left: 16px;
}

.editor-listitem {
  margin: 8px 32px 8px 32px;
}

.editor-nested-listitem {
  list-style-type: none;
}

pre::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

pre::-webkit-scrollbar-thumb {
  background: #999;
}

.debug-timetravel-panel {
  overflow: hidden;
  padding: 0 0 10px 0;
  margin: auto;
  display: flex;
}

.debug-timetravel-panel-slider {
  padding: 0;
  flex: 8;
}

.debug-timetravel-panel-button {
  padding: 0;
  border: 0;
  background: none;
  flex: 1;
  color: #fff;
  font-size: 12px;
}

.debug-timetravel-panel-button:hover {
  text-decoration: underline;
}

.debug-timetravel-button {
  border: 0;
  padding: 0;
  font-size: 12px;
  top: 10px;
  right: 15px;
  position: absolute;
  background: none;
  color: #fff;
}

.debug-timetravel-button:hover {
  text-decoration: underline;
}

.emoji {
  color: transparent;
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin: 0 -1px;
}

.emoji-inner {
  padding: 0 0.15em;
}

.emoji-inner::selection {
  color: transparent;
  background-color: rgba(150, 150, 150, 0.4);
}

.emoji-inner::moz-selection {
  color: transparent;
  background-color: rgba(150, 150, 150, 0.4);
}

.emoji.happysmile {
  background-image: url(./images/emoji/1F642.png);
}

.toolbar {
  display: flex;
  margin-bottom: 1px;
  background: #fff;
  padding: 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  vertical-align: middle;
}

.toolbar button.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  vertical-align: middle;
}

.toolbar button.toolbar-item:disabled {
  cursor: not-allowed;
}

.toolbar button.toolbar-item.spaced {
  margin-right: 2px;
}

.toolbar button.toolbar-item i.format {
  background-size: contain;
  display: inline-block;
  height: 18px;
  width: 18px;
  margin-top: 2px;
  vertical-align: -0.25em;
  display: flex;
  opacity: 0.6;
}

.toolbar button.toolbar-item i.insert {
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  height: 18px;
  width: 18px;
  margin-top: 1px;
  margin-right: 6px;
  /* vertical-align: -0.25em; */
  display: flex;
  opacity: 0.6;
}

.toolbar button.toolbar-item:disabled i.format {
  opacity: 0.2;
}

.toolbar button.toolbar-item.active {
  background-color: rgba(223, 232, 250, 0.3);
}

.toolbar button.toolbar-item.active i {
  opacity: 1;
}

.toolbar .toolbar-item:hover:not([disabled]) {
  background-color: #eee;
}

.toolbar .divider {
  width: 1px;
  background-color: #eee;
  margin: 0 4px;
}

.toolbar select.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 70px;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
}

.toolbar select.code-language {
  text-transform: capitalize;
  width: 130px;
}

.toolbar .toolbar-item .text {
  display: flex;
  line-height: 20px;
  width: 200px;
  vertical-align: middle;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
  width: 70px;
  overflow: hidden;
  height: 20px;
  text-align: left;
}

.toolbar .toolbar-item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
}

.toolbar i.chevron-down {
  margin-top: 3px;
  width: 16px;
  height: 16px;
  display: flex;
  user-select: none;
}

.toolbar i.chevron-down.inside {
  width: 16px;
  height: 16px;
  display: flex;
  margin-left: -25px;
  margin-top: 11px;
  margin-right: 10px;
  pointer-events: none;
}

.toolbar-image-options {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

i.chevron-down {
  background-color: transparent;
  background-size: contain;
  display: inline-block;
  height: 8px;
  width: 8px;
  background-image: url(images/icons/chevron-down.svg);
}
i.plus {
  background-color: transparent;
  background-size: contain;
  display: inline-block;
  height: 18px;
  width: 18px;
  background-image: url(images/icons/plus.svg);
}

.align-controls {
  column-gap: 6px;
}
.insert-controls {
  column-gap: 6px;
}

#block-controls button:hover {
  background-color: #efefef;
}

#block-controls button:focus-visible {
  border-color: blue;
}

#block-controls span.block-type {
  background-size: contain;
  display: block;
  width: 18px;
  height: 18px;
  margin: 2px;
}

#block-controls span.block-type.paragraph {
  background-image: url(images/icons/text-paragraph.svg);
}

#block-controls span.block-type.h1 {
  background-image: url(images/icons/type-h1.svg);
}

#block-controls span.block-type.h2 {
  background-image: url(images/icons/type-h2.svg);
}

#block-controls span.block-type.h3 {
  background-image: url(images/icons/type-h3.svg);
}

#block-controls span.block-type.h4 {
  background-image: url(images/icons/type-h4.svg);
}

#block-controls span.block-type.h5 {
  background-image: url(images/icons/type-h5.svg);
}

#block-controls span.block-type.quote {
  background-image: url(images/icons/chat-square-quote.svg);
}

#block-controls span.block-type.ul {
  background-image: url(images/icons/list-ul.svg);
}

#block-controls span.block-type.ol {
  background-image: url(images/icons/list-ol.svg);
}

#block-controls span.block-type.code {
  background-image: url(images/icons/code.svg);
}

.dropdown {
  z-index: 100;
  display: block;
  position: absolute;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  margin-left: 62px;
  /* min-width: 100px; */
  padding: 5px;
  min-height: 40px;
  background-color: #fff;
}

.dropdown .item {
  /* margin: 0 8px 0 8px; */
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  /* justify-content: space-between; */
  background-color: #fff;
  border-radius: 8px;
  border: 0;
  column-gap: 10px;
  width: 100%;
  /* min-width: 158px; */
}

.dropdown .item .active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

/* .dropdown .item:first-child {
  margin-top: 8px;
}

.dropdown .item:last-child {
  margin-bottom: 8px;
} */

.dropdown .item:hover {
  background-color: #eee;
}

.dropdown .item .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  /* width: 200px; */
}

.dropdown .item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  /* margin-right: 12px; */
  line-height: 16px;
  background-size: contain;
}

.dropdown-align {
  z-index: 100;
  display: block;
  position: absolute;
  top: 40px;
  left: 450px;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  /* min-width: 100px;
  min-height: 40px; */
  background-color: #fff;
}

.dropdown-insert {
  z-index: 100;
  display: block;
  position: absolute;
  padding: 5px;
  top: 40px;
  left: 475px;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  /* min-width: 100px;
  min-height: 40px; */
  background-color: #fff;
}

.editor_modal {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 10;
}
.editor_modal-overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  /* background: rgba(0, 0, 0, 0.384); */
}

.editor_imagelink {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  /* width: 200px; */
  /* height: 200px; */
  background: white;
  border-radius: 6px;
  padding: 30px 30px 15px 30px;
  flex-direction: column;
  align-items: center;
  row-gap: 15px;
  /* border: 1px solid #cccccc; */
  z-index: 11;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

.editor_imagelink input {
  width: 240px;
  border: 1px solid #cccccc;
  padding: 5px 10px;
  border-radius: 4px;
  z-index: 11;
}

.editor_imagelink button {
  /* height: 20px; */
  border-radius: 6px;
  padding: 8px 20px;
  width: fit-content;
}
.editor_imagelink button:hover {
  background: #ebebeb;
}

.link-editor {
  position: absolute;
  z-index: 100;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  max-width: 300px;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: opacity 0.5s;
}

.link-editor .link-input {
  display: block;
  width: calc(100% - 24px);
  box-sizing: border-box;
  margin: 8px 12px;
  padding: 8px 12px;
  border-radius: 15px;
  background-color: #eee;
  font-size: 15px;
  color: rgb(5, 5, 5);
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit;
}

.link-editor div.link-edit {
  background-image: url(images/icons/pencil-fill.svg);
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.link-editor .link-input a {
  color: rgb(33, 111, 219);
  text-decoration: none;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 30px;
  text-overflow: ellipsis;
}

.link-editor .link-input a:hover {
  text-decoration: underline;
}

.link-editor .button {
  width: 20px;
  height: 20px;
  display: inline-block;
  padding: 6px;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 2px;
}

.link-editor .button.hovered {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: #eee;
}

.link-editor .button i,
.actions i {
  background-size: contain;
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: -0.25em;
}

.Collapsible__container {
  background: #fcfcfc;
  border: 1px solid #eee;
  border-radius: 10px;
  margin-bottom: 8px;
}

.Collapsible__title {
  cursor: pointer;
  padding: 5px 5px 5px 20px;
  position: relative;
  font-weight: bold;
  list-style: none;
  outline: none;
}

.Collapsible__title::marker,
.Collapsible__title::-webkit-details-marker {
  display: none;
}

.Collapsible__title:before {
  border-style: solid;
  border-color: transparent;
  border-width: 4px 6px 4px 6px;
  border-left-color: #000;
  display: block;
  content: "";
  position: absolute;
  left: 7px;
  top: 50%;
  transform: translateY(-50%);
}

.Collapsible__container[open] .Collapsible__title:before {
  border-color: transparent;
  border-width: 6px 4px 0 4px;
  border-top-color: #000;
}

.Collapsible__content {
  padding: 0 5px 5px 20px;
}

.Collapsible__collapsed .Collapsible__content {
  display: none;
  user-select: none;
}

i.undo {
  background-image: url(images/icons/arrow-counterclockwise.svg);
}

i.redo {
  background-image: url(images/icons/arrow-clockwise.svg);
}

.icon.paragraph {
  background-image: url(images/icons/text-paragraph.svg);
}

.icon.heading-1,
.icon.h1 {
  background-image: url(images/icons/type-h1.svg);
}

.icon.heading-2,
.icon.h2 {
  background-image: url(images/icons/type-h2.svg);
}

.icon.heading-3,
.icon.h3 {
  background-image: url(images/icons/type-h3.svg);
}

.icon.heading-4,
.icon.h4 {
  background-image: url(images/icons/type-h4.svg);
}

.icon.heading-5,
.icon.h5 {
  background-image: url(images/icons/type-h5.svg);
}

.icon.bullet-list,
.icon.ul {
  background-image: url(images/icons/list-ul.svg);
}

.icon.numbered-list,
.icon.ol {
  background-image: url(images/icons/list-ol.svg);
}

.icon.check-list,
.icon.check {
  background-image: url(images/icons/square-check.svg);
}
.icon.add-task {
  background-image: url(images/icons/plus-square.svg);
}

.icon.quote {
  background-image: url(images/icons/chat-square-quote.svg);
}

.icon.code {
  background-image: url(images/icons/code.svg);
}

i.bold {
  background-image: url(images/icons/type-bold.svg);
}

i.italic {
  background-image: url(images/icons/type-italic.svg);
}

i.underline {
  background-image: url(images/icons/type-underline.svg);
}

i.strikethrough {
  background-image: url(images/icons/type-strikethrough.svg);
}

i.code {
  background-image: url(images/icons/code.svg);
}

i.link {
  background-image: url(images/icons/link.svg);
}

i.left-align {
  background-image: url(images/icons/text-left.svg);
}

i.center-align {
  background-image: url(images/icons/text-center.svg);
}

i.right-align {
  background-image: url(images/icons/text-right.svg);
}

i.justify-align {
  background-image: url(images/icons/justify.svg);
}
i.indent-align {
  background-image: url(images/icons/indent.svg);
}
i.outdent-align {
  background-image: url(images/icons/outdent.svg);
}

i.youtube {
  background-image: url(images/icons/youtube.svg);
}

i.image {
  background-image: url(images/icons/image.svg);
}

i.figma {
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  height: 18px;
  width: 18px;
  margin-top: 2px;
  margin-right: 4px;
  vertical-align: -0.25em;
  display: flex;
  opacity: 0.6;
  margin-left: 2px;
  background-image: url(images/icons/figma.svg);
}

i.collapsible {
  background-image: url(images/icons/caret-right-fill.svg);
}

i.horizontalrule {
  background-image: url(images/icons/horizontal-rule.svg);
}

i.table {
  background-image: url(images/icons/table.svg);
}

i.mic {
  background-image: url(images/icons/mic.svg);
}

i.excalidraw {
  background-image: url(images/icons/excalidraw.svg);
}

.typeahead-popover {
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  width: auto;
}

.typeahead-popover ul {
  padding: 0;
  list-style: none;
  margin: 0;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: scroll;
}

.typeahead-popover ul::-webkit-scrollbar {
  display: none;
}

.typeahead-popover ul {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.typeahead-popover ul li {
  margin: 0;
  min-width: 180px;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
}

.typeahead-popover ul li.selected {
  background: #eee;
}

.typeahead-popover li {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
}

.typeahead-popover li.active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.typeahead-popover li:first-child {
  border-radius: 8px 8px 0px 0px;
}

.typeahead-popover li:last-child {
  border-radius: 0px 0px 8px 8px;
}

.typeahead-popover li:hover {
  background-color: #eee;
}

.typeahead-popover li .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  min-width: 150px;
}

.actions {
  position: absolute;
  text-align: right;
  padding: 10px;
  bottom: 20px;
  right: 0;
}

.actions i {
  background-size: contain;
  display: inline-block;
  height: 15px;
  width: 15px;
  vertical-align: -0.25em;
}

.action-button {
  background-color: #eee;
  border: 0;
  padding: 8px 12px;
  position: relative;
  margin-left: 5px;
  border-radius: 15px;
  color: #222;
  display: inline-block;
  cursor: pointer;
}

.action-button:hover {
  background-color: #ddd;
  color: #000;
}

.action-button-mic.active {
  animation: mic-pulsate-color 3s infinite;
}
button.action-button:disabled {
  opacity: 0.6;
  background: #eee;
  cursor: not-allowed;
}

}

@keyframes mic-pulsate-color {
  0% {
    background-color: #ffdcdc;
  }
  50% {
    background-color: #ff8585;
  }
  100% {
    background-color: #ffdcdc;
  }
}

.actions i {
  background-size: contain;
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: -0.25em;
}

.ExcalidrawModal__overlay {
  display: flex;
  align-items: center;
  position: fixed;
  flex-direction: column;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  flex-grow: 0px;
  flex-shrink: 1px;
  z-index: 100;
  background-color: rgba(40, 40, 40, 0.6);
}
.ExcalidrawModal__actions {
  text-align: end;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
}
.ExcalidrawModal__actions button {
  background-color: #fff;
  border-radius: 5px;
}

.ExcalidrawModal__actions button:hover {
  background-color: rgb(214, 213, 213);
}

.ExcalidrawModal__row {
  position: relative;
  padding: 40px 5px 5px;
  width: 70vw;
  height: 70vh;
  border-radius: 8px;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
}
.ExcalidrawModal__row > div {
  border-radius: 5px;
}
.ExcalidrawModal__modal {
  position: relative;
  z-index: 10;
  top: 50px;
  width: auto;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #eee;
}
.ExcalidrawModal__discardModal {
  margin-top: 60px;
  text-align: center;
}

/* ul {
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 40px;
} */

.floating-text-format-popup {
  display: flex;
  margin-bottom: 1px;
  background: #fff;
  padding: 4px;
  vertical-align: middle;
  position: absolute;
  z-index: 10;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  transition: opacity 0.5s;
  height: 35px;
  /* margin-top: -40px; */
}

.floating-text-format-popup button.popup-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  align-items: center;
}

.floating-text-format-popup button.popup-item:disabled {
  cursor: not-allowed;
}

.floating-text-format-popup button.popup-item.spaced {
  margin-right: 2px;
}

.floating-text-format-popup button.popup-item i.format {
  background-size: contain;
  display: inline-block;
  height: 18px;
  width: 18px;
  margin-top: 2px;
  vertical-align: -0.25em;
  display: flex;
  opacity: 0.6;
}

.floating-text-format-popup button.popup-item:disabled i.format {
  opacity: 0.2;
}

.floating-text-format-popup button.popup-item.active {
  background-color: rgba(223, 232, 250, 1);
}

.floating-text-format-popup button.popup-item.active i {
  opacity: 1;
}

.floating-text-format-popup .popup-item:hover:not([disabled]) {
  background-color: #eee;
}

.floating-text-format-popup select.popup-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 70px;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
}

.floating-text-format-popup select.code-language {
  text-transform: capitalize;
  width: 130px;
}

.floating-text-format-popup .popup-item .text {
  display: flex;
  line-height: 20px;
  width: 200px;
  vertical-align: middle;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
  width: 70px;
  overflow: hidden;
  height: 20px;
  text-align: left;
}

.floating-text-format-popup .popup-item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
}

.floating-text-format-popup i.chevron-down {
  margin-top: 3px;
  width: 16px;
  height: 16px;
  display: flex;
  user-select: none;
}

.floating-text-format-popup i.chevron-down.inside {
  width: 16px;
  height: 16px;
  display: flex;
  margin-left: -25px;
  margin-top: 11px;
  margin-right: 10px;
  pointer-events: none;
}

.floating-text-format-popup .divider {
  width: 1px;
  background-color: #eee;
  margin: 0 4px;
}

.dropdown-align .divider {
  width: auto;
  background-color: #eee;
  margin: 4px 8px;
  height: 1px;
}
.dropdown .divider {
  width: auto;
  background-color: #eee;
  margin: 4px 8px;
  height: 1px;
}

@media (max-width: 500px) {
  .other h2 {
    font-size: 20px;
    color: #444;
    margin-bottom: 7px;
  }

  .other a {
    color: #777;
    text-decoration: underline;
    font-size: 16px;
  }

  h1 {
    font-size: 26px;
    color: #333;
  }

  .editor-input {
    /* min-height: 150px; */
    resize: none;
    font-size: 17px;
    caret-color: rgb(5, 5, 5);
    position: relative;
    tab-size: 1;
    outline: 0;
    /* padding: 10px 15px; */
    padding: 15px 10px;
    caret-color: #444;
  }

  .editor-placeholder {
    color: #999;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: 15px;
    left: 10px;
    font-size: 17px;
    user-select: none;
    display: inline-block;
    pointer-events: none;
  }
  .editor-text-code {
    background-color: rgb(240, 242, 245);
    padding: 1px 0.25rem;
    font-family: Menlo, Consolas, Monaco, monospace;
    font-size: 94%;
  }
  .tree-view-output {
    display: block;
    background: #222;
    color: #fff;
    padding: 5px;
    font-size: 14px;
    white-space: pre-wrap;
    margin: 1px auto 10px auto;
    max-height: 250px;
    position: relative;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: auto;
    line-height: 14px;
  }
  .editor-code {
    background-color: rgb(240, 242, 245);
    font-family: Menlo, Consolas, Monaco, monospace;
    display: block;
    padding: 8px 8px 8px 52px;
    line-height: 1.53;
    font-size: 15px;
    margin: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    tab-size: 2;
    /* white-space: pre; */
    overflow-x: auto;
    position: relative;
  }
  .editor-code:after {
    content: attr(data-highlight-language);
    top: 0;
    right: 3px;
    padding: 3px;
    font-size: 12px;
    text-transform: uppercase;
    position: absolute;
    color: rgba(0, 0, 0, 0.5);
  }
  .editor-heading-h1 {
    font-size: 26px;
    color: rgb(5, 5, 5);
    font-weight: 400;
    margin: 0;
    margin-bottom: 12px;
    padding: 0;
  }
  .editor-heading-h2 {
    font-size: 22px;
    color: rgb(5, 5, 5);
    font-weight: 400;
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
  }
  .editor-heading-h3 {
    font-size: 20px;
    color: rgb(5, 5, 5);
    font-weight: 400;
    margin: 0;
    margin-bottom: 8px;
    padding: 0;
  }
  .editor-heading-h4 {
    font-size: 18px;
    color: rgb(5, 5, 5);
    font-weight: 400;
    margin: 0;
    margin-bottom: 6px;
    padding: 0;
  }
  .editor-heading-h5 {
    font-size: 16px;
    color: rgb(5, 5, 5);
    font-weight: 400;
    margin: 0;
    margin-bottom: 4px;
    padding: 0;
  }
  .editor-quote {
    margin: 0;
    margin-left: 20px;
    font-size: 17px;
    color: rgb(101, 103, 107);
    border-left-color: rgb(206, 208, 212);
    border-left-width: 4px;
    border-left-style: solid;
    padding-left: 16px;
  }
  .debug-timetravel-panel-button {
    padding: 0;
    border: 0;
    background: none;
    flex: 1;
    color: #fff;
    font-size: 14px;
  }
  .debug-timetravel-button {
    border: 0;
    padding: 0;
    font-size: 14px;
    top: 10px;
    right: 15px;
    position: absolute;
    background: none;
    color: #fff;
  }
  .toolbar select.toolbar-item {
    border: 0;
    display: flex;
    background: none;
    border-radius: 10px;
    padding: 8px;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 70px;
    font-size: 16px;
    color: #777;
    text-overflow: ellipsis;
  }
  .toolbar .toolbar-item .text {
    display: flex;
    line-height: 20px;
    width: 200px;
    vertical-align: middle;
    font-size: 16px;
    color: #777;
    text-overflow: ellipsis;
    width: 70px;
    overflow: hidden;
    height: 20px;
    text-align: left;
  }
  .dropdown .item {
    margin: 0 8px 0 8px;
    padding: 8px;
    color: #050505;
    cursor: pointer;
    line-height: 18px;
    font-size: 17px;
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 8px;
    border: 0;
    min-width: 158px;
  }
  .link-editor .link-input {
    display: block;
    width: calc(100% - 24px);
    box-sizing: border-box;
    margin: 8px 12px;
    padding: 8px 12px;
    border-radius: 15px;
    background-color: #eee;
    font-size: 17px;
    color: rgb(5, 5, 5);
    border: 0;
    outline: 0;
    position: relative;
    font-family: inherit;
  }

  .typeahead-popover ul li {
    margin: 0;
    min-width: 180px;
    font-size: 16px;
    outline: none;
    cursor: pointer;
    border-radius: 8px;
  }

  .typeahead-popover li {
    margin: 0 8px 0 8px;
    padding: 8px;
    color: #050505;
    cursor: pointer;
    line-height: 18px;
    font-size: 17px;
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-shrink: 0;
    background-color: #fff;
    border-radius: 8px;
    border: 0;
  }
  .floating-text-format-popup select.popup-item {
    border: 0;
    display: flex;
    background: none;
    border-radius: 10px;
    padding: 8px;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 70px;
    font-size: 16px;
    color: #777;
    text-overflow: ellipsis;
  }
  .floating-text-format-popup .popup-item .text {
    display: flex;
    line-height: 20px;
    width: 200px;
    vertical-align: middle;
    font-size: 16px;
    color: #777;
    text-overflow: ellipsis;
    width: 70px;
    overflow: hidden;
    height: 20px;
    text-align: left;
  }
}
