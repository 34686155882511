@import "../../theme.styles.scss";
.homeFly {
  display: flex;
  flex-direction: column;
  height: 110px;
  box-sizing: border-box;
  width: 45px;
  align-items: flex-start;
  button {
    border: none;
    margin-bottom: 10px;
    text-transform: uppercase;
    &:last-child {
      margin-bottom: 18px;
    }
  }
  p {
    color: $primary;
    opacity: 0.2;
    margin-bottom: 3px;
  }
}
.hsf__icon {
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
  button {
    border-radius: 6px 0px 0px 6px;
    &:hover {
      transform: scale(1.05) !important;
    }
  }
}

// .hsf__flysName {
//   position: absolute;
//   top: -9px;
//   p {
//     color: white;
//     font-size: 10px;
//     font-weight: 800;
//   }
// }
.sf__line {
  display: none;
  width: 100%;
  height: 2px;
  border-radius: 100%;
  background: #48575d;
  margin-bottom: 10px;
}

.hsf__controls {
  button {
    margin-right: -25px;
    width: 50px;
    background: transparent;
  }
}

.hsf__controls-home {
  margin-top: 9px;
  margin-left: 8px;
  position: relative;
  align-items: center;
  transition: all 0.8s ease-out;
  :hover {
    color: #1a82c4;
  }
  svg {
    width: 45px !important;
    height: 45px !important;
    color: $dark-500;
  }
  :active {
    transform: scale(1.05);
    transition: all 0.1s linear;
  }
}

.hsf__controls-homeArrow {
  position: absolute;
  border: none;
  background: none;
  right: -50px;
  top: 15px;
  color: $primary;
  svg {
    width: 20px !important;
    height: 20px !important;
    //   color: white;
  }
}

@media (max-width: 500px) {
  .homeFly {
    display: flex;
    flex-direction: row;
    height: 40px;
    display: none;
  }
  .hsf__controls-home {
    margin-top: 5px;
    margin-left: 5px;
    svg {
      width: 30px !important;
      height: 30px !important;
      color: $primary;
    }
  }
}
