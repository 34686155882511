.members {
  position: relative;
  display: flex;
  flex-direction: column;
}
.members__contentBox {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  // overflow-y: auto;
  background: white;
  // padding: 20px 30px 20px 20px;
  padding: 5px;
  height: calc(60vh - 201px);
  border-radius: 0px 5px 5px 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 9px 30px -10px;
}
.members__show {
  border-radius: 10px;
  margin-bottom: 10px;
  transition: 0.2s ease;
  &:last-child {
    margin-bottom: 0;
  }
}

.members__button {
  display: flex;
  // justify-content: flex-end;
  // padding: 12px 0px;
  padding: 12px 12px 12px 75px;
}

.members__workspacelevel {
  display: flex;
  flex-direction: column;
  height: 100%;
  row-gap: 10px;
  justify-content: space-between;
}

.members__list {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: space-between;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px 15px 10px 10px;
}

.members__projectlevel {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.project_members {
  // margin-bottom: 15px;
  overflow-y: auto;
}

.project_members details {
  width: 100%;
  margin: 0 auto;
  // background: #282828;
  // padding-bottom: 10px;
  margin-bottom: 0.5rem;
  box-shadow: 0 0.1rem 0.8rem -0.5rem rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  overflow: hidden;
}

.project_members summary {
  padding: 0.5rem 1rem;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 10px;
  // background: #333;
  padding-left: 1rem;
  position: relative;
  cursor: pointer;
  .EmojiIcon {
    line-height: 1em;
  }
}

.project_members summary:after {
  content: "\203A";
  align-items: center;
  // color: black;
  // border-width: 0.4rem;
  // border-style: solid;
  // border-color: transparent transparent transparent black;
  position: relative;
  // top: 1.3rem;
  left: 0.6rem;
  transform: rotate(0);
  transform-origin: 0.2rem 50%;
  transition: 0.5s transform ease;
}

/* THE MAGIC 🧙‍♀️ */
.project_members details[open] > summary:after {
  transform: rotate(90deg);
}

.project_members details summary::-webkit-details-marker {
  display: none;
}

.project_members details > ul {
  padding-bottom: 1rem;
  margin-bottom: 0;
}

@media (max-width: 500px) {
  .members {
    margin-bottom: 20px;
  }
  .members__button {
    justify-content: flex-end;
  }
  .members__contentBox {
    height: 100%;
    max-height: 80vh;
  }
}
