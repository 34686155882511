@import "../../theme.styles.scss";

.FilesView {
  display: flex;
  height: calc(100vh - 91px);
  padding: 0px 20px 10px 0px;
}

.files_files {
  display: flex;
  width: 100%;
  column-gap: 20px;
  padding: 10px;
  background: white;
  border-radius: 0px 8px 8px 8px;
  box-shadow: 0 5px 20px 0 rgba(21, 7, 38, 0.08);
}

.files_files-listmode {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 20px;
  padding: 10px;
  background: white;
  border-radius: 0px 8px 8px 8px;
  box-shadow: 0 5px 20px 0 rgba(21, 7, 38, 0.08);
  .files_file {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    border: none;
    box-shadow: none;
  }
  .files_imagefile {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 4px;
  }
  .files_file-info {
    p {
      max-width: 100%;
    }
  }
}

.files_file {
  display: flex;
  flex-direction: column;

  background: white;
  // border: 1px solid $secondary2;
  box-shadow: $boxShadow-small;
  border-radius: 6px;
  padding: 5px;
}

.files_file-info {
  display: flex;
  column-gap: 5px;
  align-items: center;
  justify-content: space-between;
  p {
    max-width: 170px;
    overflow: hidden;
    max-height: 2.2em;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0px 5px;
  }
}

.files_imagefile {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
}

.files_lightbox {
  // position: absolute;
  display: flex;
  // width: 100%;
  top: 20px;
  flex-direction: column;
  row-gap: 20px;
  background: white;
  padding: 5px;
  img {
    height: 80vh;
    width: auto;
    object-fit: contain;
  }
}

.files__drives {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  .cal__gCal-tip {
    height: 0px;
    display: none;
    opacity: 0;
    transition: all 0.6s ease;
    font-size: 12px;
  }
  &:hover {
    .cal__gCal-tip {
      height: 240px;
      display: flex;
      flex-direction: column;
      opacity: 1;
      transition: all 0.6s ease;
      ul {
        margin-left: 1em;
      }
      li {
        list-style: disc outside none;
        display: list-item;
        margin-left: 1em;
        font-size: 11px;
        padding: 4px;
      }
    }
  }
}
.files__gDrive-list {
  p {
    font-size: 14px;
  }
}
.files__gDrive-ul {
  font-size: 14px;
  padding-left: 20px;
}
// .cal__gCal-Input {
//   display: flex;
//   flex-direction: row;
//   column-gap: 5px;
//   padding-right: 20px;
//   justify-content: space-between;
//   align-items: center;
//   input {
//     width: 130px;
//     border-radius: 5px;
//     border: 1px solid $secondary3;
//     outline: none;
//     padding: 2px 3px;
//   }
//   .cal__gCal-clearBtn {
//     right: 0px;
//   }
// }

.filesListCol-showas {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.filesListCol-showas-options {
  display: flex;
  column-gap: 10px;
}

.filesListCol-radio-line {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.filesListCol {
  width: 200px;
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  overflow-y: auto;
}

.filesModal {
  width: 240px;
}

.files__list {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.files__list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.files__list-title {
  display: flex;
  column-gap: 5px;
}

.files__list-btngroup {
  display: flex;
  column-gap: 20px;
  button {
    display: flex;
  }
}
.files__savebtn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1.2em;
  background-color: $secondary3;
  border-radius: 6px;
  border: 1px solid $secondary2;
  width: fit-content;
  align-self: flex-end;
  margin-top: 10px;
  padding: 5px 15px;
  margin-bottom: 20px;
  svg {
    width: 24px;
    height: 24px;
  }
}

.files__gDrive-Form {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 10px;
  input {
    border-radius: 6px;
    padding: 5px 10px;
  }
  li {
    font-size: 12px;
  }
}

// .cal__allprojects-btn {
//   display: flex;
//   flex-direction: row;
//   column-gap: 5px;
//   align-items: center;
//   padding: 3px 5px;
//   border-radius: 8px;
//   cursor: pointer;
//   &:hover {
//     color: black;
//     background: $secondary3;
//   }
// }

.drives_container {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 200px);
  overflow-y: auto;
  // padding-bottom: 40px;
  padding: 20px 20px 50px 20px;
  row-gap: 20px;
  background: white;
  border-radius: 0 8px 8px 8px;
  height: calc(100vh - 100px);
  box-shadow: 2px 2px 8px 1px #00000021;
}

.drive_container {
  display: flex;
  flex-direction: column;
  // width: calc(100% - 200px);
  // padding: 30px;
  row-gap: 10px;
  // min-height: 42vh;
  summary {
    background: white;
    box-shadow: $boxShadow-small;
    width: fit-content;
    padding: 10px 30px;
    border-radius: 6px 6px 6px 6px;
  }
}

.no_drive_found {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  width: 100%;
  align-items: center;
  // padding: 30px;
  // margin-left: 60px;
  margin-top: 30px;
  // min-height: 42vh;
  h4 {
    width: 525px;
    line-height: 2em;
    align-items: center;
    text-align: center;
    margin-left: 90px;
  }
}

.iframe_container {
  display: flex;
  // width: calc(100% - 200px);
  height: 275px;
  background: white;
  box-shadow: $boxShadow-small;
  border-radius: 0px 8px 8px 8px;
}

iframe {
  border: none;
}

// .files__gDrive-addsection {
//   display: flex;
//   flex-direction: column;
//   width: 100%;
// }

.files__gDrive-addsection summary {
  padding: 0.5rem 1rem;
  display: flex;
  width: 100%;
  background: white;
  border-radius: 6px;
  padding-left: 2.2rem;
  position: relative;
  cursor: pointer;
}

.files__gDrive-addsection summary:before {
  content: "\203A";
  align-items: center;
  // color: black;
  // border-width: 0.4rem;
  // border-style: solid;
  // border-color: transparent transparent transparent black;
  position: absolute;
  // top: 1.3rem;
  left: 1rem;
  transform: rotate(0);
  transform-origin: 0.2rem 50%;
  transition: 0.5s transform ease;
}

/* THE MAGIC 🧙‍♀️ */
.files__gDrive-addsection details[open] > summary:before {
  transform: rotate(90deg);
}

.files__gDrive-addsection details summary::-webkit-details-marker {
  display: none;
}

.drives_container summary {
  padding: 0.5rem 1rem;
  display: flex;
  // width: 100%;
  // background: #333;
  // padding-left: 2.2rem;
  position: relative;
  cursor: pointer;
}

.drives_container summary:after {
  content: "\203A";
  align-items: center;
  // color: black;
  // border-width: 0.4rem;
  // border-style: solid;
  // border-color: transparent transparent transparent black;
  // position: absolute;
  // top: 1.3rem;
  // right: 1rem;
  margin-left: 10px;
  transform: rotate(0);
  transform-origin: 0.2rem 50%;
  transition: 0.5s transform ease;
}

/* THE MAGIC 🧙‍♀️ */
.drives_container details[open] > summary:after {
  transform: rotate(90deg);
}

.drives_container details summary::-webkit-details-marker {
  display: none;
}

.drives_container details[open] > summary {
  border-radius: 6px 6px 0px 0px;
}

@media (max-width: 500px) {
  .FilesView {
    padding: unset;
    height: calc(100vh - 130px);
    flex-direction: column-reverse;
    // row-gap: 30px;
    .calendar__settings__win {
      display: none;
    }
  }
  iframe {
    border: none;
  }
  .iframe_container {
    height: 150vh;
  }
  .filesListCol {
    // flex-direction: row;
    column-gap: 30px;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    overflow-y: unset;
  }
  .drives_container {
    width: 100%;
  }
  .drive_container {
    padding: 20px;
    width: 100%;
    height: calc(100vh - 200px);
  }
  .filesListCol-showas-options {
    flex-direction: column;
    row-gap: 20px;
  }
  .files__gDrive-addsection {
    max-width: 160px;
  }
}
