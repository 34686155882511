@import "../../../theme.styles.scss";
.firePriority {
  // border-right: 1px solid $secondary2;
  // border-top: 1px solid $secondary2;
  // border-bottom: 1px solid $secondary2;
  border: 1px solid $secondary2;
  height: 30px;
  padding: 0 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  transition: all 0.2s ease;
  cursor: pointer;
  &:hover {
    .tooltip {
      opacity: 1;
    }
  }
  &:first-child {
    border-left: 1px solid $secondary2;
    border-radius: 0px 0 0 6px;
  }
  &:last-child {
    // margin-right: 0;
    // border-right: 0;
    border-radius: 0 0px 6px 0;
  }
}
.firePriority__active {
  // background: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  background: $body;
  padding: 7px 7px;
  height: 75px;
  margin-left: 6px;
  margin-top: -2px;
  transition: all 0.2s ease;
  &:first-child {
    transform: rotate(1deg);
  }
  &:nth-child(2) {
    transform: rotate(30px);
  }
  &:nth-child(3) {
    transform: rotate(5deg);
  }
  &:last-child {
    transform: rotate(2deg);
  }
  /*   svg {
    color: white !important;
  } */
}
.firePriority__active-name {
  width: 0px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  p {
    transform: rotate(-90deg);
  }
}

.firePriority-item {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: center;

  svg {
    cursor: pointer;
    transition: 0.2s ease;
  }
}
.filter__urgent {
  color: rgb(226, 68, 92);
}
.filter__high {
  color: rgb(253, 171, 61);
}
.filter__normal {
  color: rgb(52, 181, 228);
}
.filter__low {
  color: rgb(65, 163, 0);
}


@media (max-width: 500px) { 
  .firePriority__active {
// transform: unset;
height: unset;
&:first-child {
  transform: none;
}
&:nth-child(2) {
  transform: none;
}
&:nth-child(3) {
  transform: none;
}
&:last-child {
  transform: none;
}
  }
  .firePriority__active-name {
display: none;
  }
}