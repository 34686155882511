@import "../../theme.styles.scss";

.adminpage {
  display: flex;
  //   flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: $body;
  overflow: hidden;
}

.adminpage-header {
  display: flex;
  column-gap: 50px;
  height: 45px;
  width: 100%;
  background: $secondary2;
  align-items: flex-end;
}

.admin__antlogy-logo {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 200px;
  padding-top: 5px;
  background: $secondary2;
  border-right: 1px solid $primary;
  // padding: 25px 25px;
  height: 45px;
}

.admin__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: calc(100% - 90px);
}

.admin__zerocolumn {
  background: $secondary2;
  display: flex;
  flex-direction: column;
  box-shadow: rgb(0 0 0 / 20%) 0px 9px 30px -10px;
  min-width: 200px;
  height: 100vh;
  //   border-right: 1px solid $secondary2;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  //   height: 100%;
  h3 {
    padding-left: 15px;
    font-size: 16px;
  }
}

.admin__zerocolumn-content {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 100%;
  background: $secondary3;
  border: 1px solid $primary;
  border-right: none;
  box-shadow: rgb(0 0 0 / 20%) 0px 9px 30px -10px;
  min-width: 200px;
  padding: 70px 0px 70px 15px;
  border-radius: 20px 0px 0px 0px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none; /* Firefox */
  h3 {
    padding-left: 15px;
    font-size: 16px;
  }
}

.admin__zerocolumn-content::-webkit-scrollbar {
  display: none;
}
.admin__zerocolumn-content::-webkit-scrollbar-track {
  width: 0px;
}

.admin__tab {
  padding: 5px 20px;
  border-radius: 8px 8px 0px 0px;
  background: $secondary3;
  border: 1px solid $secondary2;
  height: 30px;
}
.admin__tab-active {
  padding: 5px 20px;
  border-radius: 8px 8px 0px 0px;
  background: $body;
  border: 1px solid $secondary2;
  border-right: 1px solid $body;
  height: 30px;
  text-decoration: underline 2px $brand;
}

.admin__users {
  //   resize: both;
  display: flex;
  width: 100%;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  padding: 10px 15px;
  table {
    margin-bottom: 30px;
    border-spacing: 0;
  }
  _container {
    max-width: 750px;
    /* max-height: 500px; */
    margin: 0 auto;
    overflow: auto;
  }

  caption {
    font-style: italic;
    font-size: 90%;
    margin-bottom: 10px;
  }

  th {
    background: #fff;
    padding: 8px 10px;
    border-bottom: 1px solid #1a1a1a;
    font-weight: bold;
    text-align: left;
  }

  //   th.up {
  //     background-image: url("./images/up_arrow.png");
  //   }
  //   th.down {
  //     background-image: url("./images/down_arrow.png");
  //   }
  //   th.default {
  //     background-image: url("./images/default.png");
  //   }
  th.up,
  th.default,
  th.down {
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center right;
  }

  td {
    border-top: 1px solid #ddd;
    padding: 8px 20px;
  }

  tbody tr:first-child td {
    border-top: none;
  }

  tbody tr:nth-child(n) td {
    background: #eff0f0;
  }

  tbody tr:nth-child(2n) td {
    background: #fff;
  }
}

.admin_emailform {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  padding: 10px 15px;
}

.admin__tab-content {
  display: flex;
  width: 100%;
  height: calc(100vh - 45px);
  // padding: 20px 30px;
}

// * {
//   margin: 0px;
//   box-sizing: border-box;
//   font-family: "Raleway", sans-serif;
//   font-weight: 300;
// }

// .app {
//   width: 100%;
//   height: 100vh;
// }

// .chess__showhide {
//   position: absolute;
//   height: 34px;
//   align-items: center;
//   justify-content: center;
//   right: 160px;
//   bottom: 40px;
//   padding: 8px 12px;
//   color: $primary;
//   border-radius: 6px;
//   border: 1px solid $primary;
//   cursor: pointer;
//   z-index: 50;
//   background-color: $body;
//   box-shadow: 2px 2px 8px 1px #00000021;
//   svg {
//     opacity: 1;
//     &:hover {
//       opacity: 0.7;
//     }
//   }
// }
