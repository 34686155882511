@import "../../theme.styles.scss";
.stations {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  border-left: 1px solid $primary;
  border-top: 1px solid $primary;
  padding-top: 9px;
  padding-left: 10px;
  // border-right: 1px solid $primary;
  background-color: $secondary3;
  row-gap: 5px;
  box-sizing: border-box;
  align-items: flex-end;
  // justify-content: flex-end;
  border-radius: 20px 0px 0px 0px;
  box-shadow: inset 1px 1px 1px rgba(54, 54, 54, 0.1),
    inset 1px 2px 2px rgba(54, 54, 54, 0.1),
    inset 1px 3px 3px rgba(54, 54, 54, 0.2);
  z-index: 120;
  // overflow-x: hidden;
  // overflow-y: hidden;
  p.stations__pre {
    color: black;
    opacity: 0.3;
    margin-bottom: 3px;
    margin-top: 20px;
    width: 170px;
  }

  ::-webkit-scrollbar-track {
    background: white;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #3e3e3e;
  }
}

.stations__spacename {
  color: black;
  // opacity: 0.3;
  // padding-left: 10px;
  text-align: end;
  padding-right: 30px;
  font-size: 1em;
  font-weight: 500;
  width: 170px;
  margin-bottom: 15px;
}

.mainProjectsFolder {
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  // row-gap: 5px;
}
.stations__input {
  position: relative;
}
.stations__ok {
  position: absolute;
  right: 3px;
  top: 4px;
  button {
    width: 30px;
    height: 25px;
  }
}
.stations__createStation {
  button {
    // width: 75%;
    background: transparent;
    // margin-left: 20px;
    color: $primary;
    opacity: 0.8;
    height: 36px;
    border-width: 0px;
    transition: all 0.2s ease;
    &:hover {
      color: black;
      opacity: 1;
      transform: scale(1.05);
      background: none;
    }
  }
}
.stations__item {
  // margin-bottom: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  row-gap: 10px;
  .retroButton {
    background: $labelDown;
    width: 150px;
    // min-width: 150px;
  }
  // &:last-child {
  //   margin-bottom: 0;
  // }
}
.stations__item-active {
  // margin-bottom: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .retroButton {
    background: $labelUp;
    width: 165px;
    margin-right: -15px;
    border-right: 1px solid $body;
    border: 1px 1px 1px 0px solid $primary;
    box-shadow: 1px 1px 6px -1px #00000021;
  }
  // &:last-child {
  //   margin-bottom: 0;
  // }
}
.stations__stationItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 7px;
  // margin-left: 20px;
  // border-radius: 10px;
  margin-bottom: 10px;
}

.st_singularFolder {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  // margin-bottom: 5px;
  .retroButton {
  font-weight: 600;
  }
}

.folders__item {
  background: white;
}

.folders__item-open {
  background: black;
}

.sharedwithme {
  display: flex;
  align-items: flex-start;
}

.sharedwithme details {
  width: 100%;
  margin: 0 auto;
  // background: #282828;
  margin-bottom: 0.5rem;
  // box-shadow: 0 0.1rem 0.8rem -0.5rem rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  min-width: 150px;
  overflow: hidden;
  color: black;
}

.sharedwithme summary {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  width: 100%;
  // background: #333;
  padding-left: 0;
  position: relative;
  cursor: pointer;
  .EmojiIcon {
    line-height: 1em;
  }
}

.sharedwithme summary:after {
  content: "\203A";
  align-items: center;
  // color: black;
  // border-width: 0.4rem;
  // border-style: solid;
  // border-color: transparent transparent transparent black;
  position: relative;
  // top: 1.3rem;
  left: 0.6rem;
  transform: rotate(0);
  transform-origin: 0.2rem 50%;
  transition: 0.5s transform ease;
}

/* THE MAGIC 🧙‍♀️ */
.sharedwithme details[open] > summary:after {
  transform: rotate(90deg);
}

.sharedwithme details summary::-webkit-details-marker {
  display: none;
}

.sharedwithme details > ul {
  padding-bottom: 1rem;
  margin-bottom: 0;
}

@media (max-width: 500px) {
  .stations {
    display: flex;
    // flex-direction: row;
    background: none;
    // width: 100vw;
    width: 100%;
    // height: 60px;
    height: unset;
    border-radius: 0px;
    padding-top: 5px;
    // margin-right: 0px;
    overflow-y: visible;
    overflow-x: scroll;
    box-shadow: none;
    border-left: none;
    border-top: none;
    align-items: flex-start;
    // justify-content: space-between;
    // padding: 10px 10px 5px 5px;
    // scrollbar-color: #6969dd #e0e0e0;
    // scrollbar-width: thin;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    .stations__spacename {
      display: none;
    }
  }
  .stations::-webkit-scrollbar {
    display: none;
  }

  .stations::-webkit-scrollbar-track {
    height: 0px;
  }

  .sharedwithme-item {
    .stationItem {
      .retroButton {
        display: flex;
        width: 100%;
      }
    }
  }

  // .stations::-webkit-scrollbar-track {
  //   height: 4px;
  //   box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  // }

  // .stations::-webkit-scrollbar-thumb {
  //   height: 4px;
  //   background-color: darkgrey;
  //   outline: 1px solid slategrey;
  //   border-radius: 100px;
  // }

  .mainProjectsFolder {
    // flex-direction: row;
    padding: 0px;
    align-items: flex-start;
    // row-gap: 5px;
  }
  .stations__stationItem {
    display: flex;
    flex-direction: row;
    margin-top: none;
    margin-bottom: none;
    margin-left: none;
    margin-right: none;
  }
  .stations__item {
    margin-left: 5px;
    margin-bottom: 0px;
    .retroButton {
      width: auto;
      border-radius: 6px;
    }
  }
  .stations__item-active {
    margin-left: 5px;
    margin-bottom: 0px;
    .retroButton {
      width: auto;
      background: white;
    }
  }

  .stations__pre {
    display: none;
  }
  // .stations__createStation {
  //   display: none;
  // }
  .st_singularFolder {
    flex-direction: row;
  }
  // .stations__item-active {
  //   flex-direction: row;
  // }
}
