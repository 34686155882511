@import "../../../theme.styles.scss";
.blogposts-archive {
  display: flex;
  padding: 130px 10% 120px 10%;
  // flex-direction: column;
  column-gap: 60px;
  align-items: flex-start;
  background: white;
  // p {
  //   font-size: 17px;
  //   line-height: 30px;
  // }
  h2 {
    font-size: 32px;
    line-height: 50px;
    font-weight: 600;
  }
  h3 {
    font-size: 28px;
    line-height: 40px;
    font-weight: 400;
  }
  h4 {
    font-size: 22px;
    line-height: 36px;
    font-weight: 550;
  }
  @media (max-width: 1024px) {
    padding: 100px 40px 40px 40px;
  }
  @media (max-width: 768px) {
    padding: 100px 30px 40px 30px;
    flex-direction: column;
  }
  @media (max-width: 500px) {
    padding: 100px 15px 40px 15px;
    flex-direction: column;
  }
}

.sec__resources-heading {
  display: flex;
  flex-direction: column;
  text-align: start;
  h2 {
    font-size: 24px;
    line-height: 40px;
    font-weight: 600;
  }
  h3 {
    font-size: 20px;
    line-height: 36px;
    font-weight: 400;
  }
}

.blogarchive-sidebar {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  // padding: 10px 0px 10px 0px;
  width: 100%;
  min-width: 200px;
  h2 {
    font-size: 1.4em;
    margin-bottom: 30px;
  }
  // form {
  //   display: flex;
  //   background: white;
  //   border-radius: 6px;
  //   width: 100%;
  //   height: 40px;
  //   padding: 5px;
  // }
  form {
    //   background: white;x) translateX(-102px);
    background: white;
    border: 1px solid $secondary2;
    border-radius: 6px;
    width: 200px;
    height: 32px;
    display: flex;
    align-items: center;
    column-gap: 5px;
    padding: 8px;
    transition: width 0.3s ease-in;
    svg {
      width: 1em;
      height: 1em;
    }
    input {
      opacity: 1;
      width: 140px;
      border-radius: 6px;
      border: none;
      background: white;
      padding: 2px 5px;
      // max-width: 100px;
      transition: width 0.3s ease-in;
      &:focus {
        border: none;
        outline: none;
      }
    }
  }
}

.blog_sidebar-section {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  // align-items: flex-start;
  // justify-content: flex-start;
  h3 {
    color: $primary;
    font-size: 1.2em;
    text-decoration: underline 2px $brand;
  }
}

.blog_categories {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: flex-start;
  justify-content: flex-start;
}

.blogcategory-active {
  display: flex;
  text-align: start;
  align-items: flex-start;
  font-size: 1em;
  font-weight: 600;
  &:hover {
    color: $brand;
    // text-decoration: underline 2px $brand;
    transition: all 0.1s ease-in;
  }
}

.blogcategory {
  display: flex;
  text-align: start;
  align-items: flex-start;
  font-size: 1em;
  font-weight: 540;
  &:hover {
    color: $brand;
    // text-decoration: underline 2px $brand;
    transition: all 0.1s ease-in;
  }
}

.blog_tags {
  // display: flex;
  // flex-direction: column;
  // align-items: flex-start;
  // justify-content: flex-start;
  display: inline-block;

}

.blog_tag-btn {
  font-size: 0.7em;
  font-weight: 500;
  background: $secondary3;
  border-radius: 6px;
  padding: 5px 10px;
  margin: 0px 15px 15px 0px;
  &:hover {
    color: $brand;
    // text-decoration: underline 2px $brand;
    transition: all 0.1s ease-in;
  }
}

.blog_tag-btn-active {
  font-size: 0.7em;
  font-weight: 500;
  background: $secondary3;
  border: 1px solid $primary;
  font-weight: 600;
  border-radius: 6px;
  padding: 5px 10px;
  margin: 0px 15px 15px 0px;
  &:hover {
    color: $brand;
    // text-decoration: underline 2px $brand;
    transition: all 0.1s ease-in;
  }
}

.blogposts-container {
  // padding: 10px 18px;
  max-width: 1200px;
  columns: 3;
  column-gap: 30px;
  margin: 0px auto;
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    columns: 2;
    padding: 0px;
  }
  @media (max-width: 800px) {
    columns: 2;
    padding: 0px;
  }
  @media (max-width: 500px) {
    columns: 1;
    padding: 0px;
  }
}
// .blogposts-container {
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);
//   gap: 45px;
//   grid-auto-rows: minmax(100px, auto);
// }

.blogpost-card {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  background: white;
  border-radius: 12px;
  box-shadow: $boxShadow-small;
  padding: 10px;
  margin-bottom: 30px;
  break-inside: avoid;
  cursor: pointer;
  // width: 400px;
  // height: 300px;
  img {
    width: 100%;
    height: 240px;
    object-fit: cover;
    border-radius: 6px;
  }
  &:hover {
    box-shadow: $boxShadow-large;
    transition: all 0.1s ease-in;
    h4 {
      color: $brand;
      // text-decoration: underline 2px $brand;
      transition: all 0.1s ease-in;
    }
  }
}

.blogpost-card-text {
  overflow: hidden;
  padding: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.blogpost-card-header {
  padding: 0px 5px;
}
.blogpost-card-readtime {
  font-size: 0.8em;
  padding-left: 5px;
}
// .sec__faq {
//   display: grid;
//   width: 100%;
//   // grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
//   grid-template-columns: repeat(3, 1fr);
//   grid-gap: 40px;
//   // box-shadow: $boxShadow-small;

//   @media (max-width: 800px) {
//     grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
//     // grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
//     // grid-gap: 80px;
//   }
// }

@media (max-width: 768px) {
  // .blogposts-container {
  //   grid-template-columns: repeat(1, 1fr);
  //   gap: 30px;
  //   grid-auto-rows: minmax(100px, auto);
  // }
  .blogarchive-sidebar {
    row-gap: 0px;
    padding: 0px;
    form {
      display: none;
    }
  }
  .blog_sidebar-section {
    row-gap: 5px;
  }
  .blog_categories {
    flex-direction: row;
    overflow-y: auto;
    column-gap: 30px;
    white-space: nowrap;
    h3 {
      color: $primary;
      font-size: 1.4em;
      text-decoration: underline 2px $brand;
      margin-bottom: 20px;
    }
    button {
      // display: flex;
      // text-align: start;
    }
  }

  .blog_categories::-webkit-scrollbar {
    display: none;
  }
  .blog_categories::-webkit-scrollbar-track {
    height: 0px;
  }

  .blog_tags {
    flex-direction: row;
    overflow-y: auto;
    column-gap: 30px;
    white-space: nowrap;
    h3 {
      color: $primary;
      font-size: 1.4em;
      text-decoration: underline 2px $brand;
      margin-bottom: 20px;
    }
    button {
      // display: flex;
      // text-align: start;
      align-items: center;
      font-size: 0.8em;
      font-weight: 500;
      background: $secondary3;
      border-radius: 6px;
      padding: 5px 10px;
      margin: 0px 15px 20px 0px;
      white-space: nowrap;
      &:hover {
        color: $brand;
        // text-decoration: underline 2px $brand;
        transition: all 0.1s ease-in;
      }
    }
  }
  .blog_tags::-webkit-scrollbar {
    display: none;
  }
  .blog_tags::-webkit-scrollbar-track {
    height: 0px;
  }
}

@media (max-width: 500px) {
  .sec__resources {
    flex-direction: column;
  }
}
