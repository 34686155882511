@import "../../../theme.styles.scss";
.member {
  margin-left: 5px;
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
  cursor: pointer;
  &:hover {
    .member__delete {
      opacity: 1;
    }
    .member__noAdmin {
      svg {
        opacity: 0.1;
      }
    }
  }
}
.member__user {
  display: flex;
  align-items: center;
  transition: 0.2s ease;
  cursor: pointer;
  &:hover {
    transform: scale(1.02);
  }
  &:active {
    transform: scale(0.98);
  }
}
.prmember {
  display: flex;
  align-items: center;
  transition: 0.2s ease;
  justify-content: space-between;
  padding-right: 1rem;
  cursor: pointer;
  &:hover {
    transform: scale(1.02);
    .member__delete {
      opacity: 1;
      transition: 0.2s ease;
    }
  }
  &:active {
    transform: scale(0.98);
  }
}
.prmember__user {
  display: flex;
  align-items: center;
  column-gap: 5px;
  padding-left: 3rem;
  padding-right: 0.5rem;
}
.member__name {
  margin-left: 15px;
  p {
    font-weight: 550;
  }
}
.member__admin {
  svg {
    color: #ffdf58;
  }
}
.member__avatar {
  .avatar {
    width: 50px;
    height: 50px;
  }
}
.prmember__avatar {
  .avatar {
    width: 32px;
    height: 32px;
  }
}
.member__noAdmin {
  margin-left: 5px;
  svg {
    opacity: 0;
    &:hover {
      color: #ffdf58;
      opacity: 1 !important;
    }
  }
}
.member__controls {
  display: flex;
  column-gap: 20px;
  // margin-top: 25px;
  align-items: center;
}
.member__delete {
  opacity: 0;
  svg {
    opacity: 0.1;
    &:hover {
      opacity: 1;
      color: $danger !important;
    }
  }
}
.member__deleteBtns {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  button {
    &:last-child {
      background: $danger;
    }
  }
}
.member__addBtns {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  button {
    &:last-child {
      background: $info;
    }
  }
}

@media (max-width: 500px) {
  .member__delete {
    opacity: 1;
    svg {
      opacity: 0.1;
    }
  }

  .member__noAdmin {
    svg {
      opacity: 0.1;
    }
  }
}
