@import "../../theme.styles.scss";
.spaceFly {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  padding: 0px;
  // width: 45px;
  button {
    // margin-bottom: 10px;
    text-transform: uppercase;
    // &:last-child {
    //   margin-bottom: 18px;
    // }
  }
  p {
    color: $primary;
    opacity: 0.2;
    margin-bottom: 3px;
  }
}
.sf__icon {
  // margin-bottom: 5px;
  // &:last-child {
  //   margin-bottom: 0;
  // }
  button {
    border-radius: 6px 0px 0px 6px;
  }
}
.sf__flys {
  display: flex;
  padding-top: 15px;
  // padding-left: 22px;
  padding-bottom: 20px;
  margin-right: -1px;
  flex-direction: column;
  // margin-bottom: 0px;
  // margin-right: 0px;
  position: relative;
  row-gap: 24px;
  max-height: calc(100vh - 340px);
  overflow-x: visible;
  // overflow-y: auto;
  button {
    border: 1px solid black;
    margin-right: 0px;
    padding-left: 0;
    padding-right: 0;
    width: 36px;
    height: 36px;
    // &:hover {
    //   transition: 0.2s;
    //   width: 40px;
    // }
  }
}

// .sf__flys::-webkit-scrollbar {
//   display: none;
// }

// .sf__flys::-webkit-scrollbar-track {
//   height: 0px;
//   width: 0px;
// }

.sf__flys-shared {
  display: flex;
  // padding-top: 31px;
  padding-left: 22px;
  padding-bottom: 5px;
  flex-direction: column;
  align-items: left;
  margin-bottom: 0px;
  margin-right: 0px;
  position: relative;
  button {
    border: 1px solid black;
    margin-right: 0px;
    padding-left: 0;
    padding-right: 0;
    width: 36px;
    height: 36px;
    &:hover {
      transition: 0.2s;
      width: 40px;
    }
  }
}
.sf__flys-main {
  display: flex;
  padding-top: 31px;
  padding-left: 22px;
  padding-bottom: 15px;
  flex-direction: column;
  align-items: left;
  margin-bottom: 0px;
  margin-right: 0px;
  position: relative;
  button {
    border: 1px solid black;
    margin-right: 0px;
    padding-left: 0;
    padding-right: 0;
    width: 36px;
    height: 36px;

    &:hover {
      transition: 0.2s;
      width: 40px;
    }
  }
  .charAtFull {
    opacity: 1;
    background: none;
    border: none;
    box-shadow: none;
  }
}
.sf__flysName {
  position: absolute;
  top: -9px;
  p {
    color: white;
    font-size: 10px;
    font-weight: 800;
  }
}
.sf__line {
  display: none;
  width: 100%;
  height: 2px;
  border-radius: 100%;
  background: #48575d;
  margin-bottom: 10px;
}

.sf__controls {
  button {
    margin-right: -25px;
    width: 60px;
    background: transparent;
  }
}

// .sf__controls-home {
//   margin-right: 20px;
//   margin-top: 20px;
//   position: relative;
//   align-items: center;
//   svg {
//     width: 30px !important;
//     height: 30px !important;
//     color: $primary;
//   }
// }

// .sf__controls-homeArrow {
//   position: absolute;
//   right: -38px;
//   top: 3px;
//   color: white;
//   svg {
//     width: 30px !important;
//     height: 30px !important;
//     color: white;
//   }
// }

.sf__controls-plus {
  display: flex;
  // padding-top: 25px;
  // padding-left: 22px;
  padding-bottom: 15px;
  flex-direction: column;
  align-items: left;
  margin-bottom: 0px;
  margin-right: 0px;
  position: relative;
  button {
    border-left: 0.5px dashed $secondary;
    border-top: 0.5px dashed $secondary;
    border-bottom: 0.5px dashed $secondary;
    border-radius: 6px 0px 0px 6px;
    background: none;
    margin-right: 0px;
    padding-left: 0;
    padding-right: 0;
    width: 36px;
    height: 36px;
    &:hover {
      background: white;
      transition: 0.2s;
      width: 40px;
      border-right: 0.5px dashed $secondary;
    }
  }
  svg {
    // width: 24px !important;
    // height: 24px !important;
    color: $primary;
  }
}

.rotated__workspace {
  position: absolute;
  color: white;
  font-size: 5px;
  font-weight: 800;
  transform-origin: 0 0;
  transform: rotate(90deg);
}

@media (max-width: 500px) {
  .spaceFly {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    // justify-content: space-evenly;
    button {
      border-radius: 6px 6px 0px 0px;
      margin-bottom: 0px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .sf__flys {
    display: flex;
    height: 40px;
    flex-direction: row;
    justify-content: space-around;
    padding: 0px;
    align-items: flex-end;
    width: 100%;
  }
  .sf__flys-shared {
    display: flex;
    height: 40px;
    flex-direction: row;
    justify-content: space-around;
    padding: 0px;
    align-items: flex-end;
    width: 100%;
  }
  .sf__icon {
    // margin-left: 20px;
    // margin-bottom: 0px;
    .charAtFull {
      display: none;
    }
    .retroButton {
      margin-bottom: 0px;
      // border-bottom: 1px solid $secondary2;
    }
  }
  .sf__controls-plus {
    display: none;
  }
}
