@import "../../../theme.styles.scss";
.loadingPage {
  position: fixed;
  width: 100%;
  height: 100%;
  background: $bodyGray;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // --primary: $primary;
  z-index: 999;
}
.loadingPage__logo {
  // font-family: "Roboto", monospace;
  margin-top: -100px;
  margin-left: 20px;
  color: $primary;
}
.loadingPage__item {
  height: 100%;
  display: flex;
  justify-content: center;
  &:first-child {
    align-items: center;
  }
  &:last-child {
    align-items: center;
    padding-bottom: 30px;
  }
}
.loader {
  transform: scale(0.5);
}

/**
    * Simple CSS Clock Loader
    * @author MST
  */

  .clockloader{
    border-radius: 70px;
    border: 3px solid $primary;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
    display: block;
    width: 120px;
    height: 120px;
  }
  
  .clockloader:after{
    content: "";
    position: absolute;
    background-color: $primary;
    top: 13px;
    left: 48%;
    height: 50px;
    width: 4px;
    border-radius: 5px;
    -webkit-transform-origin: 50% 97%;
        transform-origin: 50% 97%;
    -webkit-animation: grdAiguille 2s linear infinite;
        animation: grdAiguille 2s linear infinite;
  }
  
  @-webkit-keyframes grdAiguille{
      0%{-webkit-transform:rotate(0deg);}
      100%{-webkit-transform:rotate(360deg);}
  }
  
  @keyframes grdAiguille{
      0%{transform:rotate(0deg);}
      100%{transform:rotate(360deg);}
  }
  
  .clockloader:before{
    content: "";
    position: absolute;
    background-color: $primary;
    top: 24px;
    left: 48%;
    height: 40px;
    width: 4px;
    border-radius: 5px;
    -webkit-transform-origin: 50% 94%;
        transform-origin: 50% 94%;
    -webkit-animation: ptAiguille 12s linear infinite;
        animation: ptAiguille 12s linear infinite;
  }
  
  @-webkit-keyframes ptAiguille{
      0%{-webkit-transform:rotate(0deg);}
      100%{-webkit-transform:rotate(360deg);}
  }
  
  @keyframes ptAiguille{
      0%{transform:rotate(0deg);}
      100%{transform:rotate(360deg);}
  }
