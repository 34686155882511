@import "../../../theme.styles.scss";
.st__emojicon-name-row {
  display: flex;
  justify-content: center;
  align-items: center;
  label {
    font-size: 10px;
    color: $primary;
    padding: 2px 12px;
    // margin-bottom: 16px;
  }
  // button {
  //   margin-bottom: 10px;
  // }
}
.st__emojiconwithlabel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.sn__name {
  // margin-top: 10px;
  // margin-bottom: 20px;
  display: flex;
  justify-content: center;
  p {
    margin-bottom: 5px;
  }
  input {
    width: fit-content;
  }
}
.sn__next {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  .retroButton {
    border-radius: 6px;
    width: 100px;
    &:hover {
      transform: scale(1.05);
      transition: all 0.1s ease;
      background-color: $secondary3;
    }
  }
}

.sn__icon {
  width: 100%;
  display: flex;
  justify-content: center;
  svg {
    color: $primary;
  }
}

.cr__duration-label {
  display: flex;
  align-items: center;
  justify-content: center;
  label {
    font-size: 10px;
    color: $primary;
    padding: 2px 12px;
  }
}

.cr__duration-buttons {
  display: flex;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  button {
    font-size: 10px;
    background: $secondary3;
    border-radius: 4px;
    padding: 4px;
  }
}

.cr__twocolumnsnarrow {
  display: flex;
  flex-direction: row;
  // width: 150px;
  justify-content: center;
  column-gap: 20px;
  margin-bottom: 30px;
  input {
    width: 130px;
    font-size: 12px;
    border: 0.5px solid $secondary2;
    border-radius: 4px;
    padding: 0.4rem 0.7rem;
  }
  label {
    font-size: 10px;
    color: $primary;
    padding: 2px 12px;
  }
}

@media (max-width: 500px) {
  .sn__name {
    input {
      width: 100% !important;
    }
  }
}
