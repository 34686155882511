@import "../../theme.styles.scss";
.station {
  height: 100vh;
  min-width: 180px;
  background: $secondary2;
  color: white;
  position: relative;
  margin-top: 0px;
}

.station__headermock {
  height: 80px;
  background: $secondary2;
  // padding: 25px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid $primary;
  // border-radius: 20px 0px 0px 0px;
}

.uniquelinkdockstation {
  position: absolute;
  width: 26px;
  height: 26px;
  left: 105px;
  top: 32px;
  border-radius: 50%;
  padding: 3px 5px;
  background: none;
  color: $body;
  font-size: 0.6em;
}

.station__headermockmobile {
  display: none;
  // height: 90px;
  // background: $secondary2;
  // padding: 25px 25px;
  // display: flex;
  // align-items: flex-start;
  // justify-content: center;
  // border-right: 1px solid $primary;
  // border-radius: 20px 0px 0px 0px;
}

.station__miniMenu {
  .boxLayer {
    align-items: flex-start;
    position: absolute;
    width: 100%;
    padding-top: 90px;
  }
  .bl__layer {
    margin-top: -90px;
  }
  .retroBox {
    padding: 5px;
    width: 220px;
  }
}

.station__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.station__name {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  h3 {
    margin-left: 10px;
    margin-top: -5px;
  }
  button {
    width: 40px;
    height: 40px;
  }
}
.station__menu {
  position: relative;
  button.flat {
    width: 35px;
    padding: 0px 9px;
    height: 20px;
    border-radius: 8px;
    background: $secondary2;
    color: white;
  }
  svg {
    margin: 0;
  }

  .bl__content {
    margin-top: 70px;
  }
}
.breadcrumb__arrow1 {
  position: fixed;
  left: 64px;
  top: 30px;
  padding: 0 5px;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  z-index: 85;
  svg {
    color: $primary;
    opacity: 1;
    &:hover {
      opacity: 0.7;
    }
  }
}
.breadcrumb__arrow2 {
  position: absolute;
  right: 6px;
  top: 30px;
  padding: 0 5px;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  z-index: 85;
  svg {
    color: $primary;
    opacity: 1;
    &:hover {
      opacity: 0.7;
    }
  }
}
.station__hide {
  position: absolute;
  right: -2px;
  top: 93px;
  padding: 0 5px;
  color: white;
  border-radius: 10px;
  transition: 0.3s;
  cursor: pointer;
  z-index: 90;
  svg {
    color: $secondary2;
    opacity: 1;
    &:hover {
      opacity: 0.7;
    }
  }
}

.station__hideicon__rotated {
  padding: 0;
  margin-top: 0;
  // transform: scaleX(-1);
  top: 55px;
  svg {
    color: $primary;
  }
}

@media (max-width: 500px) {
  .station {
    display: none;
    // display: flex;
    height: 60px;
    width: 100vw;
    flex-direction: row;
    background: none;
    // box-shadow: 1px 1px 1px rgba(54, 54, 54, 0.1),
    //   2px 2px 2px rgba(54, 54, 54, 0.2), 3px 3px 3px rgba(54, 54, 54, 0.3);
    box-shadow: 0px 2px 3px -2px rgba(0, 0, 0, 0.4);
    z-index: 85;
  }
    .station::-webkit-scrollbar {
    display: none;
  }
  .station__headermock {
    display: none;
    position: absolute;
    top: -82px;
    left: 10px;
    height: 40px;
    padding: 0px 0px;
    z-index: 100;
    border-right: none;
    background: none;
    img {
      height: 32px;
    }
  }
  .station__headermockmobile {
    padding: 25px 25px;
    display: flex;
    position: absolute;
    // top: -82px;
    left: 10px;
    height: 40px;
    padding: 0px 0px;
    z-index: 100;
    border-right: none;
    background: none;
    img {
      height: 32px;
    }
  }
  // .station__hide {
  //   display: none;
  // }
  .station__hideicon {
    display: none;
  }
  .station__hideicon__rotated {
    display: none;
  }
}
