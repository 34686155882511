.daysLeft {
  position: relative;
  display: flex;
  justify-content: center;

  &:hover {
    .tooltip {
      opacity: 1;
      width: 60px;
      display: flex;
      justify-content: center;
    }
  }
  svg {
    opacity: 0.1;
  }
}

.tm__durationest {
  p {
    padding: 2px;
  }
}
