.createDemo {
  padding: 30px;
  width: 400px;
}

.createDemo__button {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
@media (max-width: 500px) {
  .createDemo {
    width: 100%;
  }
}
