@import "../../../theme.styles.scss";
.personFilter {
  // background: $body;
  height: 36px;
  display: flex;
  align-items: flex;
  min-width: 90px;
  border-radius: 6px 6px 0px 0px;
  border: 1px solid $secondary2;
  padding: 10px;
  margin-left: 10px;
  &:hover {
    background: $body;
    .personFilter__select {
      p {
        opacity: 1;
      }
    }
  }
}
.personFilter__select,
.personFilter__false,
.personFilter__true {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  p {
    padding: 5px;
    opacity: 0.5;
  }
}
.personFilter__true  {
  background: white;
  border-radius: 6px 6px 0px 0px;
  padding: 3px;
}
.personFilter__false  {
  background: none;
}
.personFilter__pop {
  position: absolute;
  .retroBox {
    padding: 5px;
    width: 120px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-left: -10px;
    margin-top: 26px;
    border-radius: 0px 0px 6px 6px;
  }
}
.personFilter__member {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
  padding: 5px;
  border-radius: 6px;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    background: $body;
  }
  .avatar {
    margin-right: 10px;
    min-width: 25px !important;
    height: 25px;
  }
}

.personFilter__true {
  .avatar {
    margin-right: 5px;
  }
}

.personFilter__remove {
  cursor: pointer;
  svg {
    color: $primary;
    opacity: 0.9;
  }
}


@media (max-width: 500px) {
  .personFilter__pop {
    position: fixed;
    .retroBox {
      padding: 5px;
      width: 120px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-left: -10px;
      margin-top: 26px;
      border-radius: 0px 0px 6px 6px;
    }
  }
}
