/* $primary: #26a69a;
$secondary: #37484f;
$secondary2: #415761; */

//Default
//Craft
//Paper
//Dark
//Noir

:root {
  --background-color: white;
  --text-color: black;
  --primary: #000;
  // --primary: #3b3b3b;
  --secondary: #1d1d1d;
  --secondary2: #cccccc;
  --secondary3: #dddddd;

  --text-color1: black;
  --text-color2: #3b3b3b;
  --bg-color1: #cccccc;
  --bg-color2: #ebebeb;
  --body1: #fafafa;
  --body2: #e8eaeb;
  --brand: #1a82c4;
}

body[data-theme="silver-theme"] {
  --primary: #000;
  --secondary: #1d1d1d;
  --secondary2: #cccccc;
  --secondary3: #ebebeb;
  --labelUp: #ffffffcc;
  --labelDown: #ffffff66;
}

body[data-theme="craft-theme"] {
  --primary: #000;
  --secondary: #1d1d1d;
  --secondary2: url("./assets/textures/seamless_paper_texture.webp");
  --secondary3: #f1f0e8;
  --labelUp: #f9e9b966;
  --labelDown: #f9e9b933;
}

body[data-theme="paper-theme"] {
  --primary: #000;
  --secondary: #1d1d1d;
  --secondary2: #dddddd;
  --secondary3: #eeeeee;
  --labelUp: #ffffffcc;
  --labelDown: #ffffff66;
}

body[data-theme="rouge-theme"] {
  --primary: #000;
  --secondary: #1d1d1d;
  --secondary2: #d3656c;
  --secondary3: #fff1f5;
  --labelUp: #ffffffcc;
  --labelDown: #ffffff66;
}

body[data-theme="ocean-theme"] {
  --primary: #000;
  --secondary: #1d1d1d;
  --secondary2: #8baec1;
  --secondary3: #f3fcff;
  --labelUp: #ffffffcc;
  --labelDown: #ffffff66;
}

body[data-theme="dark-theme"] {
  --primary: #fff;
  --secondary: #1d1d1d;
  --secondary2: #141517;
  --secondary3: #25262b;
  --labelUp: #000000cc;
  --labelDown: #00000066;
}

// * {
//   transition: all 0.3s ease-in;
// }

$text: var(--text-color);
$primary: var(--primary);
$secondary: var(--secondary);
$secondary2: var(--secondary2);
$secondary3: var(--secondary3);
$brand: var(--brand);
$success: #408140;
$warning: #fbcb00;
$danger: #c8001e;
$info: #34b5e4;
$info-light: #e9f8ff;

$body: #fafafa;
$bodyGray: #e8eaeb;

$labelUp: var(--labelUp);
$labelDown: var(--labelDown);

$boxShadow-small: 0 5px 20px 0 rgb(21 7 38 / 8%);
$boxShadow-large: rgba(0, 0, 0, 0.2) 0px 9px 30px -10px;

$dark-000: #c1c2c5;
$dark-100: #a6a7ab;
$dark-200: #909296;
$dark-300: #5c5f66;
$dark-400: #373a40;
$dark-500: #2c2e33;
$dark-600: #25262b;
$dark-700: #1a1b1e;
$dark-800: #141517;
$dark-900: #101113;

$folder: #e5e5e5;

.dark-mode {
  color: white;
  background-color: $dark-700;

  .workspace {
    background-color: $dark-900;
  }

  .welcomeHome {
    background-color: $dark-900;
  }

  .space {
    background-color: $dark-900;
    svg {
      color: $body;
    }
  }

  .themeToggle {
    svg {
      color: $body;
    }
  }

  .dockHeader {
    background-color: $dark-900;
  }

  .dH__upperRow {
    background: $dark-900;
  }

  .dH__bottomRow {
    background: $dark-800;
    p {
      color: $secondary2;
    }
  }

  .wH__bottomRow {
    background: $dark-700;
    p {
      color: $secondary2;
    }
  }

  .station__headermock {
    background-color: $dark-900;
    img {
      border-radius: 6px;
      background: white;
    }
  }

  .station__headermockmobile {
    background-color: $dark-900;
    z-index: 100;
    img {
      border-radius: 6px;
      background: white;
    }
  }

  .homeIconAll {
    background-color: $dark-900;
    img {
      border-radius: 6px;
      background: white;
    }
  }

  .app {
    background-color: $dark-700;
  }

  .homeIcon {
    background-color: $dark-700;
  }

  .rs__item {
    .children {
      color: $body;
    }
  }

  .member__name {
    p {
      color: $body;
    }
  }

  .breadcrumb__arrow1 {
    svg {
      color: $body;
    }
  }
  .breadcrumb__arrow2 {
    svg {
      color: $body;
    }
  }

  .retroBox {
    color: white;
    background-color: $dark-900;
    svg {
      color: $body;
    }
  }
  .sd__btn {
    color: white;
    background-color: $dark-700;
    svg {
      color: $body;
    }
  }
  .sd__createNewSpace {
    .retroButton {
      color: white;
      background-color: $dark-700;
      svg {
        color: $body;
      }
    }
  }

  .firePriority {
    background-color: $dark-700;
    border: 1px solid $dark-400;
  }
  .timeFilter {
    border-top: none;
  }
  .timeFilter-item {
    background-color: $dark-700;
    border: 1px solid $dark-400;
    p {
      color: white;
    }
  }

  .modules {
    .modules__button {
      button {
        background-color: $dark-700;
        border: none;
        svg {
          color: $body;
        }
      }
    }
  }

  .personFilter {
    background-color: $dark-700;
    p {
      color: white;
    }
    svg {
      color: $body;
    }
  }
  .personFilter__member {
    &:hover {
      background-color: $dark-700;
    }
  }

  .moduleList {
    background-color: $dark-700;
  }

  .recentStations__create {
    .retroButton {
      color: white;
      background-color: $dark-700;
      svg {
        color: $body;
      }
    }
  }

  .meetingVideoChat {
    background: $dark-700;
  }

  .chat {
    color: white;
    background: $dark-800;
    svg {
      color: $body;
    }
    h3 {
      color: $body;
    }
    p {
      color: $body;
    }
  }

  .message__msg {
    background: $dark-600;
  }

  .ch__writeUpdate {
    background: $dark-700;
  }

  // .ch__writeUpdate-msgs:after {
  //   position: absolute;
  //   bottom: 0;
  //   height: 100%;
  //   width: 100%;
  //   content: "";
  //   background: linear-gradient(
  //     to top,
  //     rgba(1, 1, 1, 0) 40%,
  //     rgba($dark-700, 1) 90%
  //   );
  //   pointer-events: none; /* so the text is still selectable */
  // }

  .ch__sendtomode-tab-active {
    color: white;
    background: $dark-900;
    border: 1px solid $dark-900;
    border-bottom: 1px solid $dark-900;
  }
  .ch__sendtomode-tab {
    color: white;
    background: $dark-700;
    border: 1px solid $dark-900;
    border-bottom: 1px solid $dark-700;
  }
  .ch__sendtomode-tabs {
    background: $dark-700;
  }
  .ch__personNonselected {
    color: lightgray;
  }
  .ch__writeUpdate-send {
    border-top: 1px solid $dark-700;
  }
  .ch__breadcrumb-header {
    color: white;
    background: $dark-800;
  }

  .ch__writeUpdate-message {
    border: 1px solid $dark-900;
    border-radius: 0px 0px 10px 10px;
    textarea {
      color: white;
      background: $dark-900;
    }
  }

  .chat__hide {
    background-color: $dark-800;
    svg {
      color: white;
    }
  }

  .chat__hideMoved {
    background-color: $dark-800;
    svg {
      color: white;
    }
  }
  .notes__form-addbutton {
    background-color: $dark-800;
    svg {
      color: white;
    }
  }

  .meetingVideoChat-mockheader {
    background-color: $dark-900;
  }

  .addtask__plus {
    background-color: $dark-800;
    svg {
      color: white;
    }
  }

  .addnotebutton {
    background-color: $dark-700;
    svg {
      color: white;
    }
  }

  .home {
    background-color: $dark-900;
  }

  .home__content {
    background-color: $dark-700;
  }

  .allHome {
    background-color: $dark-700;
  }

  .homeIconAll {
    background-color: $dark-900;
  }

  .homeIconLeft {
    background-color: $dark-900;
  }

  .station {
    background-color: $dark-900;
    .retroButton {
      color: white;
      background-color: $dark-700;
      svg {
        color: $body;
      }
      h3 {
        color: $body;
      }
      p {
        color: $body;
      }
    }
  }

  .sm__upperRow {
    background-color: $dark-900;
  }

  .sm__buttomRow {
    background-color: $dark-800;
  }

  .stations {
    background-color: $dark-800;
  }

  .station__hideicon {
    svg {
      color: $body;
    }
  }

  .stations__pre {
    background-color: $dark-900;
  }

  .stationMenu {
    background-color: $dark-900;
  }

  .stationItem {
    a {
      color: $body;
    }
  }
  .stationIteminFolder {
    a {
      color: $body;
    }
  }

  .dh__name {
    input {
      &::placeholder {
        color: $body;
      }
      &:focus {
        border-bottom: 1px dotted gray;
        &::placeholder {
          color: gray;
        }
      }
    }
  }

  .si__name {
    input {
      &::placeholder {
        color: $dark-100;
      }
      &:focus {
        border-bottom: 1px dotted gray;
        &::placeholder {
          color: gray;
        }
      }
    }
  }

  .breadcrumb__spaceName {
    color: $dark-100;
  }

  .views__view {
    color: white;
    &:hover {
      background: gray;
      border-bottom: 1px solid #fafafa;
    }
    p {
      color: white;
    }
  }

  .task {
    color: $body;
    background-color: $dark-700;
    .task__taskName {
      p {
        color: $body;
      }
    }
  }
  .taskList {
    color: $body;
    background-color: $dark-700;
    .task__listcard {
      p {
        color: $body;
      }
    }
  }

  .calendar_filters-buttons {
    button {
      color: $body;
    }
  }

  .gantt__button {
    color: $body;
  }

  .chess__button {
    color: $body;
  }

  .chess__buttongroup {
    label {
      color: $body;
    }
  }

  .chess__options {
    background: $dark-900;
  }

  .chess__option {
    background: $dark-900;
    color: $dark-500;
  }

  .chess__option-active {
    background: $dark-700;
    color: $body;
  }

  .ch__writeUpdate-buttongroup {
    background: $dark-900;
  }

  .ch__writeUpdate-buttongroup-sub {
    background: $dark-900;
  }

  .ch__writeUpdate-message .ch__writeUpdate-btn .retroButton {
    background: $dark-900;
    a {
      color: $body;
    }
  }

  .person__notifications {
    background: $dark-700;
    p {
      color: $body;
    }
  }

  .notifications__badge {
    background: $dark-600;
  }

  .app__notifications {
    svg {
      color: $body;
    }
  }

  .custom__chart {
    background: $dark-900;
    color: $body;
  }

  .chartBtn {
    // background: $dark-600;
    color: $dark-300;
    border: 1px solid $dark-300;
    border-top: 1px solid $dark-600;
  }

  .chartBtn-active {
    background: $dark-900;
    color: $body;
    border: 1px solid $dark-300;
    border-top: 1px solid $dark-600;
  }

  .list__column {
    background: $dark-600;
    p {
      color: $body;
    }
  }
  #external-events {
    li {
      background: $dark-800;
      color: $body;
    }
  }

  .calexternal_duration {
    p {
      background: $dark-600;
      color: $body;
    }
  }

  .taskList {
    color: $body;
    background-color: $dark-700;
    .tl__task {
      p {
        color: $body;
      }
    }
  }

  .dh__description {
    textarea {
      color: $body;
      background-color: $dark-700;
    }
    .retroButton {
      color: $body;
      background-color: $dark-700;
    }
  }

  .foldersItem {
    background: $dark-600;
  }

  .wH__everything {
    h2 {
      color: $secondary3;
    }
  }

  .wrapper {
    color: $body;
    background-color: $dark-700;
    .clock {
      background-color: $dark-700;
    }
    .clock__title {
      color: $body;
    }
    .clock__value {
      color: $body;
    }
    .btn__icon {
      color: $body;
    }
    .length__title {
      color: $body;
    }
    .length__time {
      color: $body;
    }
    .btn {
      color: $body;
    }
    .btn__type--active {
      background-color: $dark-500;
    }
  }

  .modal-content {
    background: $dark-700;
    box-shadow: 4px 4px 12px 2px #a9a9a921;
  }
  .modal-content-full {
    background: $dark-700;
    box-shadow: 4px 4px 12px 2px #a9a9a921;
  }

  //gantt
  ._2dZTy {
    background: none;
  }
  .rows {
    background: none;
  }
  .fc-daygrid-dot-event .fc-event-title {
    color: $secondary3;
  }
  .fc-direction-ltr .fc-daygrid-event .fc-event-time {
    color: $secondary2;
  }
  .fc .fc-button-primary {
    background: $dark-900;
    color: white;
    border-color: $dark-600;
  }
  .fc .fc-daygrid-day-number {
    color: $secondary2;
  }
  .fc .fc-daygrid-day.fc-day-today {
    background: $dark-600;
  }
  .fc-col-header {
    background: $dark-800;
  }
  .fc .fc-col-header-cell-cushion {
    color: white;
  }
  .fc-theme-standard .fc-list-day-cushion {
    background: $dark-800;
  }
  .fc-direction-ltr .fc-list-day-text,
  .fc-direction-rtl .fc-list-day-side-text {
    color: white;
  }

  .mobileMenu {
    background: $dark-700;
    .retroButton {
      background: $dark-700;
      svg {
        color: $body;
      }
    }
  }
  .mobileMenu__icon {
    background: $dark-700;
  }

  .timer__hide {
    color: white;
    svg {
      color: $body;
    }
  }

  .assignedTasks {
    background: $dark-900;
    .retroButton.flat {
      background: $dark-800;
    }
  }

  .flat {
    color: white;
    background-color: $dark-700;
    svg {
      color: $body;
    }
  }
  .at__content {
    p {
      color: white;
    }
    svg {
      color: $body;
    }
  }
  .asgntaskwidgetheader {
    background-color: $dark-900;
    color: white;
    svg {
      color: $body;
    }
  }
  .widgetheader {
    background-color: $dark-900;
    color: white;
  }
  .journal {
    color: white;
    background-color: $dark-900;
  }
  .journal__btn-group {
    button,
    input {
      color: white;
      background-color: $dark-700;
    }
  }
  .editor-inner {
    p {
      color: $body;
    }
    background-color: $dark-900;
    .action-button {
      background: $dark-700;
      i {
        color: white;
      }
    }
  }
  .journal .PlaygroundEditorTheme__ul {
    color: $body;
  }
  .journal .PlaygroundEditorTheme__ol1 {
    color: $body;
  }
  .journal__form-button {
    color: white;
  }
  .habitcontent {
    color: white;
    background-color: $dark-900;
  }
  .widgetbtns {
    color: white;
    background-color: $dark-900;
    button {
      background: $dark-700;
      color: white;
    }
  }
  .habit__btn-group {
    button,
    input {
      color: white;
      background-color: $dark-700;
    }
  }
  .minical {
    background-color: $dark-900;
  }
  .minicalwidgetheader {
    color: white;
    background-color: $dark-900;
  }
  .habit-content {
    color: white;
    background-color: $dark-600;
  }
  .notes__form-content {
    background-color: $dark-900;
  }
  .editor-inner {
    color: white;
    background-color: $dark-900;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span {
      color: white;
    }
    .editor-text-code {
      background: $dark-600;
      color: white;
    }
    .editor-code {
      background: $dark-600;
      color: white;
    }
    .editor-code:before {
      background: $dark-400;
      color: white;
    }
  }
  .notes__form-actions {
    background: $dark-800;
    color: white;
  }
  .notes__form-input-title {
    color: white;
    background-color: $dark-900;
  }
  .editor-container {
    .toolbar {
      background: $dark-800;
    }
    .toolbar-item {
      background: $dark-100;
      p {
        color: white;
      }
    }
    .action-button-mic {
      color: $body;
    }
  }
  .notes__form-image-delete-btn {
    color: white;
    background: $dark-500;
    i {
      svg {
        color: white;
      }
    }
  }
  .action-button {
    color: white;
  }
  .habits {
    background: $dark-900;
  }
  .workspace__journal {
    background: $dark-900;
  }
  .assignedTasksHide {
    background: $dark-900;
  }
  .drive_container {
    summary {
      background: $dark-800;
    }
    .iframe_container {
      background: $dark-800;
    }
  }
  .fs__noStar {
    p {
      color: $body;
    }
  }
  .clients {
    background: $dark-900;
    svg {
      color: lightgray;
    }
  }
  .fs__item {
    .retroButton {
      background: $dark-800;
    }
  }
  .client__header-tab {
    background: $dark-900;
    color: white;
  }
  .client-content {
    background: $dark-600;
    color: white;
  }
}
/* $primary: #264653;
$secondary: #37484f;
$secondary2: #415761;
$success: #009b3d;
$warning: #ff8801;
$danger: rgb(226, 68, 92);
$info: #34b5e4; */

// $body: #f6f8f9;

// $dark-000: #c1c2c5;
// $dark-100: #a6a7ab;
// $dark-200: #909296;
// $dark-300: #5c5f66;
// $dark-400: #373a40;
// $dark-500: #2c2e33;
// $dark-600: #25262b;
// $dark-700: #1a1b1e;
// $dark-800: #141517;
// $dark-900: #101113;

// .dark {
//   $primary: #acacac;
//   $secondary: #ffffff;
//   $secondary2: #242424;
//   $secondary3: #222222;
//   $success: #408140;
//   $warning: #fbcb00;
//   $danger: rgb(226, 68, 92);
//   $info: #34b5e4;
//   $info-light: #e9f8ff;

//   $body: #1a1a1a;
//   $bodyGray: #595f62;

//   $boxShadow-small: 0 5px 20px 0 rgb(21 7 38 / 8%);
//   $boxShadow-large: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
// }
