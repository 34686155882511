.meeting__joinbutton {
  color: white;
  background: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  button {
    background: none;
    h3 {
      text-align: center;
    }
    svg {
      background: none;
      transform: scale(1);
      transition: 0.3s;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

.meeting__norecipient {
  display: flex;
  padding: 30px;
  align-items: center;
  justify-content: center;
  color: black;
  h3 {
    color: black;
  }
}

.meeting__callperson {
  button {
    background: green;
  }
  svg {
    background: none;
  }
}

.meeting__callperson-name {
  h3 {
    font-weight: 600;
  }
}
