@import "../../theme.styles.scss";
.boardView {
  display: flex;
  padding: 0px 20px 10px 0px;
}
.boardContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  background: white;
  border-radius: 0 8px 8px 8px;
  height: calc(100vh - 100px);
  box-shadow: 2px 2px 8px 1px #00000021;
}
.board {
  display: flex;
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
  height: 100%;
  padding: 5px 15px;
}
::-webkit-scrollbar-track {
  background: white;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.board__dragDelete {
  // border-top: 2px solid rgba(0, 0, 0, 0.6);
  position: absolute;
  transition: 0.3s ease;
  padding: 20px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: $bodyGray;
  opacity: 0.5;
  // svg {
  //   color: rgba(0, 0, 0, 0.6);
  //   width: 25px !important;
  //   height: 25px !important;
  // }
  &:hover {
    height: 130px;
  }
}
.board__placeholder {
  padding: 10px;
}
/* .board__dragDelete {
  position: relative;
  transition: 0.3s ease;
  padding: 10px;
  position: fixed;
  bottom: 10px;
  min-width: 45px;
  min-height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(226, 68, 92);
  left: 320px;
  border-radius: 10px;
  opacity: 1;
  svg {
    color: white;
    position: absolute;
    bottom: 9px;
    left: 9px;
    width: 25px !important;
    height: 25px !important;
  }
} */
.board__placeholder {
  padding: 10px;
}

// vertical

.board__vertical {
  flex-direction: column;
}

.trash {
  background: $primary;
  width: 66px;
  height: 80px;
  display: inline-block;
  margin: 0 auto;
  position: relative;
  -webkit-border-bottom-right-radius: 6px;
  -webkit-border-bottom-left-radius: 6px;
  -moz-border-radius-bottomright: 6px;
  -moz-border-radius-bottomleft: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
// .trash:after {
//   content: "To delete, drop the file to the bin";
//   position: absolute;
//   left: -99px;
//   right: 0;
//   bottom: -50px;
//   width: 300px;
// }
.trash span {
  position: absolute;
  height: 12px;
  background: $primary;
  top: -19px;
  left: -10px;
  right: -10px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transform: rotate(0deg);
  transition: transform 250ms;
  transform-origin: 19% 100%;
}
.trash span:after {
  content: "";
  position: absolute;
  width: 27px;
  height: 7px;
  background: black;
  top: -10px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transform: rotate(0deg);
  transition: transform 250ms;
  transform-origin: 19% 100%;
  left: 27px;
}

.trash i {
  position: relative;
  width: 5px;
  height: 50px;
  background: #fff;
  display: block;
  margin: 14px auto;
  border-radius: 5px;
}
.trash i:after {
  content: "";
  width: 5px;
  height: 50px;
  background: #fff;
  position: absolute;
  left: -18px;
  border-radius: 5px;
}
.trash i:before {
  content: "";
  width: 5px;
  height: 50px;
  background: #fff;
  position: absolute;
  right: -18px;
  border-radius: 5px;
}

.trash:hover span {
  transform: rotate(-45deg);
  transition: transform 250ms;
}

@media (max-width: 500px) {
  .boardView {
    padding: unset;
    overflow: unset;
  }
  .board {
    height: calc(100vh - 130px);
    overflow: unset;
  }
}
