@import "../../../../theme.styles.scss";

.mic {
  background-image: url(../../images/icons/mic.svg);
}

.ant-microphone {
  display: flex;
  align-self: flex-end;
  z-index: 6;
}

.ant-microphone i {
  background-size: contain;
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: -0.25em;
}

.action-button {
  background-color: #eee;
  border: 0;
  padding: 8px 12px;
  position: relative;
  margin-left: 5px;
  margin-right: 10px;
  border-radius: 8px;
  color: #222;
  display: inline-block;
  cursor: pointer;
}

.action-button:hover {
  background-color: #ddd;
  color: #000;
}

.action-button-mic.active {
  animation: mic-pulsate-color 3s infinite;
}

@keyframes mic-pulsate-color {
  0% {
    background-color: #bce5ff;
  }
  50% {
    background-color: #279ae1;
  }
  100% {
    background-color: #bce5ff;
  }
}

button.action-button:disabled {
  opacity: 0.6;
  background: #eee;
  cursor: not-allowed;
}

i.mic {
  background-image: url(../../images/icons/mic.svg);
}
