@import "../../../../theme.styles.scss";
.subtask-all {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding-left: 5px;
  .subtask__delete {
    opacity: 0;
    cursor: pointer;
    color: $primary;
    &:hover {
      color: $danger;
      transition: all 0.3s ease-in-out;
    }
  }
  &:hover {
    background: $body;
    .subtask__delete {
      opacity: 1;
      transition: all 0.3s ease-in-out;
    }
  }
}
.subtask {
  display: flex;
  align-items: center;
  // margin-left: 5px;
  // width: 310px;
  svg {
    opacity: 0.1;
    margin-right: 5px;
  }
}
.subtask__checkBox,
.subtasks__addTrue-box {
  width: 13px;
  height: 13px;
  border-radius: 2px;
  border: 1px solid $bodyGray;
  margin-right: 6px;
  background: none;
  margin-top: 2px;
  transition: 0.2s ease all;
  cursor: pointer;
  transform: scale(1);
  &:hover {
    background: $primary;
    transform: scale(1.2);
  }
}
.subtask__content {
  p {
    font-size: 12px;
  }
}
.subtask__label-gray {
  opacity: 0;
  transition: 0.2s ease all;
  svg {
    opacity: 0.6;
  }
}
.opacityTrue {
  opacity: 1 !important;
}
.subtasks__addTrue {
  display: flex;
  form {
    display: flex;
    align-items: center;
  }
  input {
    font-size: 11px;
    border: none;
    width: 100%;
    outline: none;
  }
  ::placeholder {
    color: $secondary2;
    font-weight: 100;
  }
}
.subtask__done-false,
.subtask__done-true {
  cursor: pointer;
  height: 1em;
}
.subtask__done-false {
  &:hover {
    svg {
      color: $primary;
      opacity: 1;
    }
  }
}
.subtask__content-done {
  text-decoration: line-through;
  // color: rgba(0, 0, 0, 0.2);
}
.subtask__delete {
  align-items: center;
  margin-top: 2px;
}
