@import "../../../theme.styles.scss";
.priority {
  display: flex;
  cursor: pointer;
  position: relative;
}
.priority__current {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  svg {
    margin-right: 5px;
  }
  &:hover {
    .tooltip {
      opacity: 1;
      width: 80px;
    }
  }
}
.priority__show {
  background: blue;
  position: absolute;
  left: 0;
  .bll__content {
    top: 20px;
    left: -34px;
    .retroBox {
      padding: 5px !important;
      background: white;
      box-shadow: $boxShadow-small;
    }
  }
}
.priority__priority {
  display: flex;
  align-items: center;
  transition: 0.2s ease;
  margin-bottom: 2px;
  padding: 3px 5px;
  border-radius: 8px;
  &:last-child {
    margin-bottom: 0;
  }
  svg {
    margin-right: 5px;
  }

  &:hover {
    background: rgb(235, 235, 235);
  }
}
