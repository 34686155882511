@import "../../theme.styles.scss";
.allchat {
  min-width: 320px;
  display: flex;
  position: relative;
  height: 100vh;
}
.allchatFS {
  // width: calc(100% - 70px);
  height: calc(100vh - 45px);
  // padding-top: 45px;
  display: flex;
  position: absolute;
  // height: 100vh;
}
.chat {
  height: 100vh;
  min-width: 320px;
  width: 320px;
  // padding: 90px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  background: $secondary3;
  color: white;
  position: relative;
  margin-top: 0px;
  justify-content: space-between;
  // border-left: 1px solid $primary;
  z-index: 100;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  overflow: hidden;
  .emoji-mart-title-label {
    display: none;
  }
  .emoji-mart-preview {
    height: 30px;
    svg {
      height: 30px;
    }
  }
  .emoji-mart-preview-emoji {
    display: none;
  }
}

.chatFS {
  height: 100vh;
  // min-width: 320px;
  width: 100vw;
  // padding: 90px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  background: $secondary3;
  color: white;
  position: relative;
  margin-top: 0px;
  justify-content: space-between;
  // border-left: 1px solid $primary;
  z-index: 100;
  .ch__writeUpdate {
    width: 320px;
    background: none;
    align-self: flex-end;
    padding-bottom: 73px;
    padding-right: 10px;
  }
  // padding-bottom: 20px;
  // padding-left: 10px;
  // padding-right: 10px;
  // padding-top: 10px;
  .emoji-mart-title-label {
    display: none;
  }
  .emoji-mart-preview {
    height: 30px;
    svg {
      height: 30px;
    }
  }
  .emoji-mart-preview-emoji {
    display: none;
  }
  .ch__writeUpdate-msgs {
    // max-height: unset;
    max-height: calc(100vh - 240px);
  }
  .ch__messageLine .message__msg {
    background: $dark-900;
    color: white;
  }
  .ch__messageLine-Self .message__msg {
    background: $dark-900;
    color: white;
  }
  .message__msg {
    .message__user-name {
      p {
        color: $dark-200;
      }
    }
    .message__user-time {
      p {
        color: $dark-200;
      }
    }
    p {
      color: $secondary2;
    }
  }
  .ch__writeUpdate-send {
    border-top: unset;
    .ch__writeUpdate-buttongroup {
      svg {
        color: $dark-200;
      }
      background: $dark-900;
    }
    textarea {
      color: white;
      background: $dark-900;
      border-radius: 10px 0px 0px 0px;
    }
  }
  .ch__personselected {
    background: unset;
    color: white;
  }
  .ch__personNonselected {
    background: unset;
    color: white;
  }
  .ch__sendtomode-tabs {
    display: flex;
    background: rgba(0, 0, 0, 0.2);

    .ch__sendtomode-tab-active {
      background: $dark-900;
      color: white;
      border: none;
      border-radius: 0px;
    }
    .ch__sendtomode-tab {
      background: transparent;
      color: rgb(114, 114, 114);
      border: none;
      border-radius: 0px;
    }
  }
}

.meetingVideoChat {
  z-index: 50;
  width: 100%;
  background: $body;
  display: flex;
  // height: 323px;
  // height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 10px 10px;
  display: flex;
  flex-direction: column;
}

.meetingVideoChatFS {
  z-index: 50;
  position: absolute;
  width: 100%;
  background: $body;
  display: flex;
  height: calc(100dvh - 60px);
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 10px 10px;
  display: flex;
  flex-direction: column;
}

.meetingVideoChat-mockheader {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid black;
  position: relative;
  background: $secondary2;
  position: absolute;
  top: 0px;
  margin-left: -10px;
}

.chat__miniMenu {
  .boxLayer {
    align-items: center;
    position: absolute;
    width: 100%;
    padding-top: 0px;
  }
  .bl__layer {
    margin-top: -90px;
  }
  .retroBox {
    padding: 5px;
    width: 220px;
  }
}

.chat__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.chat__name {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  h3 {
    margin-left: 10px;
    margin-top: -5px;
  }
  button {
    width: 40px;
    height: 40px;
  }
}
.chat__menu {
  position: relative;
  button.flat {
    width: 35px;
    padding: 0px 9px;
    height: 20px;
    border-radius: 8px;
    background: $secondary2;
    color: white;
  }
  svg {
    margin: 0;
  }

  .bl__content {
    margin-top: 70px;
  }
}
.chat__hide,
.chat__hideMoved {
  position: absolute;
  height: 56px;
  width: 56px;
  align-items: center;
  justify-content: center;
  left: -86px;
  // top: calc(100vh - 74px);
  bottom: 30px;
  padding: 8px 12px;
  color: $primary;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 50;
  background-color: white;
  box-shadow: 2px 2px 8px 1px #00000021;
  z-index: 100;
  svg {
    opacity: 1;
    &:hover {
      opacity: 0.7;
    }
  }
}

.chat__exit__button {
  // position: absolute;
  // top: 0px;
  // right: 2px;
  // background: none;
  // margin-top: 4px;
  // transform: translate(22px, 18px);
  // color: $secondary2;
  // align-self: end;
  // stroke-width: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-45deg);
  transition: all 0.3s;
  // svg {
  //   width: 32px;
  //   height: 32px;
  // }
  :hover {
    color: $primary;
  }
}

.chat__exit__btn {
  color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    opacity: 0.7;
  }
}
.chat__exit__btn-flipped {
  color: $primary;
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    opacity: 0.7;
  }
}

.chat__hide-xs {
  margin-top: 5px;
  margin-right: 5px;
  color: $primary;
}

.ch__EmojiPick {
  width: auto;
  display: flex;
  column-gap: 15px;
  margin-top: 12px;
  button {
    height: 16px;
  }
}

.ch__writeUpdate {
  display: flex;
  flex-direction: column;
  // max-height: calc(100vh - 323px);
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  background: $body;
  border-radius: 10px 10px 0px 0px;
  z-index: 190;
  // padding: 20px 20px;
  // justify-content: center;
  overflow: hidden;
}
.ch__writeUpdateHidden {
  // opacity: 0;
  // height: 30px;
  // overflow: hidden;
  display: none;
  // div.dropdown * {
  //   display: none;
  // }
}
.ch__writeUpdate-send {
  display: flex;
  flex-direction: row;
  justify-content: center;
  // padding: 20px 0px;
  column-gap: 10px;
  z-index: 190;
  border-top: 1px solid rgb(224, 224, 224);
}
.ch__textbox {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  // background: white;
  // border: 1px solid rgb(224, 224, 224);
  border-radius: 10px;
}
textarea {
  width: 100%;
}
.ch__writeUpdate-message {
  display: flex;
  position: relative;
  width: 100%;
  padding: none;
  align-items: flex-end;
  // border: 1px solid $secondary2;

  textarea {
    padding: 10px;
    // width: 100%;
    max-width: 100% !important;
    height: 60px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-radius: 0;
    resize: none;
    transition: 0.2s ease;
    &:focus {
      outline: none;
      // height: 90px;
      border-top: none;
    }
  }
  .ch__writeUpdate-btn {
    margin-right: 5px;
    .retroButton {
      background: none;
    }
    &:hover {
      a {
        font-weight: bold;
        color: black;
      }
    }
    .retroButton {
      border-radius: 6px;
      border: none;
      width: 48px;
      height: 32px;
      // border-top: 1px solid $secondary2;
      // &:hover {
      //   color: black;
      //   font-weight: bold;
      //   border-top: none;
      //   box-shadow: 3px 3px 4px rgba(204, 204, 204, 0.8);
      // }
    }
  }
  form {
    height: 60px;
    width: 100%;
    &:focus {
      height: 90px;
    }
  }
}
.ch__writeUpdate-buttongroup {
  display: flex;
  height: 100%;
  background: white;
  justify-content: flex-end;
  align-items: center;
  border-radius: 0 0 0px 0px;
  // border-left: 1px solid $secondary2;
  // border-right: 1px solid $secondary2;
  // border-bottom: 1px solid $secondary2;
  width: fit-content;
  svg {
    width: 18px;
    height: 18px;
  }
}
.ch__writeUpdate-buttongroup-sub {
  display: flex;
  justify-content: space-between;
  // background: white;
  // width: 100%;
  width: fit-content;
  height: 32px;
  align-items: center;
  border-radius: 0 0px 0 10px;
  padding-left: 10px;
  padding-right: 10px;
  // padding-right: 4em;
  // border-top: 1px solid $secondary2;
  // border-right: 1px solid $secondary2;
  .ch__EmojiPick {
    margin-top: 0px;
  }
}
.ch__writeUpdate-msgs {
  display: flex;
  flex-direction: column-reverse;
  // background: white;
  // row-gap: 5px;
  // align-items: end;
  max-height: calc(100vh - 390px);
  overflow-y: scroll;
  // padding-top: 30px;
}
// .ch__writeUpdate-msgs:after {
//   position: absolute;
//   bottom: 0;
//   height: 100%;
//   width: 100%;
//   content: "";
//   background: linear-gradient(
//     to top,
//     rgba(255, 255, 255, 0) 40%,
//     rgba($secondary3, 1) 90%
//   );
//   pointer-events: none; /* so the text is still selectable */
// }
.ch__leftcolumn {
  display: flex;
  flex-direction: column;
  height: 30px;
  align-items: center;
  justify-content: center;
}
.ch__EmojiIcon {
  max-width: 20px;
  margin: none;
}

// .lt__content {
//   display: flex;
//   z-index: 400;
//   flex-direction: column;
//   justify-content: space-between;
//   height: 100%;
// }

.ch__PickerOn {
  position: relative;
  top: -422px;
  right: 155px;
  max-width: 100px;
  z-index: 150;
}
.ch__messagewithReaction {
  display: flex;
  flex-direction: column;
}

.ch__ReactionPickerOn {
  position: absolute;
  top: 180px;
  right: 20px;
  // z-index: 150;
}
.ch__messageReaction {
  position: relative;
  left: 45px;
  align-self: start;
  bottom: 12px;
  font-size: 18px;
  margin-top: 5px;
}
.ch__messageReaction-Self {
  position: relative;
  left: 45px;
  align-self: flex-start;
  bottom: 12px;
  font-size: 18px;
  margin-top: 5px;
}

.ch__sendtomode-tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 15px;
  background: $secondary3;
  width: 100%;
  z-index: 1;
}
.ch__sendtomode-tab {
  border: 1px solid $secondary2;
  border-top: 1px solid $secondary3;
  border-radius: 0px 0px 8px 8px;
  // padding: 5px;
  width: 100%;
  background: none;
  height: 32px;
  // :only-child {
  //   background: none;
  // }
  &:active {
    background: white;
  }
}
.ch__sendtomode-tab-active {
  border: 1px solid white;
  // border-top: 1px solid white;
  border-radius: 0px 0px 8px 8px;
  border-bottom: 2px solid white;
  margin-top: -1px;
  height: 32px;
  // padding: 5px;
  width: 100%;
  background: white;
  box-shadow: 2px 2px 2px -2px #00000021;
  &:active {
    background: white;
  }
}
.ch__selectperson {
  position: absolute;
  display: flex;
  background: white;
  border-radius: 12px 12px 12px 0px;
  flex-direction: column;
  box-shadow: $boxShadow-small;
  row-gap: 10px;
  padding: 10px;
  bottom: 52px;
  right: 16px;
  z-index: 151;
  border: 1px solid $secondary2;
}
.ch__selectperson_item {
  display: flex;
  flex-direction: row;
  background: white;
  padding: 5px;
  align-items: center;
  column-gap: 5px;
  // border: 1px solid $body;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  // box-shadow: 2px 2px 8px 1px #00000021;
  &:hover {
    background: $secondary3;
  }
}
.ch__personselected {
  display: flex;
  flex-direction: row;
  // background: $secondary2;
  padding: 3px 3px 3px 6px;
  align-items: center;
  // justify-content: center;
  column-gap: 5px;
  border: none;
  border-radius: 0px 0px 6px 6px;
  min-width: 8em;
  width: 100%;
  font-weight: 600;
}
.ch__personNonselected {
  display: flex;
  flex-direction: row;
  padding: 5px;
  align-items: center;
  column-gap: 5px;
  // border: 1px solid $body;
  border-radius: 15px 15px 0px 0px;
  min-width: 8em;
  font-weight: 450;
}
.ch__messageLine {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px 20px;
  .message__user-name {
    p {
      b {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
  .message__msg {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    background: $secondary3;
    border-radius: 10px 4px 10px 4px;
    p {
      font-weight: 400;
    }
  }
  &:hover {
    .ch__EmojiPickIcon {
      svg {
        opacity: 0.6;
        &:hover {
          opacity: 1;
        }
      }
    }
    .ch__MessageDelete {
      svg {
        opacity: 0.6;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
.ch__messageLine-Self {
  display: flex;
  flex-direction: row-reverse;
  column-gap: 10px;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: flex-end;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 16px;
  .message__user-name {
    p {
      b {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
  .message__msg {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    background: $secondary3;
    border-radius: 10px 4px 10px 4px;
    p {
      font-weight: 400;
    }
  }
  &:hover {
    .ch__EmojiPickIcon {
      svg {
        opacity: 0.6;
        &:hover {
          opacity: 1;
        }
      }
    }
    .ch__MessageDelete {
      svg {
        opacity: 0.6;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
.ch__EmojiPickIcon {
  svg {
    cursor: pointer;
    opacity: 0.1;
    transition: 0.2s ease;
  }
}
.ch__MessageDelete {
  margin-top: 12px;
  svg {
    cursor: pointer;
    opacity: 0.1;
    transition: 0.2s ease;
  }
}

.leftwatermark {
  display: none;
  position: relative;
}
.watermark {
  display: none;
  position: relative;
}

.ch__breadcrumb-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: $primary;
  background: $secondary3;
  padding: 5px 10px;
  text-transform: capitalize;
  h5 {
    font-weight: 400;
  }
}

.ch__breadcrumb-headerFS {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: white;
  background: none;
  padding: 10px 10px;
  position: absolute;
  bottom: 4px;
  right: 20px;
  width: fit-content;
  column-gap: 50px;
  align-self: flex-end;
  // h5 {
  //   display: none;
  // }
  // bottom: 0px;
  text-transform: capitalize;
  font-weight: 500;
  z-index: 200;
  svg {
    color: white;
    width: 40px;
    height: 40px;
  }
}

.chat_grpselect {
  display: flex;
  position: absolute;
  padding: 12px 8px;
  bottom: 52px;
  flex-direction: column;
  border-radius: 12px 12px 12px 0px;
  row-gap: 10px;
  box-shadow: $boxShadow-large;
  z-index: 100;
  background: white;
  border: 1px solid $secondary2;
}
.chat_grpoption {
  display: flex;
  padding: 6px 8px;
  position: relative;
  background: white;
  border-radius: 4px;
  white-space: nowrap;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  font-weight: 500;
  font-size: 16px;
  &:hover {
    background: $secondary3;
  }
}
.chat_grpoption-active {
  display: flex;
  padding: 6px 8px;
  position: relative;
  background: white;
  border-radius: 4px;
  white-space: nowrap;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  background: $secondary3;
  font-weight: 600;
  font-size: 16px;
  // &:hover {
  //   background: $secondary3;
  // }
}
.chat_grpoption-selected {
  display: flex;
  padding: 6px 8px;
  position: relative;
  border-radius: 0px 0px 6px 6px;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  font-weight: 500;
  font-size: 16px;
  &:hover {
    // background: $secondary3;
    button {
      text-decoration: underline 2px black;
    }
  }
}
.chat_grpsuboption {
  display: flex;
  padding: 6px 8px;
  position: relative;
  background: white;
  border-radius: 4px;
  column-gap: 5px;
  // white-space: nowrap;
  color: black;
  margin-left: 10px;
  font-weight: 500;
  font-size: 14px;
  &:hover {
    background: $secondary3;
  }
}
.chat_grpsuboption-active {
  display: flex;
  padding: 6px 8px;
  position: relative;
  background: white;
  border-radius: 4px;
  // white-space: nowrap;
  color: black;
  margin-left: 10px;
  background: $secondary3;   
  font-weight: 600;
  font-size: 14px;
  &:hover {
    background: $secondary3;
  }
}

@media (max-width: 500px) {
  .allchat {
    // max-height: 65vh;
    position: fixed;
    min-width: unset;
    // display: flex;
    // align-self: flex-end;
    bottom: 45px;
    // border-top: 4px solid black;
    // z-index: 100;
  }
  .chat {
    // display: none;
    width: 100vw;
    // max-height: 65vh;
    padding-bottom: 10px;
    // padding-left: 0px;
    // padding-right: 0px;
    // height: calc(100vh - 35px);
    height: 100vh;
    border: none;
    align-items: flex-end;
    justify-content: space-between;
    z-index: 190;
    bottom: -45px;
  }
  .chat__hide {
    position: fixed;
    // height: 52px;
    // width: 52px;
    top: unset;
    left: calc(100vw - 70px);
    // right: 50px;
    padding: 5px 15px;
    bottom: 64px;
    display: flex;
    align-items: center;
    // text-align: center;
    justify-content: center;
    // border: 1px solid $secondary2;
    // top: 300px;
    // left: 150px
    z-index: 201;
  }
  .chat__hideMoved {
    position: fixed;
    // height: 52px;
    // width: 52px;
    top: unset;
    left: calc(100vw - 60px);
    // right: 50px;
    padding: 5px 15px;
    // bottom: 94px;
    top: 5px;
    display: flex;
    align-items: center;
    // text-align: center;
    justify-content: center;
    // border: 1px solid $secondary2;
    // top: 300px;
    // left: 150px
    z-index: 201;
    background: rgba(255, 255, 255, 0.7);
  }
  .meetingVideoChat {
    // display: none;
    height: unset;
    margin-top: 0px;
    // height: 100%;
    // #jitsi-container-id {
    //   width: 100vw;
    // }
  }
  .ch__writeUpdate-send {
    // background: $body;
    background: none;
  }
  .ch__writeUpdate {
    // height: 90vh;
    background: none;
    border-top: 1px solid $secondary2;
    padding-top: 10px;
    background: $body;
  }
  .ch__writeUpdate-msgs {
    max-height: 50vh;
    // padding-top: 30px;

    // background-image: linear-gradient(0deg, white 30%, transparent);
  }
  .ch__writeUpdate-msgs:before {
    // display: none;
    position: absolute;
    bottom: 0;
    // height: 300px;
    width: 100vw;
    max-height: 460px;
    content: "";
    // background:linear-gradient(transparent 150px, white);
    // background: linear-gradient(rgba(0, 0, 0, 0), #000);
    // -webkit-mask-image: linear-gradient(0deg, #ffffff 100%, transparent);
    // background: linear-gradient(
    //   to top,
    //   rgba(255, 255, 255, 0) 50%,
    //   rgba(255, 255, 255, 1) 100%
    // );
    pointer-events: none; /* so the text is still selectable */
  }

  .ch__writeUpdate-message {
    textarea {
      display: flex;
      width: 100%;
      // height: 36px;
    }
    form {
      display: flex;
      // height: 36px;
      width: 100%;
      &:focus {
        height: 90px;
      }
    }
  }
  .ch__sendtomode-tabs {
    display: flex;
    width: 100%;
  }
  .ch__sendtomode-tab {
    height: 36px;
  }
  .ch__sendtomode-tab-active {
    height: 36px;
  }

  .meetingVideoChat-mockheader {
    display: none;
  }

  .ch__breadcrumb-header {
    h5 {
      font-weight: 500;
      font-size: 1rem;
    }
    svg {
      width: 40px;
      height: 40px;
    }
  }

  .ch__breadcrumb-headerFS {
    width: unset;
    bottom: 6px;
    right: 10px;
    h5 {
      display: none;
    }
    svg {
      width: 40px;
      height: 40px;
    }
  }

  .chatFS {
    position: fixed;
    height: 100dvh;
    .ch__writeUpdate {
      margin-bottom: 19px;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  .ch__EmojiIcon {
    max-width: unset;
    height: 22px;
  }

  .ch__writeUpdate-buttongroup {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  .ch__selectperson {
    row-gap: 10px;
    padding: 10px;
  }
  .ch__selectperson_item {
    padding: 5px;
    column-gap: 10px;
    border-radius: 25px;
    .avatar {
      width: 40px;
      height: 40px;
    }
    font-size: 16px;
  }
  .ch__personselected {
  }
  .ch__personNonselected {
  }

  // .ch__writeUpdate-msgs:after {
  //   position: absolute;
  //   bottom: 0;
  //   height: 100%;
  //   width: 100%;
  //   content: "";
  //   background: linear-gradient(
  //     to top,
  //     rgba(255, 255, 255, 0) 40%,
  //     rgba($secondary3, 1) 90%
  //   );
  //   pointer-events: none; /* so the text is still selectable */
  // }
}
