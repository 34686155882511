@import "../../../theme.styles.scss";
.sec__product {
  display: flex;
  padding: 120px 10% 60px 10%;
  // flex-direction: column;
  height: 85vh;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  column-gap: 60px;
  margin-top: 90px;
  background: white;
}
.sec__product-hero-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  h3 {
    font-size: 36px;
    line-height: 40px;
    font-weight: 400;
  }
}

.product__hero-info {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  max-width: 500px;
  h1 {
    font-size: 36px;
    line-height: 54px;
    font-weight: 600;
    color: black;
    text-decoration: underline;
  }
  p {
    font-size: 20px;
    line-height: 32px;
    // font-weight: 400;
  }
  h2 {
    font-size: 36px;
    line-height: 36px;
    font-weight: 600;
  }
  h3 {
    font-size: 28px;
    line-height: 40px;
    font-weight: 400;
  }
  h4 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
  }
  button {
    width: 160px;
    align-self: center;
  }
}

.visual__container {
  display: flex;
  // align-items: center;
  // justify-content: center;
  flex-direction: column;
  max-width: 700px;
  // padding-bottom: 300px;
  // transition: all 0.3s ease-in;
  // width: fit-content;
  img {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    border-radius: 7px;
    transition: all 0.3s ease-in;
  }
}

.sec_hero-stacked-card-header {
  // position: absolute;

  bottom: 30px;
  right: 30px;
  padding-right: 30px;
  padding-bottom: 20px;

  background: "#fff";
  padding: 30;
  border-radius: 20;
  // height: 0px;
  transform: translateY(-70px);
  h4 {
    text-align: end;
    font-weight: 700;
  }
}

.storebadges__container {
  display: flex;
  column-gap: 50;
  justify-content: space-between;
  h4 {
    font-weight: 500;
  }
}

// .visual__container:hover {
//   transform: scale(1.05);
//   transition: all 0.3s ease-in;
//   img {
//     box-shadow: $boxShadow-small;
//   }
// }

.product__hero-info button {
  div {
    // font-size: 14px;
    font-weight: 600;
  }
}

.sec_hero-stacked {
  display: flex;
  padding: 40px 0px;
  // :nth-child(even) {
  //   flex-direction: row-reverse;
  // }
  :first-child {
    z-index: 5;
  }
  :nth-child(2) {
    z-index: 4;
    margin-top: 2rem;
  }
  :nth-child(3) {
    z-index: 3;
    margin-top: 3rem;
  }
  :nth-child(4) {
    z-index: 2;
    margin-top: 3rem;
  }
  :nth-child(5) {
    z-index: 1;
    margin-top: 4rem;
  }
}

.sec_hero-stacked-card {
  display: flex;
  border-radius: 12px;
  // padding: 20px 30px;
  transition: 0.4s;
  // width: 50%;
  column-gap: 30px;
  &:hover {
    z-index: 6;
  }
  height: 80%;
  // div {
  //   width: 50%;
  //   height: 100%;
  // }
}

.sec_hero-stacked-card:not(:first-child) {
  margin-left: -600px;
  flex-direction: row-reverse;
}

.sec_hero-stacked-card:hover,
.sec_hero-stacked-card:focus-within {
  transform: translateY(-0.5rem);
  ~ .card {
    transform: translateX(2rem);
  }
}
// .sec_feature-stacked-card:not(:last-child):hover,
// .sec_feature-stacked-card:not(:last-child):focus-within {
//   transform: translateY(-1rem);
//   ~ .card {
//     transform: translateX(2rem);
//   }
// }

.sec_hero-stacked-card-inner {
  // width: 40%;
  column-gap: 30px;
  img {
    background: white;
    box-shadow: $boxShadow-small;
    border: 6px solid white;
  }
}
.sec_hero-stacked-card-inner-content {
  width: 60%;
  a {
    font-weight: 600;
  }
}

@media (max-width: 768px) {
  .sec__product {
    padding: 20px 20px 60px 20px;
    img {
      object-fit: contain;
    }
  }
  .product__hero-info {
    row-gap: 20px;
  }
  .product__hero-info h2 {
    font-size: 30px;
  }
  .product__hero-info p {
    font-size: 18px;
  }
  .visual__container {
    margin-top: 100px;
  }
}

@media (max-width: 500px) {
  .sec__product {
    flex-direction: column;
  }
}
