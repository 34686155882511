@import "../../theme.styles.scss";
.dockStation {
  width: 100%;
  // overflow-y: auto;
  overflow: hidden;
  // overflow-x: auto;
  position: relative;
  display: flex;
  height: 100vh;
  .retroBox {
    padding: 20px;
    min-height: 126px;
    margin-bottom: 20px;
  }
}
.dockStation-content {
  display: flex;
  position: relative;
  flex-direction: column;
  // height: calc(100vh - 91px);
  width: 100%;
  // height: 100vh;
  height: 100%;
  // overflow-y: scroll;
  overflow: hidden;
}

.dockStation-content-inner {
  display: flex;
  position: relative;
  flex-direction: column;
  height: calc(100vh - 80px);
  padding: 0px 0px 30px 0px;
  // height: 100%;
  width: 100%;
  // overflow-y: auto;
}
.workspace__journal {
  // text-align: center;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  // height: 100%;
  padding: 10px;
  background: white;
  box-shadow: rgb(0 0 0 / 20%) 0px 9px 30px -10px;
  border-radius: 0px 5px 5px 5px;
  height: calc(60vh - 201px);
  .editor-container {
    margin: 0px;
    display: flex;
    // flex-direction: column;
    // max-height: calc(50vh - 221px);
  }
  .editor-inner {
    width: 100%;
    min-height: 120px;
  }
  .editor-input {
    padding: 5px 10px;
    // display: flex;
    max-height: calc(60vh - 251px);
    overflow-y: auto;
    // min-height: 100px;
  }
  .actions {
    bottom: -30px;
    padding: 0px;
  }
  .editor-placeholder {
    top: 5px;
    left: 11px;
  }
  .PlaygroundEditorTheme__listItem {
    margin: 0px;
    margin-bottom: 4px;
  }
  .PlaygroundEditorTheme__ul {
    margin-block-start: 5px;
    margin-left: 2px;
    line-height: 24px;
    margin-bottom: 6px;
  }
  .PlaygroundEditorTheme__ol1 {
    line-height: 24px;
    margin-bottom: 6px;
    margin-left: 10px;
  }
}
.ds__content {
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 60px;
  padding: 20px 25px;
  justify-content: space-between;
  // overflow-y: auto;
}

.roadmap__content {
  // display: flex;
  // flex-wrap: wrap;
  padding: 10px 25px;
  // height: 220px;
  // overflow: hidden;
  // min-height: 220px;
  // .gantt-container {
  //   // margin-top: 8px;
  //   max-height: 220px;
  // }
  .gantt-container {
    max-height: 40vh;
  }
  .gantt {
    max-height: 100%;
  }
}

.ds__recentStations {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.ds__item {
  min-width: 300px;
  // max-width: 400px;
  flex: 1;
  // margin-right: 20px;
  h2 {
    margin-bottom: 10px;
  }
  &:last-child {
    margin-right: 0;
  }
}

.ds__item-secondary {
  min-width: 250px;
  // max-width: 400px;
  flex: 0.7;
  // margin-right: 20px;
  h2 {
    margin-bottom: 10px;
  }
}

.dh__description {
  textarea {
    border: none;
    width: inherit;
    // max-width: max-content;
    font-size: 12px;
  }
}
.gantt__header-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.project g rect {
  // fill: #d32f2f !important;
  display: inline;
  min-width: 20px;
  overflow: visible;
}
.project .bar-progress {
  fill: #2b2e33;
  display: inline;
  min-width: 20px;
  overflow: visible;
}

.ds__members {
  .retroBox {
    padding-right: 30px;
    padding-bottom: 30px;
    height: calc(60vh - 201px);
  }
}

@media (max-width: 800px) {
  .dockStation {
    width: 100%;
    height: 100%;
  }
  .ds__content {
    padding: 10px;
    padding-top: 0px;
    row-gap: 30px;
  }
  .dockStation-content {
    height: 100%;
  }
  .roadmap__content {
    padding: 20px 0px 0px 0px;
    overflow: hidden;
    width: 100%;
    // min-height: 220px;
    min-height: 50vh;
    max-height: unset;
    .gantt-container {
      max-height: unset;
    }
    input {
      display: none;
    }
    span {
      display: none;
    }
  }
  .dockStation-content-inner {
    padding: 0px 0px 120px 0px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
  .ds__item {
    min-width: 100%;
    margin-bottom: 20px;
    &:first-child {
      margin-right: 0;
    }
  }
  .ds__item-secondary {
    min-width: 100%;
    // margin-bottom: 20px;
  }
  .gantt__header-buttons {
    padding: 0px 10px;
  }
}
