.favoriteStation {
  padding: 20px 15px;
}
.fs__noStar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  svg {
    opacity: 0.1;
    margin-bottom: 15px;
  }
  p {
    opacity: 0.4;
    text-align: center;
  }
}
.fs__item {
  margin-bottom: 8px;
  .retroButton.flat {
    padding-left: 12px;
    padding-right: 12px;
    height: 32px;
    border-radius: 6px;
    background: #fafafa;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  &:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 500px) {
  .favoriteStation {
    padding: 10px 20px;
  }
  .fs__item {
    font-size: 0.9em;
    button {
      font-size: 1em;
    }
  }
}
