@import "../../theme.styles.scss";

.habits {
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: rgb(0 0 0 / 20%) 0px 9px 30px -10px;
  border-radius: 0px 5px 5px 5px;
  padding: 10px;
}

.widgetheader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background: white;
  padding: 5px 10px;
  border-radius: 5px 5px 0px 0px;
  align-items: center;
  box-shadow: rgb(0 0 0 / 20%) 0px -9px 30px -10px;
  // border: 1px solid black;
  // display: flex;
  justify-content: space-between;
  column-gap: 10px;
  width: fit-content;
  z-index: 5;
  // border: 1px solid black;
  // z-index: 5;
  h3 {
    font-size: 16px;
    font-weight: 500;
    font-family: "Noto Sans JP", sans-serif;
  }
}

.widgetname {
  padding: 5px 10px;
  border-radius: 5px 5px 0px 0px;
  box-shadow: rgb(0 0 0 / 20%) 0px -9px 30px -10px;
}

.widgetbtns {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  column-gap: 5px;
  border-radius: 5px 5px 0px 0px;

  input {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    padding-top: 2px;
  }

  button {
    // background: gray;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    // min-width: 40px;
    min-height: 25px;
    padding: 5px 10px;
    background: $secondary3;
    border-radius: 5px;
    svg {
      width: 20px;
      height: 20px;
    }
    // font-weight: 600;
    // box-shadow: rgb(0 0 0 / 20%) 0px 1px 30px -5px;
  }
}

.habitadd-btn {
  margin-bottom: 5px;
}

.habit__today-btn {
  width: auto;
  padding: 5px;
  padding-top: 2px;
  // text-decoration: underline 1.5px solid;
  display: flex;
  align-items: center;
  justify-content: center;
}
.habit__today-btn-passive {
  width: auto;
  color: $secondary2;
  padding-top: 2px;
  // text-decoration: line-through;
  display: flex;
  align-items: center;
  justify-content: center;
}

.habitcontent {
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 7px;
  margin-bottom: 8px;
  overflow-y: auto;
  height: 30vh;
}

.habitline-main {
  text-align: center;
  padding: 10px;

  // box-shadow: rgb(0 0 0 / 20%) 0px 9px 30px -10px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  justify-content: space-between;
  h5 {
    font-size: 14px;
    font-weight: 500;
    text-align: start;
  }
}
.habitline {
  text-align: center;
  padding: 10px;

  // box-shadow: rgb(0 0 0 / 20%) 0px 9px 30px -10px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  justify-content: flex-start;
  span {
    font-size: large;
    background: $secondary3;
    border-radius: 100%;
    padding: 5px;
    align-items: center;
    justify-content: center;
  }
  h5 {
    font-size: 14px;
    font-weight: 500;
    text-align: start;
  }
}

.habitline-confetti {
  position: fixed;
  width: 180px;
  display: flex;
  margin-left: 150px;
}

.habit-progress-icon {
  display: flex;
  position: relative;
  // margin-left: -47px;
  width: 32px;
  height: 32px;
  background: $secondary3;
  padding: 5px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  span {
    font-size: large;
    padding: 5px;
    align-items: center;
    justify-content: center;
  }
}

.habit-progress-circle {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100px;
  // transform: translate(-50px);
  // margin-left: -47px;
}

.habit__btn-group {
  display: flex;
  align-self: center;
  justify-content: flex-end;
  column-gap: 5px;
  right: 5px;
  button {
    width: 25px;
    height: 25px;
    background: $secondary3;
    border-radius: 5px;
    font-weight: 600;
  }
}

.habit-checkbox {
  display: flex;
  cursor: pointer;
  svg {
    width: 18px;
    height: 18px;
    color: lightgray;
  }
}
.habit-checkbox-colored {
  display: flex;
  cursor: pointer;
  svg {
    width: 18px;
    height: 18px;
    color: #1a82c4;
  }
}
.habit-checkbox-done {
  display: flex;
  cursor: pointer;
  svg {
    width: 18px;
    height: 18px;
    color: $primary;
  }
}

.habit__name-check {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: center;
  justify-content: flex-end;
  column-gap: 5px;
  right: 5px;
  h3 {
    align-self: flex-start;
  }
  // button {
  //   width: 25px;
  //   height: 25px;
  //   background: $secondary3;
  //   border-radius: 5px;
  //   font-weight: 600;
  // }
}

// .habit__backdrop {
//   background: rgba(0, 0, 0, 0.2);
//   backdrop-filter: blur(0.2px);
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   z-index: 100;
// }

// .habits__range-slider {
//   display: block;
//   width: 100%;
//   height: 15px;
// }

// $colorTrack: #7a7a7a !default;
// $colorThumb: rgb(0, 0, 0) !default;

// $h: 1em;
// $w: 100%;

// @mixin trackN($n) {
//   input[type="range"].n.n#{$n}:before {
//     $bgW: $w / ($n);
//     background-size: ($bgW $h, $w 0.1em);
//   }
// }

// @mixin track() {
//   width: $w;
//   box-sizing: border-box;
//   height: $h;
//   border-radius: $h/2;
//   // margin-right: 1em;
//   box-sizing: content-box;
//   background: (
//     radial-gradient(
//       circle at $h/2 50%,
//       $colorTrack 0.3125em,
//       $colorTrack 0.3125em,
//       $colorTrack $h/2,
//       transparent $h/2
//     ),
//     linear-gradient(90deg, transparent $h, $colorTrack $h) repeat-x 0 50%
//   );
// }

// @mixin thumb() {
//   border: none;
//   border-radius: 50%;
//   box-sizing: border-box;
//   border: (solid 0.35em transparent) null (solid 0.25em transparent);
//   width: $h + 0.3em;
//   height: $h + 0.3em;
//   background: $colorThumb;
//   background-clip: content-box;
//   position: relative;
//   z-index: 10;
//   margin-top: -0.1em;
//   margin-left: 0.05em;
// }

// input[type="range"].n {
//   display: block;
//   margin: 0em auto;
//   padding: 0;
//   background: transparent;
//   font-size: 1em;
//   cursor: pointer;
//   width: $w;
//   position: relative;

//   &,
//   &::-webkit-slider-runnable-track,
//   &::-webkit-slider-thumb {
//     -webkit-appearance: none;
//   }
//   &:after {
//     content: "";
//     width: 1em;
//     height: 1em;
//     border-radius: 50%;
//     background: $colorTrack;
//     position: absolute;
//     right: 0;
//   }
//   &:before {
//     content: "";
//     position: absolute;
//     z-index: 0;
//     left: 0;
//     right: $h - 0.2em;
//     height: $h;
//     background: (
//       radial-gradient(
//         circle at $h/2 50%,
//         $colorTrack 0.3125em,
//         $colorTrack 0.3125em,
//         $colorTrack $h/2,
//         transparent $h/2
//       ),
//       linear-gradient(90deg, transparent 0, $colorTrack 0) repeat-x 0 50%
//     );
//     box-sizing: border-box;
//   }

//   &::-ms-track {
//     border: none;
//     color: transparent;
//   }
//   &::-webkit-slider-thumb {
//     @include thumb();
//   }
//   &::-moz-range-thumb {
//     @include thumb();
//   }
//   &::-ms-thumb {
//     @include thumb();
//   }
//   &::-ms-tooltip {
//     display: none;
//   }
//   &:focus {
//     outline: none;
//   }
// }

// @for $n from 2 through 10 {
//   @include trackN($n);
// }

@media (max-width: 500px) {
  .habitcontent {
    height: 100%;
    // margin-bottom: 20px;
  }
  .widgetbtns {
    column-gap: 8px;
    input {
      font-family: "Raleway", sans-serif;
      font-weight: 600;
      padding-top: 2px;
      font-size: 16px;
    }
    button {
      height: 32px;
      font-size: 16px;
      svg {
        width: 24px;
        height: 24px;
      }
      // font-weight: 600;
      // box-shadow: rgb(0 0 0 / 20%) 0px 1px 30px -5px;
    }
  }
  .widgetheader {
    h3 {
      font-size: 18px;
    }
  }
  .habit-checkbox {
    cursor: pointer;
    svg {
      width: 22px;
      height: 22px;
    }
  }
  .habit-checkbox-colored {
    svg {
      width: 22px;
      height: 22px;
    }
  }
  .habit-checkbox-done {
    cursor: pointer;
    svg {
      width: 22px;
      height: 22px;
    }
  }
  .habitline-main {
    h5 {
      font-size: 16px;
    }
  }
}
