@import "../../theme.styles.scss";

.context-menu-container {
  position: fixed;
  box-sizing: border-box;
  z-index: 10;
  box-shadow: $boxShadow-small;
  border-radius: 6px;
  // border: 1px solid rgb(255, 253, 253);
  background-color: white;
  margin: auto;
  padding: 3px;
}

.context-menu-item-container {
  display: flex;
  justify-content: flex-start;
  color: $primary;
  gap: 0.5rem;
  padding: 0.6rem;
  border-radius: 4px;
}

.context-menu-item-container:hover {
  cursor: pointer;
  background-color: $secondary3;
  color: black;
}
