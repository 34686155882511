$bar-color: #b8c2cc !default;
$bar-stroke: #8d99a6 !default;
$border-color: #e0e0e0 !default;
$light-bg: #f5f5f5 !default;
$light-border-color: #ebeff2 !default;
$light-yellow: #fcf8e3 !default;
$text-muted: #666 !default;
$text-light: #555 !default;
$text-color: #333 !default;
$blue: #1a82c4 !default;
$handle-color: #ddd !default;

.gantt {
  .grid-background {
    fill: none;
  }
  .grid-header {
    fill: #ffffff;
    stroke: $border-color;
    stroke-width: 1.4;
  }
  .grid-row {
    fill: #ffffff;
  }
  .grid-row:nth-child(even) {
    fill: $light-bg;
  }
  .row-line {
    stroke: $light-border-color;
  }
  .tick {
    stroke: $border-color;
    stroke-width: 0.2;
    &.thick {
      stroke-width: 0.4;
    }
  }
  .today-highlight {
    fill: $light-yellow;
    opacity: 0.5;
  }
  .weekend-highlight {
    fill: $light-bg;
    opacity: 0.5;
  }

  .arrow {
    cursor: pointer;
    fill: none;
    stroke: $text-muted;
    stroke-width: 1.4;
  }

  .bar {
    fill: $bar-color;
    stroke: $bar-stroke;
    stroke-width: 0;
    transition: stroke-width 0.3s ease;
    user-select: none;
  }
  .bar-progress {
    fill: $blue;
  }
  .bar-invalid {
    fill: transparent;
    stroke: $bar-stroke;
    stroke-width: 1;
    stroke-dasharray: 5;

    & ~ .bar-label {
      fill: $text-light;
    }
  }
  .bar-label {
    fill: #fff;
    dominant-baseline: central;
    text-anchor: middle;
    font-size: 12px;
    font-weight: lighter;

    &.big {
      fill: $text-light;
      text-anchor: start;
    }
  }

  .handle {
    fill: $handle-color;
    cursor: ew-resize;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
  }

  .endpoint {
    fill: $handle-color;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .endpoint-active {
    fill: $blue;
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  .bar-wrapper {
    cursor: pointer;
    outline: none;

    .bar-group-wrapper {
      outline: none;
    }

    &:hover:not(.disabled) {
      .bar {
        fill: darken($bar-color, 5);
      }

      .bar-progress {
        fill: darken($blue, 5);
      }

      .handle {
        visibility: visible;
        opacity: 1;
      }

      .endpoint.end {
        cursor: copy;
        stroke: $blue;
        stroke-width: 1px;
        opacity: 1;
      }
      .endpoint.start {
        cursor: default;
      }
    }

    &.active {
      .bar {
        fill: darken($bar-color, 5);
      }

      .bar-progress {
        fill: darken($blue, 5);
      }
    }
  }

  .lower-text,
  .upper-text {
    font-size: 12px;
    text-anchor: middle;
  }
  .upper-text {
    fill: $text-light;
    font-weight: 600;
  }
  .lower-text {
    fill: $text-color;
    font-weight: 400;
  }

  .hide {
    display: none;
  }
}

.gantt-swimlanes-container {
  position: relative;
  overflow: auto;
  font-size: 12px;
  float: left;

  .gantt-swimlanes {
    .swimlanes-header {
      fill: #ffffff;
      stroke: $border-color;
      stroke-width: 1.4;
    }

    .swimlanes-background {
      fill: #ffffff;
      stroke: $border-color;
      stroke-width: 1.4;
    }

    .separ {
      stroke: $light-border-color;
    }

    .swimlane {
      .swimlane-label {
        padding: 10px;
        fill: black;
        font-weight: 500;
      }
      .swimlane-row {
        fill: #ffffff;
        stroke: $border-color;
        stroke-width: 1.4;
      }

      .sub-swimlane {
        .sub-swimlane-label {
          padding: 10px;
          fill: black;
        }

        .sub-swimlane-row {
          padding: 10px;
          fill: #ffffff;
          stroke: $border-color;
          stroke-width: 1.4;
        }
      }
    }
  }
}

.gantt-container {
  position: relative;
  overflow: auto;
  font-size: 12px;
  height: 100%;

  .popup-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    padding: 0;
    color: #959da5;
    border-radius: 3px;

    .title {
      border-bottom: 3px solid $blue;
      padding: 10px;
    }

    .subtitle {
      padding: 10px;
      color: #dfe2e5;
    }

    .new-progress {
      padding: 10px;
      color: red;
    }
    .new-dependencies {
      padding: 10px;
      color: red;
    }
    .new-drag {
      padding: 10px;
      color: red;
    }

    .pointer {
      position: absolute;
      height: 5px;
      margin: 0 0 0 -5px;
      border: 5px solid transparent;
      border-top-color: rgba(0, 0, 0, 0.8);
    }
  }
}
