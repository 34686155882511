@import "../../../theme.styles.scss";

.admin__blog {
  display: flex;
  width: 100%;
  border-radius: 8px;
  .editor-container {
    margin: 0px;
    display: flex;
    padding: 3px 5px;
    min-height: calc(100% - 90px);
    // flex-direction: column;
    // height: 100%;
    // max-height: 60vh;
    // overflow-x: hidden;
    // min-height: 50vh;
    .editor-inner {
      overflow-y: auto;
    }
  }
}

.admin__blog-editor-wrapper {
  display: flex;
  width: 100%;
  padding: 20px;
}

.admin__blog-editor {
  display: flex;
  flex-direction: column;
  width: 100%;
  // padding: 10px 20px;
  background: white;
  border-radius: 8px 0px 8px 8px;
  box-shadow: 0px 3px 4px -2px #00000021;
}

.admin__editor-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 16px;
  box-shadow: 0px 3px 4px -2px #00000021;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: $primary;
    }
  }
}

.admin__editor-title {
  // margin-top: 16px;
  padding: 5px 16px;
  background: white;
  width: 100%;
  min-width: 200px;
  outline: none;
  border: none;
  font-size: 24px;
  font-weight: 500;
  color: black;
  border-radius: 8px 0px 0px 0px;
}

.admin__editor-sidebar {
  display: flex;
  flex-direction: column;
  width: 270px;
  row-gap: 10px;
}

.blog_editor-sidebar-section {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding-top: 5px;
  padding-left: 20px;
}

/* FORM ACTIONS */

.admin__editor-actions {
  background: white;
  // border-top: 1px solid #bbb;
  width: 100%;
  height: 50px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  border-radius: 0px 0px 6px 6px;
  box-shadow: 0px -3px 4px -2px #00000021;
  z-index: 5;
}

.admin__editor-action-grp {
  display: flex;
  column-gap: 50px;
}

.admin__blog-featuredimg {
  width: 270px;
  background: white;
  border-radius: 0px 8px 8px 0px;
  box-shadow: 0px 3px 4px -2px #00000021;
  z-index: 6;
  padding-top: 5px;
  h4 {
    text-align: center;
    font-size: 0.8em;
  }
  img {
    // display: absolute;
    width: 270px;
    height: 180px;
    object-fit: cover;
    // border-radius: 6px;
    // border-radius: 6px 0px 6px 0px;
    border: 4px solid white;
  }
  input[type="file"] {
    display: none;
  }
}

.admin_image-options {
  display: flex;
  flex-direction: column;
  height: 180px;
  row-gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 0px 5px;
}

.admin_imagelink {
  position: absolute;
  display: flex;
  background: white;
  border-radius: 6px;
  padding: 10px;
  flex-direction: column;
  box-shadow: $boxShadow-small;
  row-gap: 10px;
  border: 1px solid $secondary2;
  transform: translateX(10%) translateY(85%);
  input {
    width: 200px;
    border: 1px solid $secondary2;
    padding: 5px 10px;
    border-radius: 4px;
  }
}

.admin_imagelinkdelbtn {
  position: absolute;
  transform: translateY(-40px);
  display: flex;
  align-items: center;
  // justify-content: center;
  right: 40px;
  z-index: 200;
  padding: 5px;
  background: white;
  border: 1px solid $secondary2;
  border-radius: 4px;
}

.admin_image-upload {
  border: 1px solid #ccc;
  // display: inline-block;
  display: flex;
  column-gap: 5px;
  align-items: center;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
}

.blog_editor-author {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 6px;
  padding: 5px 10px;
  white-space: nowrap;
  column-gap: 5px;
  p {
    font-weight: 300;
  }
  strong {
    font-weight: 500;
  }
}

.admin__socials {
  display: flex;
  height: 40px;
  // width: 200px;
  justify-content: space-between;
  padding: 0px 30px 0px 5px;
  align-items: center;
  svg {
    width: 1.2em;
    height: 1.2em;
    &:hover {
      cursor: pointer;
      color: #1a82c4;
    }
  }
  h4 {
    cursor: pointer;
    margin-left: -5px;
  }
}

.admin_social {
  display: flex;
  align-items: center;
}
.socialbadge {
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  background: none;
  border: none;
  // border-radius: 50%;
  width: 4em;
  height: 32px;
  font-size: 0.8em;
  text-align: right;
}
.socialbadge:focus {
  background: rgba(255, 255, 255, 0.6);
  height: 32px;
  font-size: 1.2em;
  width: 3em;
  transform: translateX(-6px);
  transition: all 0.2s ease;
  // text-align: center;
  border-radius: 8px;
}

.editor_tags-section {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  width: 100%;
}
.editor_tags-section-header {
  display: flex;
  align-items: center;
  column-gap: 5px;
  width: 100%;
  height: 32px;
  input {
    max-width: 150px;
    padding: 2px 6px;
    border-radius: 4px;
  }
}

.editor_addtag-btn {
  // width: 32px;
  // height: 32px;
  // background: $secondary3;
  border-radius: 4px;
}

.topicsearchresults {
  position: absolute;
  width: 144px;
  right: 40px;
  background: white;
  border-radius: 0px 0px 6px 6px;
  padding: 5px 10px;
  transform: translateY(32px);
  box-shadow: $boxShadow-small;
}

.admin__excerpt {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  height: 100%;
  width: 100%;
  // h4 {
  //   margin-left: 10px;
  // }
  textarea {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 7px 12px;
    border-radius: 0px 6px 6px 6px;
    border: 1px solid $secondary2;
    box-shadow: 0px 3px 4px -2px #00000021;
  }
}

.admin__editor-editlist {
  // background: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.admin__editor-editlist-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border: 1px solid $secondary2;
  border-radius: 12px 0px 0px 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  row-gap: 10px;
  box-shadow: 0px 3px 4px -2px #00000021;
}

.admin__editor-editlist-btn {
  background: $secondary3;
  display: flex;
  align-self: flex-end;
  position: relative;
  padding: 5px 15px;
  border: 1px solid $secondary2;
  border-radius: 6px 0px 0px 6px;
  text-align: start;
  width: 150px;
  //   font-size: 1.1em;
  //   font-weight: 500;
  box-shadow: 0px 3px 4px -2px #00000021;
  z-index: 100;
}

.admin__editor-editlist-btn-active {
  background: white;
  display: flex;
  align-self: flex-end;
  position: relative;
  padding: 5px 15px;
  border: 1px solid $secondary2;
  border-radius: 6px 0px 0px 0px;
  text-align: start;
  width: 150px;
  //   font-size: 1.1em;
  //   font-weight: 500;
  box-shadow: 0px 3px 4px -2px #00000021;
  z-index: 100;
  text-decoration: underline 2px solid $brand;
}

.admin__editor-editlist-item {
  margin-top: -30px;
  .blogpost-card {
    box-shadow: 0px 0px 2px 1px #00000021;
    width: 170px;
    border: 1px solid $secondary2;
    padding: 5px;
  }
  img {
    height: 100px;
  }
}

.admin__editor-editbtn {
  position: relative;
  background: $secondary3;
  padding: 5px 10px;
  border-radius: 6px;
  border: 2px solid $primary;
  transform: translate(115px, 60px);
  box-shadow: 0px -3px 4px -2px #00000021;
  z-index: 101;
}
.admin__editor-editbtn-active {
  position: relative;
  background: $secondary3;
  padding: 5px 10px;
  border-radius: 6px 0px 0px 6px;
  border: 2px solid $brand;
  transform: translate(90px, 60px);
  box-shadow: 0px -3px 4px -2px #00000021;
  text-decoration: underline 2px solid $brand;
  z-index: 101;
}
.admin__editor-editbtn-active::after {
  content: " \01F8A1";
}

.blog_editor-tag-container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.editor_tags {
  display: flex;
  width: 480px;
  column-gap: 10px;
  overflow-y: auto;
  padding: 5px 0px;
  input {
    width: 80px;
  }
  button {
    // display: flex;
    // text-align: start;
    // align-items: flex-start;
    font-size: 0.7em;
    font-weight: 500;
    background: $secondary3;
    border-radius: 6px;
    padding: 5px 10px;
    white-space: nowrap;
    &:hover {
      color: $brand;
      // text-decoration: underline 2px $brand;
      transition: all 0.1s ease-in;
    }
  }
}
