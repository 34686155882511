@import "../../../theme.styles.scss";

.item__faq {
  border-radius: 8px;
  box-shadow: $boxShadow-small;
  height: fit-content;
  details {
    font-size: 14px;
    line-height: 30px;
  }
  h5 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
  }
}
