@import "../../../theme.styles.scss";
.timeFilter {
  display: flex;
  // grid-template-columns: 1fr 1fr;
  // flex-direction: column;
  row-gap: 2px;
  // column-gap: 2px;
  overflow: hidden;
  background: $secondary2;
  // padding: 4px;
  // height: 100px;
  align-items: flex-end;
  justify-content: flex-start;
  overflow: visible;
  // border: 1px solid $primary;
  border-radius: 4px;
}
.timeFilter-item {
  display: flex;
  font-size: 1em;
  justify-content: center;
  align-items: center;
  padding: 2px 6px;
  border: 1px solid $secondary2;
  background: $secondary3;
  // margin-left: -3px;
  margin-left: 0px;
  cursor: pointer;
  transition: all 0.2s ease;
  &:first-child {
    border-left: 1px solid $secondary2;
  }
  &:last-child {
  }
  p {
    opacity: 0.5;
  }
  &:hover {
    background: white;
    p {
      opacity: 1;
    }
  }
}
// .timeFilter-past {
//   transform: translateY(60px) translateX(130px) rotate(-87deg) scale(1.5);
//   margin-left: -20px;
// }
// .timeFilter-today {
//   transform: translateY(60px) translateX(80px) rotate(-85deg) scale(1.5);
// }
// .timeFilter-tomorrow {
//   transform: translateY(60px) translateX(50px) rotate(-89deg) scale(1.5);
//   margin-left: -24px;
// }
// .timeFilter-week {
//   transform: translateY(60px) translateX(10px) rotate(-88deg) scale(1.5);
//   margin-left: -25px;
// }
// .timeFilter-month {
//   transform: translateY(60px) translateX(-35px)  rotate(-87deg) scale(1.5);
//   margin-left: -25px;
// }
.timeFilter__active {
  background: white;
  width: max-content;
  p {
    color: $primary;
    opacity: 1;
  }
}

@media (max-width: 500px) {
.timeFilter-item:nth-child(4) {
  display: none;
}
.timeFilter-item:nth-child(5) {
  display: none;
}
.timeFilter-past {
  transform: none;
  margin-left: 0;
}
.timeFilter-today {
  transform: none;
  margin-left: 0;
}
.timeFilter-tomorrow {
  transform: none;
  margin-left: 0;
}

  .timeFilter-week {
display: none;
  }
  .timeFilter-month {
    display: none;
  }

}
