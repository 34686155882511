@import "../../theme.styles.scss";
.color-palette {
  position: absolute;
  display: grid;
  grid-template-columns: repeat(10, 36px);
  grid-gap: 4px;
  width: 100%;
  transform: translateY(40px);
  .color1 {
    background: $primary;
  }
  .color2 {
    background: #89afb4;
  }
  .color3 {
    background: #d4745e;
  }
  .color4 {
    background: #eecd81;
  }
  .color5 {
    background: #bf94b2;
  }
  .color6 {
    background: #826860;
  }
  .color7 {
    background: #b887ac;
  }
  .color8 {
    background: #d1624f;
  }
  .color9 {
    background: #d6d85a;
  }
  .color10 {
    background: #39a7a4;
  }
  .color11 {
    background: #6c77a7;
  }
  .color12 {
    background: #f8df51;
  }
  .color13 {
    background: #e4a380;
  }
  .color14 {
    background: #dc8e8d;
  }
  .color15 {
    background: #6daed1;
  }
  .color16 {
    background: #727272;
  }
  .color17 {
    background: #daa4bb;
  }
  .color18 {
    background: #ebc194;
  }
  .color19 {
    background: #efe47f;
  }
  .color20 {
    background: #a4d1e4;
  }
  .color21 {
    background: #8dc2b0;
  }
  .color22 {
    background: #aec977;
  }
  .color23 {
    background: #7398c0;
  }
  .color24 {
    background: #b2b2b2;
  }
  .color25 {
    background: #aca1be;
  }
  .color26 {
    background: #c76573;
  }
}
.palette_color {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  transition: 0.2s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    box-shadow: inset 0px -4px 0 0 rgba(0, 0, 0, 0.3);
  }
}

// .colorpicker_container {
//   display: flex;
//   height: 35px;
//   width: 35px;
//   input {
//     width: 35px;
//     height: 35px;
//   }
// }

.colorpicker_btn {
  position: absolute;
  padding: 10px 20px;
  background: white;
  border-radius: 6px;
  border: 2px solid $secondary3;
  transform: translateX(35px) translateY(-6px);
  box-shadow: $boxShadow-large;
  white-space: nowrap;
  color: $primary;
  &:hover {
    border: 2px solid $secondary2;
    color: black;
  }
}

.colorpicker_rounded {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  width: 37px;
  height: 42px;
  margin-top: -2px;
  margin-left: -1px;
  cursor: pointer;
  border: none;
}
.colorpicker_rounded::-webkit-color-swatch {
  border-radius: 50%;
  background: linear-gradient(135deg, orange 30%, cyan);
  // border: 7px solid $secondary3;
  border: none;
  &:hover {
    transform: scale(1.05);
    box-shadow: inset 0px -4px 0 0 rgba(0, 0, 0, 0.3);
  }
}
.colorpicker_rounded::-moz-color-swatch {
  border-radius: 50%;
  background: linear-gradient(135deg, orange 30%, cyan);
  // border: 7px solid $secondary3;
  border: none;
  &:hover {
    transform: scale(1.05);
    box-shadow: inset 0px -4px 0 0 rgba(0, 0, 0, 0.3);
  }
}
