.step3 {
}
.step3__create {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.step3__h2 {
  margin-bottom: 5px !important;
}
.step3__p {
  margin-bottom: 20px;
  text-align: center;
}

.step3__modules {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
  .moduleList {
    min-width: 49%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
