@import "../../../theme.styles.scss";

.blogpost-single-page {
  display: flex;
  padding: 130px 10% 120px 10%;
  // height: 100vh;
  // width: 70%;
  padding-top: 130px;
  column-gap: 150px;
  // flex-direction: column;
  row-gap: 40px;
  align-items: flex-start;
  background: white;
  p {
    font-size: 18px;
    line-height: 30px;
  }
  h2 {
    font-size: 32px;
    line-height: 50px;
    font-weight: 600;
  }
  h3 {
    font-size: 28px;
    line-height: 40px;
    font-weight: 400;
  }
  h4 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
  }
  h5 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
  }
}

.blogpost-single-pagedata {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.blogpost-single-page-image {
  img {
    width: 100%;
    height: 20vh;
    object-fit: cover;
    border-radius: 6px;
  }
}

.breadcrumb-homepage {
  display: flex;
  align-items: center;
  column-gap: 5px;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    background: none;
    border-radius: 4px;
    &:hover {
      background: $secondary3;
    }
  }
}

@media (max-width: 768px) {
  .blogpost-single-page {
    padding: 100px 15px 40px 15px;
    flex-direction: column;
  }
}
