.signIn {
  // width: 300px;
  display: flex;
  // width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  padding: 20px 60px;
  // flex-wrap: wrap;
  // margin-top: 90px
  // .retroBox {
  //   box-shadow: none;
  //   position: relative;
  // }
}

.signIn__content {
  height: 100vh;
  // width: 100%;
  overflow: hidden;
  // padding: 30px;
  width: 100vw;
  // width: 300px;
  position: relative;
}

.signIn__forgotPassword {
  position: absolute;
  bottom: 105px;
  cursor: pointer;
  display: flex;
  p {
    text-align: center;
    font-weight: 500;
  }
  span {
    font-weight: 700;
  }
}

.signIn__noAccount {
  position: absolute;
  bottom: 40px;
  cursor: pointer;
  display: flex;
  p {
    text-align: center;
  }
  span {
    font-weight: 700;
  }
}

.signIn__register-content,
.signIn__login-content,
.signIn__reset-content {
  // width: 240px;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  // row-gap: 30px;
  input {
    min-width: 270px;
  }
  h2 {
    margin-bottom: 10px;
    text-align: center;
  }
}

// .signIn__reset-content {
//   margin-top: 60px;
//   margin-bottom: 60px;
// }

.signIn__scroll {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
