@import "../../theme.styles.scss";

.assigned__cal {
  display: flex;
  flex-direction: row;
  // column-gap: 40px;
  height: 100%;
  // justify-content: flex-end;
  padding: 10px 20px 0px 20px;
  column-gap: 30px;
}

.assigned__taskscolumn {
  display: flex;
  flex-direction: column;
  max-width: 480px;
  .sectionBox {
    .retroBox {
      height: calc(100vh - 165px);
      overflow-y: auto;
    }
  }
}

.assigned__taskscolumn-hide {
  display: none;
}

.assignedTasks {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 9px 30px -10px;
  border-radius: 0px 5px 5px 5px;
  height: calc(100vh - 140px);
  overflow-y: auto;
  padding: 20px 20px 20px 20px;
  .children {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .retroButton.flat {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 7px;
    padding-bottom: 7px;
    background: $body;
    border: 1px solid rgba(0, 0, 0, 0.1);
    // height: 36px;
    border-radius: 6px;
    p {
      // padding: 5px;
      // font-size: 12px;
      font-weight: 500;
      // font-family: "Poppins", sans-serif;
      text-align: start;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.assignedTasksHide {
  height: calc(100vh - 150px);
  background: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 9px 30px -10px;
  .at__fromspace-list {
    display: none;
  }
}
.at__task {
  background: #eff2f5;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  p {
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.at__check-and-name {
  display: flex;
  // align-items: center;
  column-gap: 6px;
}
.at__modules {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  div {
    display: flex;
    justify-content: center;
  }
}
.at__priority {
  margin-left: 5px;
  pointer-events: none;
}
.at__status {
  pointer-events: none;
}
.at__noTasks {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  svg {
    opacity: 0.1;
    margin-bottom: 15px;
  }
  p {
    opacity: 0.4;
  }
}
.at__by {
  .avatar {
    width: 25px !important;
    height: 25px !important;
  }
}
.at__item {
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0px;
  }
}
.at__fromspace-list {
  display: flex;
  flex-direction: column;
}

.at__showlist {
  height: auto;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-top: 10px;
  // margin-bottom: 0px;
  max-width: 380px;
}
.at__hidelist {
  height: 0px;
  display: none;
}
.at__fromspace-list-btn {
  display: flex;
  align-items: center;
  transition: 0.2s;
  column-gap: 5px;
  // &:hover {
  //   transform: scale(1.03);
  // }
  // &:active {
  //   transform: scale(0.97);
  // }
  button {
    font-size: 14px;
    text-transform: uppercase;
    div {
      font-weight: 500;
    }
  }
  .retroButton {
    // width: 12px;
    height: 22px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 4px;
  }
  h4 {
    font-weight: 500;
    font-size: 12px;
  }
}

.assigned__taskscolumn-half {
  display: flex;
  flex-direction: column;
  width: 50px;
  // border: 1px solid black;
  .sectionBox {
    .retroBox {
      height: calc(100vh - 300px);
      overflow-y: auto;
      padding: 10px 20px 20px 10px;
    }
  }
  .sb__title {
    padding-top: 70px;
    // display: none;
    p {
      display: none;
    }
  }
}

.assigned__taskscolumn-hide {
  display: none;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  height: 100px;
  min-width: 45px;
  margin-right: -25px;
  // width: 20px;
  // left: -20px;
  // bottom: 40px;
  top: 500px;
  padding: 6px 0px;
  color: $primary;
  border-radius: 0px 6px 6px 0px;
  border: 1px solid $secondary2;
  cursor: pointer;
  z-index: 50;
  background-color: $body;
  svg {
    opacity: 1;
    &:hover {
      opacity: 0.7;
    }
  }
  .assigned__taskscolumn-hidetext {
    margin-bottom: 5px;
    display: flex;
    transform: rotate(-90deg);
    font-size: 12px;
    font-weight: 600;
    // width: 20px;
    position: absolute;
    right: 18px;
    top: 42px;
  }
  &:hover {
    transform: scale(1.05);
    transition: all 0.1s ease;
    background-color: white;
  }
}

.this_project-items {
  background-color: #303030;
  border: #303030;
  color: white;
}

.daysLeft {
  font-size: 10px;
  margin-left: 5px;
  p {
    font-size: 10px;
  }
}

.asgntaskwidgetheader {
  background: white;
  padding: 5px 10px;
  border-radius: 5px 5px 0px 0px;
  align-items: center;
  box-shadow: rgb(0 0 0 / 20%) 0px -9px 30px -10px;
  // border: 1px solid black;
  // display: flex;
  justify-content: space-between;
  column-gap: 10px;
  width: fit-content;
  z-index: 5;
  display: flex;
  transition: all 0.5s ease;
  h3 {
    font-size: 16px;
    transition: all 0.5s ease;
    font-weight: 500;
    font-family: "Noto Sans JP", sans-serif;
  }
}
.asgntaskwidgetheader-rotated {
  background: white;
  padding: 5px 5px;
  border-radius: 5px 5px 0px 0px;
  align-items: center;
  box-shadow: rgb(0 0 0 / 20%) 0px -9px 30px -10px;
  // border: 1px solid black;
  // display: flex;
  justify-content: space-between;
  column-gap: 10px;
  // width: fit-content;
  z-index: 5;
  display: flex;
  flex-direction: column-reverse;
  transition: all 0.5s ease;
  row-gap: 20px;
  margin-bottom: -37px;
  h3 {
    font-size: 16px;
    transform: rotate(-90deg);
    transition: all 0.5s ease;
    white-space: nowrap;
    margin-top: 30px;
    font-weight: 500;
    font-family: "Noto Sans JP", sans-serif;
  }
}
.asgntaskwidgetheader-btn {
  transform: rotate(0deg);
  transition: all 0.5s ease;
}
.asgntaskwidgetheader-btn {
  transform: rotate(-180deg);
  transition: all 0.5s ease;
}

.minicalwidgetheader {
  background: white;
  padding: 5px 10px;
  border-radius: 5px 5px 0px 0px;
  align-items: center;
  box-shadow: rgb(0 0 0 / 20%) 0px -9px 30px -10px;
  // border: 1px solid black;
  // display: flex;
  justify-content: space-between;
  column-gap: 10px;
  width: 134px;
  z-index: 5;
  h3 {
    font-size: 16px;
    font-weight: 500;
    font-family: "Noto Sans JP", sans-serif;
  }
}

.at__checkBox {
  color: black;
  svg {
    width: 17px;
    height: 17px;
  }
}

.at__content {
  display: flex;
  align-items: center;
  p {
    font-size: 1.1em;
  }
}

.at__daysLeft {
  width: 45px;
  p {
    font-size: 10px;
  }
  display: flex;
  align-self: flex-end;
}

@media (max-width: 500px) {
  .assignedTasks {
    row-gap: 20px;
    .retroButton.flat {
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 7px;
      padding-bottom: 7px;
      background: $body;
      border: 1px solid rgba(0, 0, 0, 0.1);
      // height: 36px;
      border-radius: 6px;
      p {
        // padding: 5px;
        // font-size: 12px;
        font-weight: 500;
        // font-family: "Poppins", sans-serif;
        text-align: start;
      }
    }
  }

  .at__content {
    p {
      font-size: 14px;
    }
  }

  .assigned__cal {
    justify-content: space-between;
    align-items: start;
    column-gap: 0px;
    padding: 0px;
    // height: auto;
  }

  .assigned__taskscolumn {
    width: 20px;
    overflow: hidden;
    padding: 0 0 0 0;
    box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.4);
    border-radius: 0px 6px 6px 0px;
    margin-top: 50px;
    height: calc(100vh - 250px);
    border: 1px solid $secondary3;
    border-left: none;
    // &:first-child {
    //   display: none;
    // }
    // ::after {
    //   background: white;
    // }
    .sectionBox {
      .retroBox {
        padding: 0px;
        padding-left: 20px;
        overflow-x: hidden;
        // background: $body;
        // border: 1px solid black;
      }
    }
    .sb__title {
      display: none;
    }
  }
  .assigned__taskscolumn-half {
    display: flex;
    flex-direction: column;
    width: 50vw;
    // height: calc(100vh - 150px);
    // overflow-y: auto;
    // overflow-x: hidden;
    // box-shadow: 1px 2px 4px gray;
    margin-top: 2px;
    // box-shadow: 1px 2px 4px gray;
    // border-radius: 0px 6px 6px 0px;
    // margin-top: 60px;
    // border: 1px solid black;
    .sectionBox {
      .retroBox {
        height: calc(100vh - 250px);
        overflow-y: auto;
        padding: 10px 20px 20px 10px;
        border-radius: 0px 6px 6px 0px;
        box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.4);
        border: 1px solid $secondary3;
        border-left: none;
        padding-top: 20px;
      }
    }
    .sb__title {
      padding-top: 10px;
      // display: none;
      p {
        display: none;
      }
    }
  }
  // .assigned__taskscolumn {
  //   display: flex;
  //   width: 30vw;
  //   flex-direction: column;
  //   padding: 22px 20px 20px 20px;
  // }
  .asgntaskwidgetheader {
    display: none;
  }
  .asgntaskwidgetheader-rotated {
    display: none;
  }
  .minicalwidgetheader {
    display: none;
  }
  .assigned__taskscolumn-hide {
    display: flex;
    // flex-direction: column;
    justify-content: flex-end;
    position: relative;
    height: 70px;
    top: 324px;
    // width: 20px;
    // left: -20px;
    // bottom: 40px;
    // bottom: 150px;
    padding: 6px 0px;
    color: $primary;
    border-radius: 0px 6px 6px 0px;
    border: 1px solid $secondary2;
    cursor: pointer;
    z-index: 50;
    background: rgba(255, 255, 255, 0.7);
    // background-color: $body;
    box-shadow: 2px 2px 8px 1px #00000021;
    svg {
      opacity: 1;
      &:hover {
        opacity: 0.7;
      }
    }
    .assigned__taskscolumn-hidetext {
      margin-bottom: 5px;
      display: flex;
      transform: rotate(-90deg);
      font-size: 12px;
      color: $primary;
      font-weight: 600;
      width: 20px;
    }
    &:hover {
      transform: scale(1.05);
      transition: all 0.1s ease;
      background-color: white;
    }
  }

  .at__modules {
    // display: none;
    width: 15px;
    align-self: flex-end;
    svg {
      width: 12px !important;
      height: 12px !important;
    }
    .at__daysLeft {
      p {
        font-size: 10px;
      }
      display: none;
      align-self: flex-end;
    }
    .at__by {
      display: none;
    }
  }

  .at__showlist {
    // max-width: 205px;
    margin-top: 5px;
    p {
      font-size: 14px;
      text-align: left;
    }
    .retroButton {
      border-radius: 6px;
      padding-left: 5px;
      padding-right: 2px;
      padding-top: 10px;
      padding-bottom: 10px;
      display: flex;
      flex-direction: row;
      .children {
        display: flex;
        width: 100%;
        flex-direction: row;
        column-gap: 3px;
        justify-content: space-between;
      }
      p {
        font-size: 14px;
        // font-weight: 600;
      }
    }

    .retroButton .flat {
      height: 100%;
    }
  }

  // .toolbar::-webkit-scrollbar {
  //   display: none;
  // }

  // .toolbar::-webkit-scrollbar-track {
  //   height: 0px;
  // }

  .assignedTasksHide {
    width: 50vw;
    height: calc(100vh - 95px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 15px 0px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    border-radius: 0px 0px 6px 0px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 9px 30px -10px;
    // z-index: 0;
    .children {
      display: flex;
      flex-direction: column;
    }
    // .at__modules {
    //   display: none;
    // }
    .at__fromspace-list {
      display: flex;
      padding: 0px 10px;
    }
  }
  .assignedTasksHide::-webkit-scrollbar {
    display: none;
  }
  .assignedTasksHide::-webkit-scrollbar-track {
    height: 0px;
  }
  .assigned__taskscolumn-hidetasks {
    margin-top: 3px;
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    // font-weight: 600;
    // width: 20px;
    transform: rotate(0deg);
    transition: all 0.3s ease;
  }
  .assigned__taskscolumn-hidetasks-rotated {
    margin-top: 3px;
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    // font-weight: 600;
    // width: 20px;
    transform: rotate(-180deg);
    transition: all 0.3s ease;
  }

  // .at__check-and-name {
  //   column-gap: 5px;
  //   i {
  //     svg {
  //       width: 28px;
  //       height: 28px;
  //     }
  //   }
  // }
  .at__checkBox {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.fc-event-time {
  font-weight: 400;
}

.fc-v-event .fc-event-title {
  font-weight: 500;
}
