@import "../../../../theme.styles.scss";
.task__logic {
  position: relative;
  &:hover {
    .task__drag {
      opacity: 1;
    }
    .subtask__label-gray {
      opacity: 1 !important;
    }
  }
}
.task {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 8px 4px 9px;
  margin-bottom: 8px;
  background: white;
  align-items: center;
  border-radius: 8px;
  transition: 0.2s ease all;
  .loadModule {
    height: auto;
  }
  .bl__content {
    width: 70%;
    min-width: 500px;
    max-width: 1000px;
  }

  &:hover {
    border: 1px solid #34b5e4;
    border: 1px solid rgba(0, 0, 0, 0.2);

    .task__expand {
      opacity: 0.2;
    }
    .status {
      filter: grayscale(0%);
      opacity: 1;
    }
    .daysLeft,
    .deadline,
    .assign {
      opacity: 1;
    }
    .checkBox {
      opacity: 1 !important;
    }
  }
}
.task__card {
  display: flex;
  i {
    margin-right: 10px;
    cursor: pointer;
    color: $primary;
    margin-left: -2px;
  }
  svg {
    width: 17px;
    height: 17px;
    color: $primary;
    transition: 0.2s ease;
    &:hover {
      color: black;
      transform: scale(1.1);
    }
  }
  p {
    text-decoration: none;
    color: $primary;
    padding-top: 1px;
    font-weight: 400;
  }
}

.task__card-done {
  display: flex;
  i {
    margin-right: 10px;
    cursor: pointer;
    // color: $secondary;
    margin-left: -2px;
  }
  svg {
    // color: $secondary2;
    transition: 0.2s ease;
    &:hover {
      color: black;
      transform: scale(1.1);
    }
  }
  p {
    text-decoration: line-through;
    // color: $secondary2;
    padding-top: 1px;
  }
}
.task__drag {
  position: absolute;
  top: 5px;
  left: -12px;
  opacity: 0;
  svg {
    color: lightgray;
  }
}
.task__taskName {
  font-weight: 500;
  margin-left: 5px;
}

.task__expand {
  position: absolute;
  top: 5px;
  right: 8px;
  opacity: 0;
  transition: 0.2s ease;
  cursor: pointer;
  &:hover {
    opacity: 0.4;
    transform: scale(1.15);
  }
  &:active {
    transform: slace(0.95);
  }
}
.task__comments {
  position: absolute;
  top: 6px;
  right: 30px;
  opacity: 1;
  transition: 0.2s ease;
  cursor: pointer;
  svg {
    color: #ccc;
  }
  p {
    position: absolute;
    top: 3px;
    display: flex;
    width: 15px;
    justify-content: center;
    opacity: 1;
    color: #666;
    font-size: 10px;
    font-weight: 800;
  }
  &:hover {
    transform: scale(1.15);
  }
  &:active {
    transform: slace(0.95);
  }
}
/* .task__modules {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  grid-auto-flow: dense;
  justify-content: center;
  margin-top: 20px;
  opacity: 0.3;
  transition: 0.2s ease;
  filter: grayscale(100%);
} */
.task__modules {
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
  /*   font-weight: 500; */
  p {
    font-size: 11px;
  }
  .assign {
    .assign__choose {
      // margin-left: 100px;
      position: absolute;
      left: 270%;
    }
  }
  .loadModule {
  }
  // MODUELS
  .deadline,
  .assign,
  .status {
    opacity: 1;
    transition: 0.2s ease;
  }
  .status {
    /*  filter: grayscale(100%); */
  }
}
.task__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .checkBox {
    margin-right: 5px;
  }
}
.task__done {
  .task__taskName {
    opacity: 0.6;
    p {
      text-decoration: line-through;
    }
  }
  .task__modules {
    opacity: 0.6;
    filter: grayscale(100%);
    pointer-events: none;
  }
}

.task__expandPlace {
  position: absolute;
  width: 83%;
  height: 100%;
  top: 0;
  left: 36px;
}

.board_animation-card {
  position: absolute;
  width: 100px;
  height: 50px;
}

@media (max-width: 500px) {
  .task__card {
    i {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
