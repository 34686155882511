@import "../../../theme.styles.scss";
.sec__pricing {
  display: flex;
  padding: 30px 10% 60px 10%;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 40px;
}

.sec_priceoptions {
  display: flex;
  align-items: center;
  column-gap: 50px;
  width: 100%;
  justify-content: center;
  button {
    margin-top: 10px;
    width: 160px;
    align-self: center;
  }
}

.sec_priceoption {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 12px;
  box-shadow: $boxShadow-small;
  width: 400px;
  padding: 30px 40px;
  row-gap: 10px;
  h4 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
  }
  p {
    font-size: 16px;
    line-height: 24px;
  }
  ul {
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: flex-start;
    column-gap: 10px;
  }
  svg {
    min-width: 22px;
    margin-top: 5px;
  }
  h6 {
    font-size: 18px;
    line-height: 32px;
    font-weight: 500;
    b {
      font-size: 24px;
      font-weight: 600;
    }
  }
}

.sec__pricing-heading {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  h2 {
    font-size: 24px;
    line-height: 40px;
    font-weight: 600;
    text-align: center;
  }
  h3 {
    font-size: 20px;
    line-height: 36px;
    font-weight: 400;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .sec__pricing {
    padding: 30px 20px 40px 20px;
  }
  .sec_priceoptions {
    flex-direction: column;
    row-gap: 30px;
  }
  .sec_priceoption {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .sec__pricing {
    flex-direction: column;
  }
}
