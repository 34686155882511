.meet_participants {
  display: grid;
  grid-template-columns: repeat(var(--grid-size), 1fr);
  grid-gap: 5px;
  /* max-height: calc(100vh - 100px); */
  max-height: 350px;
  height: 100%;
  padding: 5px 5px 0px 5px;
  overflow-y: auto;
}
.meet_participantsTall {
  display: grid;
  grid-template-columns: repeat(var(--grid-size), 1fr);
  grid-gap: 5px;
  max-height: calc(100vh - 100px);
  height: 100%;
  padding: 5px 5px 0px 5px;
  overflow-y: auto;
}
.meet_participantsFS {
  display: grid;
  grid-template-columns: repeat(var(--grid-size), 1fr);
  grid-gap: 15px;
  height: calc(100vh - 80px);
  padding: 10px;
  overflow-y: auto;
}

.meet_participants img {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

@media (max-width: 800px) {
  .meet_participants {
    /* grid-template-columns: repeat(var(--grid-col-size), 1fr); */
    grid-template-rows: repeat(var(--grid-row-size), 1fr);
    grid-template-columns: 1fr;
  }
  .meet_participantsFS {
    height: calc(100vh - 80px);
  }
  .meet_participantsTall {
    max-height: calc(100dvh - 130px);
  }
}
