@import "../../../theme.styles.scss";
.sec__collaboration {
  display: flex;
  padding: 100px 10%;
  padding-bottom: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  row-gap: 80px;
  width: 100%;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  background: white;
  p {
    font-size: 17px;
    line-height: 30px;
  }
  h2 {
    font-size: 28px;
    line-height: 40px;
    font-weight: 600;
  }
  h3 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
  }
  h4 {
    font-size: 22px;
    line-height: 32px;
    font-weight: 600;
  }
  section {
    scroll-snap-align: center;
  }
}

.sec__collaboration-header {
  align-items: center;
  justify-content: center;
  h2 {
    font-size: 28px;
    line-height: 40px;
    font-weight: 600;
    text-align: center;
  }
  h3 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
    text-align: center;
  }
}

.sec_collab {
  display: flex;
  column-gap: 60px;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  :nth-child(odd) {
    p {
      text-align: right;
      background: white;
      padding: 20px 10px;
      border-radius: 0px 12px 0px 12px;
      box-shadow: $boxShadow-small;
    }
    h4 {
      text-align: right;
      background: white;
      width: fit-content;
      padding: 5px 10px;
      border-radius: 12px 12px 0px 0px;
      box-shadow: $boxShadow-small;
      z-index: 5;
    }
    img {
      align-self: flex-start;
    }
  }
  :nth-child(even) {
    p {
      text-align: left;
      background: white;
      padding: 20px 10px;
      border-radius: 0px 12px 0px 12px;
      box-shadow: $boxShadow-small;
    }
    h4 {
      text-align: left;
      background: white;
      width: fit-content;
      padding: 5px 10px;
      border-radius: 12px 12px 0px 0px;
      box-shadow: $boxShadow-small;
      z-index: 5;
    }
    img {
      align-self: flex-end;
    }
  }

  div {
    width: 480px;
  }
  // section {
  //   width: 600px;
  // }
}

// .sec_collab {
//   display: flex;
//   flex-direction: row;
//   column-gap: 20px;
//   width: 100%;
//   justify-content: center;
//   align-items: flex-start;
//   flex-wrap: wrap;
//   :nth-child(odd) {
//     p {
//       text-align: right;
//       background: white;
//       padding: 20px 10px;
//       border-radius: 0px 12px 0px 12px;
//       box-shadow: $boxShadow-small;
//     }
//     h4 {
//       text-align: right;
//       background: white;
//       width: fit-content;
//       padding: 5px 10px;
//       border-radius: 12px 12px 0px 0px;
//       box-shadow: $boxShadow-small;
//       z-index: 5;
//     }
//     img {
//       align-self: flex-start;
//     }
//   }
//   :nth-child(even) {
//     p {
//       text-align: left;
//     }
//     h4 {
//       text-align: left;
//     }
//     img {
//       align-self: flex-end;
//     }
//   }

//   div {
//     width: 600px;
//   }
//   section {
//     width: 600px;
//   }
// }

.sec__collaboration-features {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 150px;
  :nth-child(odd) {
    flex-direction: row;
    // text-align: center;
    .sec_collab-card p {
      text-align: right;
    }
    .sec_collab-cardHeader {
      display: flex;
      justify-content: flex-end;
    }
    .collab__visual {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  :nth-child(even) {
    flex-direction: row-reverse;
    // text-align: left;
    .sec_collab-card p {
      text-align: left;
    }
    .sec_collab-cardHeader {
      display: flex;
      justify-content: flex-start;
    }
    .collab__visual {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.sec_collab-yayy {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  z-index: 2;
  padding-bottom: 80px;
}

// .sec_collabinfo-left p {
//   text-align: left;
// }

// .sec_collabinfo-right p {
//   text-align: right;
// }

// .collab__visual {
//   // width: 100%;
//   // min-width: 15vw;
//   display: flex;
//   width: 400px;
//   height: 200px;
//   align-items: center;
//   justify-content: center;
//   img {
//     object-fit: contain;
//   }
// }

.sec_collab-card {
  min-width: 300px;
  // display: flex;
  // flex-direction: column;
  // :nth-child(odd) {

  //   align-self: flex-end;
  //   h4 {
  //     align-self: flex-end;
  //     text-align: right;
  //   }
  // }
  // :nth-child(odd) {
  //   align-self: flex-start;
  //   h4 {
  //     text-align: left;
  //     align-self: flex-start;
  //   }
  // }
}

.sec_collab-visual {
  display: flex;
  :nth-child(odd) {
    flex-direction: row;
    justify-content: flex-end;
  }
  :nth-child(even) {
    flex-direction: row-reverse;
    justify-content: flex-start;
  }
}

@media (max-width: 768px) {
  .sec__collaboration {
    padding: 40px 20px;
  }
  .sec_collab-middleline {
    z-index: -1;
  }
  .sec_collab {
    div {
      width: 100%;
    }
  }
  .sec_collab-card {
    min-width: none;
    margin-bottom: 30px;
  }
  .sec__collaboration-features {
    row-gap: 60px;
  }
}

@media (max-width: 500px) {
  .sec__collaboration {
    flex-direction: column;
  }
}
