.deadline {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    .tooltip {
      opacity: 1;
      width: 110px;
    }
  }
  svg {
    opacity: 0.1;
    transition: 0.2s ease opacity;
    &:hover {
      opacity: 0.3;
    }
  }
  input {
    border: none;
    position: absolute;
    opacity: 1;
    width: 30px;
    left: -5px;
    z-index: 100;
    opacity: 0;
  }
  ::-webkit-datetime-edit-fields-wrapper {
    background: silver;
    min-width: 100px;
    opacity: 1;
  }
  ::-webkit-calendar-picker-indicator {
    background: orange;
    position: absolute;
    cursor: pointer;
    left: 0;
    margin: 0;
    opacity: 1;
    width: 100%;
  }
  p {
    color: gray;
  }
}
