.resetpasword {
  height: 100;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  // margin-top: 60px;
  p {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
  // .retroButton {
  //   margin-bottom: 50px;
  // }
}

.resetpasword__error {
  margin-top: 5px;
  p {
    color: red;
  }
}

.resetpasword__success {
  margin-top: -30px;
  p {
    color: rgb(0, 82, 0);
  }
}
