@import "../../theme.styles.scss";

.progress__nodes {
  position: relative;
  top: 10px;
  z-index: 80;
  input {
    width: 5%;
    height: 10px;
    background: transparent;
    color: transparent;
    border: none;
    font-size: 8px;
    cursor: pointer;
    &:hover {
      display: none;
      // position: absolute;
      // width: 15px;
      // height: 15px;
      // align-content: center;
      // align-items: center;
      // text-align: center;
      // color: white;
      // background: black;
      // border-radius: 100%;
    }
  }
  input:focus {
    outline: none;
    caret-color: transparent;
  }
}
