@import "../../theme.styles.scss";

.paywall_container {
  display: flex;
//   .retroBox {
//     position: relative;
//     width: 70%;
//     justify-content: center;
//     left: 140px;
//     bottom: 140px;
//   }
    .retroBox {
      // position: relative;
      // max-width: 50%;
      // top: 100px;
      // left: 50%;
      // transform: translate(-50%, -50%);
      display: flex;
      width: "100%";
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 5px;
      padding-left: 5px;
      margin-left: 30px;
      margin-right: 30px;
      min-width: 70vw;
    }
  b {
    color: black;
    font-weight: 600;
  }
  i {
    color: black;
    font-weight: 600;
  }
  a {
    color: black;
    font-weight: 600;
  }
  p {
    font-size: 16px;
    line-height: 1.6;
    color: $primary;
    margin-bottom: 20px;
  }
  h2 {
    margin-bottom: 30px;
    text-align: center;
  }
  img {
    max-height: 270px;
    max-width: 320px;
    height: 100%;
  }
}
.invPremium__inner {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  align-items: center;
}
.cs__createPop {
  width: 550px;
}
.cs__create-btn {
  display: flex;
  justify-content: flex-end;
}

.paywall_columns {
  display: flex;
  flex-direction: "row";
  padding-right: 30px;
}

.paywall_columnleft {
  display: flex;
  flex-direction: column;
  // width: 200px;
  align-items: center;
  row-gap: 20px;
  padding-left: 20px;
  // justify-content: center;
}

.paywall_columnright {
  // display: flex;
  // width: "100%";
  // max-width: 1040px;
  margin-left: -2px;
  border: 2px solid $primary;
  border-radius: 10px;
  iframe {
    width: 1040px;
    border-radius: 10px;
  }
}

.planOption {
font-weight: 500;
font-size: large;
border: 2px solid $bodyGray;
width: 190px;
border-radius: 8px 0px 0px 0px;
padding: 10px 30px;
}

.selectedPlanOpt {
  font-weight: 600;
  font-size: large;
  border: 2px solid $primary;
  width: 190px;
  border-radius: 8px 0px 0px 0px;
  padding: 10px 30px;
}

@media (max-width: 500px) {
  .cs__createPop {
    width: 94% !important;
  }
}
