.login {
  // height: 100%;
  position: relative;
  display: flex;
  width: "100%";
  // height: "100vh";
  align-items: center;
  justify-content: space-around;
  column-gap: 90px;
  row-gap: 30px;
  flex-wrap: wrap;
  padding-bottom: 80px;
  h2 {
    font-weight: 500;
  }
}

.login__error {
  margin-top: 10px;
  p {
    color: red;
  }
}
