@import "../../theme.styles.scss";

.custom__chart {
  text-align: center;
  padding: 10px;
  background: white;
  box-shadow: rgb(0 0 0 / 20%) 0px 9px 30px -10px;
  border-radius: 0px 5px 5px 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom__chart-graph {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 225px;
}

.custom__chart-graph-nodata {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  height: 100%;
  align-items: center;
  justify-content: center;
  height: 260px;
  text-align: center;
  h4 {
    color: $primary;
    width: 200px;
    text-align: center;
  }
  svg {
    color: $secondary2;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.chart__settings-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.chart__settings-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .ch__selectperson {
    right: unset;
    bottom: 60px;
  }
}

.chart_user-avatar {
  display: flex;
  align-items: center;
  .avatar {
    position: relative;
    width: 36px;
    height: 36px;
  }
}

.chart_username {
  display: flex;
  border-radius: 0px 6px 6px 0px;
  background: $secondary3;
  padding: 2px 4px;
  padding-left: 10px;
  margin-left: -6px;
  column-gap: 3px;
  align-items: center;
  font-size: 0.8em;
  height: 25px;
  p {
    max-width: 60px;
    font-size: 1em;
    overflow: hidden;
  }
}

.chart__settings-top select {
  background: $secondary3;
  height: 25px;
  border-radius: 4px;
  border: none;
  padding: 0px 6px;
  &:focus {
    outline: none;
  }
  option {
    color: inherit;
    background-color: $secondary3;
  }
}

.chart__btn-group {
  display: flex;
  // align-self: flex-end;
  align-items: center;
  justify-content: flex-end;
  column-gap: 5px;
  right: 5px;
  width: 100%;
  input {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    padding-top: 2px;
  }
  input:focus {
    border: none;
    outline: none;
  }
  button {
    display: flex;
    text-align: center;
    min-width: 25px;
    // max-width: 100px;
    height: 25px;
    background: $secondary3;
    // border-radius: 5px;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    &:last-child {
      width: auto;
      padding: 5px;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 5px;
      white-space: nowrap;
    }
  }
}

.chart__btn-group-nav {
  display: flex;
  // align-self: flex-end;
  align-items: center;
  justify-self: center;
  justify-content: center;
  // column-gap: 5px;
  // right: 5px;
  width: 100%;
  input {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    padding-top: 2px;
  }
  input:focus {
    border: none;
    outline: none;
  }
  &:first-child {
  }
  button {
    display: flex;
    text-align: center;
    min-width: 25px;
    // max-width: 100px;
    height: 25px;
    border: 1px solid $secondary3;
    background: none;
    // border-radius: 5px;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    &:first-child {
      width: auto;
      padding: 5px;
      background: none;
      background: $secondary3;
      border-radius: 5px 0px 0px 5px;
    }
    &:last-child {
      width: auto;
      padding: 5px;
      background: none;
      background: $secondary3;
      border-radius: 0px 5px 5px 0px;
    }
  }
}

.chart__switch {
  display: flex;
  column-gap: 10px;
}

.chart-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $secondary3;
  border-radius: 4px;
  width: 25px;
  height: 25px;
  svg {
    width: 16px;
    height: 16px;
  }
}
.chart-btn-passive {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $secondary3;
  border-radius: 4px;
  width: 25px;
  height: 25px;
  svg {
    width: 16px;
    height: 16px;
  }
}

.chart__datepicker {
  border: none;
  // width: fit-content;
  // max-width: fit-content;
  z-index: 100;
  width: 100%;
}
.chart__datepicker-day {
  max-width: 100px;
  overflow: hidden;
}

.chart__datepicker-week {
  max-width: 110px;
  overflow: hidden;
}
.chart__datepicker-month {
  max-width: 115px;
  overflow: hidden;
}
.chart__datepicker-day,
.chart__datepicker-week,
.chart__datepicker-month {
  input {
    width: fit-content;
    height: 25px;
    border: none;
    background-color: rgba(255, 255, 255, 0);
    margin-top: -2px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 13px;
    text-align: center;
  }
}

.chart__datepicker-wrp {
  width: inherit;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.custom__chart-btngroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 10px;
  width: 100%;
  position: relative;
  bottom: -37px;
}
.chartBtn {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  border-top: 1px solid transparent;
  border-radius: 0px 0px 8px 8px;
  box-shadow: rgb(0 0 0 / 20%) 0px 9px 30px -10px;
}
.chartBtn-active {
  background: white;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  border-top: 1px solid white;
  box-shadow: rgb(0 0 0 / 20%) 0px 9px 30px -10px;
  border-radius: 0px 0px 8px 8px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
