@import "../../theme.styles.scss";

.tm__durationest {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $body;
  border: 1px solid $bodyGray;
  border-radius: 10px;
  align-items: center;
  ::placeholder {
    font-size: 8px;
  }
  input {
    width: 40px;
    text-align: center;
    padding: 0.1rem 0.1rem;
    padding-left: 0.3rem;
    border: none;
    outline: none;
    background: none;
    color: #888;
    font-size: 11px;
    font-weight: 100;
  }
  button {
    background: none;
    color: $primary;
    padding-right: 2px;
    padding-top: 2px;
  }
  &:hover {
    .tooltip {
      opacity: 1;
      // width: 90px;
      right: -12px;
      top: -20px;
    }
  }
}

.duration__container {
  width: 100%;
  position: absolute;
  right: 0px;
  top: 40px;
  display: grid;
  grid-template-columns: 50px 50px 50px 50px 50px;
  row-gap: 10px;
  column-gap: 10px;
  background: $body;
  border: 1px solid $secondary2;
  border-radius: 10px;
  padding: 10px;
  z-index: 60;
  box-shadow: $boxShadow-small !important;
}
.duration__tag {
  background: $secondary3;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 10px;
  padding: 0.5em;
  cursor: pointer;
  &:hover {
    background-color: $secondary2;
  }
}
