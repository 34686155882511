@import "../../theme.styles.scss";

.recurring {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  position: relative;
  width: 100%;
  //   max-width: 328;
}

.recurring__options {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.recurring_mainsettings {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  position: relative;
  width: 100%;
}

.recurring__showmore-btn {
  margin-top: 0px;
  align-self: flex-start;
}

.recurring_confirm {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  column-gap: 20px;
}

.recurring_confirm-btn {
  // background: $secondary3;
  width: 50px;
  border-radius: 6px;
  svg {
    // color: #1a82c4;
    width: 36px;
    height: 36px;
  }
  &:hover {
    svg {
      color: #1a82c4;
    }
  }
}

// .recurring__custom {
//   display: flex;
//   flex-direction: column;
//   row-gap: 20px;
//   max-width: 328;
// }

.recurring__custom {
  display: flex;
  position: absolute;
  top: 30px;
  right: -10px;
  padding: 10px;
  width: 350px;
  flex-direction: column;
  row-gap: 20px;
  max-width: 328;
  background: white;
  z-index: 50;
  border: 1px solid $secondary3;
  border-radius: 6px;
  box-shadow: $boxShadow-small !important;
}

.recurring__customfreq {
  display: flex;
  column-gap: 0.5em;
  align-items: center;
  justify-content: flex-end;
  input {
    width: 3em;
    text-align: center;
    padding: 1px 2px;
  }
  label {
    font-size: 12px;
  }
  label:first-child {
    font-size: 12px;
    font-weight: 600;
  }
}

.Recurring__Row {
  display: flex;
  //   direction: column;
  // border: 1px solid gray;
  border-radius: 3px;
  margin-bottom: 0px;
  position: relative;
  // column-gap: 1em;
  align-items: center;
  justify-content: space-between;
  label {
    font-size: 12px;
  }
}

.recurring__end {
  display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  column-gap: 30px;
  width: 100%;
  label {
    font-size: 12px;
  }
  label:first-child {
    font-size: 12px;
    font-weight: 600;
  }
}

.recurring__endrow {
  display: flex;
  //   column-gap: 1em;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  row-gap: 20px;
  .inverval_number {
    min-width: 4em;
    text-align: center;
    padding: 1px 2px;
  }
  label {
    font-size: 12px;
  }
}

.recurring__endrowdate {
  display: flex;
  align-items: center;
  column-gap: 3px;
  width: 150px;
  //   input {
  //     width: 1em;
  //   }
  input:last-child {
    width: 10em;
    text-align: center;
    font-size: 11px;
    padding: none;
  }
  label {
    font-size: 12px;
  }
  .react-datepicker-wrapper {
    max-width: 110px;
  }
  .react-datepicker__input-container {
    max-width: 110px;
    input {
      max-width: 110px;
    }
  }
}

.recurring__endrowoption {
  display: flex;
  align-items: center;
  column-gap: 2px;
  input {
    width: 1em;
    text-align: center;
  }
  label {
    font-size: 12px;
  }
}

.timerangeslider_container {
  position: relative;
  width: 100%;
  height: 40px;
  // padding: 5px;
  // padding-left: 40px;
  // background: #fcfcfc;
  // border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // box-shadow: 0px 15px 40px #7E6D5766;
  input {
    accent-color: black;
  }
  p {
    text-align: right;
  }
}

.timerangeslider {
  display: flex;
  flex-direction: column;
  accent-color: black;
  width: 100%;
  row-gap: 10px;
  input {
    padding-left: 18px;
  }
}

.recurring_weekdays {
  display: flex;
  width: 100%;
  justify-content: space-between;
  row-gap: 10px;
  align-items: center;
  label {
    font-size: 12px;
    font-weight: 600;
  }
  label:first-child {
    font-size: 12px;
    font-weight: 600;
  }
  .react-datepicker-wrapper {
    max-width: 90px;
  }
  .react-datepicker__input-container {
    max-width: 90px;
    input {
      max-width: 90px;
    }
  }
  input {
    padding-left: 5px;
  }
}

.recurring_freq {
  color: $primary;
  background-color: $secondary3;
  padding: 0.5em;
  border-radius: 6px;
  font-size: 12px;
}

.recurring_freq-active {
  color: white;
  background-color: $primary;
  padding: 0.5em;
  border-radius: 6px;
  font-size: 12px;
}

.recurring_weekday-active {
  width: 30px;
  color: white;
  background-color: $primary;
  border-radius: 6px;
  padding: 4px;
  overflow: hidden;
  float: left;
}

.recurring_weekday {
  width: 30px;
  color: $primary;
  background-color: $secondary3;
  border-radius: 6px;
  padding: 4px;
  overflow: hidden;
  float: left;
}

// .slider p {
// font-size: 26px;
// font-weight: 600;
// font-family: Open Sans;
// padding-left: 30px;
// color: black;
// }
// .slider input[type="range"] {
// -webkit-appearance:none !important;
// // width: 420px;
// height: 2px;
// background: $primary;
// border: none;
// outline: none;
// }
// .slider input[type="range"]::-webkit-slider-thumb {
// -webkit-appearance: none !important;
// width: 30px;
// height:30px;
// background: $primary;
// border: 2px solid $primary;
// border-radius: 50%;
// cursor: pointer;
// }
// .slider input[type="range"]::-webkit-slider-thumb:hover {
// background: black;
// }
