.modules {
  // width: 100%;
  // margin-left: 50px;
  // margin-right: 50px;
  // margin-top: 9px;
  display: flex;
  // align-self: flex-end;
  justify-content: flex-end;
  .modules__button {
    .retroButton {
      border-radius: 0px 0px 6px 6px;
    }
    .svg {
      color: black;
    }
  }
}

.modules__window {
  position: relative;
  // right: 40px;
  // top: 100px;
  width: 150px;
}
.modules__win {
  .retroBox {
    padding: 10px !important;
    border-radius: 12px 0px 12px 12px;
  }
  .bl__content {
    right: 20px;
    top: 95px;
    width: 150px;
    position: absolute;
  }
  .bl__layer {
    background: transparent;
    backdrop-filter: none;
  }
}
