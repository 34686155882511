.createStation {
  .retroBox {
    position: relative;
  }
  h2 {
    margin-bottom: 30px;
    text-align: center;
  }
}
.cs__createPop {
  width: 550px;
}
.cs__create-btn {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 500px) {
  .cs__createPop {
    width: 94% !important;
  }
}
