@import "../../../../theme.styles.scss";
.sec_legal {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 120px 160px;
  p {
    font-size: 1em;
  }
  li {
    margin-left: 40px;
    font-size: 1em;
  }
  strong {
    font-weight: 700;
  }
  h1 {
    margin-bottom: 20px;
    font-weight: 700;
  }
}

@media (max-width: 768px) {
  .sec_legal {
  }
}
