.meet_main-screen{
    width: 100%;
    /* height: 100%; */
    /* background: #3c4043; */
}
.meet_main-screenFS{
    width: 100%;
    height: 100%;
    background: #3c4043;
}

.meet_wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.meet_wrapperFS{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.meet_footer{
    height: 50px;
}
.meet_footerFS{
    height: 80px;
}

@media (max-width: 800px) {
    .meet_footer{
        height: 70px;
    }
  }