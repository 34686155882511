.createdBy {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  .avatar {
    width: 25px;
    height: 25px;
    cursor: pointer;
    box-shadow: none;
  }
  &:hover {
    .cb__nameHover {
      opacity: 1;
    }
    .tooltip {
      opacity: 1;
      width: 80px;
      top: -45px;
    }
  }
}
.cb__nameHover {
  position: absolute;
  width: 100px;
  text-align: center;
  background: white;
  border-radius: 8px;
  top: -15px;
  margin-left: 5px;
  opacity: 0;
  z-index: 100;
  transition: 0.2s ease opacity;
  color: #666;
}
