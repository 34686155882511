.app {
  display: flex;
  width: 100%;
  overflow-x: hidden;
}
.appinner {
  display: flex;
  width: 100%;
  overflow: hidden;
}
.space-container {
  height: 100vh;
}
.home-container {
  width: 100%;
}

.app_frontend {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: white;
}

@media (max-width: 500px) {
  .app {
    display: flex;
    flex-direction: column;
  }
  .appinner {
    display: flex;
    flex-direction: column;
  }
  .space-container {
    height: 100vh;
  }
  // .home-container {
  //   width: 100%;
  // }
}
