.textArea {
  textarea {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    padding: 15px;
    width: 100%;
    max-width: 100% !important;
    height: 100px;
    border: 1px solid rgb(228, 228, 228);
    border-radius: 10px;
    resize: none;
    transition: 0.2s ease;
    &:focus {
      outline: none;
      border: 1px solid rgb(179, 179, 179);
    }
  }
}
