// @import '~@fullcalendar/core/main.css';
// @import '~@fullcalendar/daygrid/main.css';
@import "../../theme.styles.scss";

// .fc-theme-standard td, .fc-theme-standard th {
// border: none;
// }

.calendarView {
  display: flex;
  height: calc(100vh - 80px);
  padding: 0px 10px 10px 0px;
  // padding: 10px 10px 10px 0px;
  // padding: 10px;
}

.calendar__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: calc(100vh - 80px);
  // column-gap: 10px;
  width: 100%;
  // font-family: sans-serif;
  // text-align: center;

  .fc-scrollgrid {
    border-color: transparent !important;
  }
  .fc-scrollgrid td:last-of-type {
    border-right-color: transparent !important;
  }
  .fc-scrollgrid-section.fc-scrollgrid-section-body td[role="presentation"] {
    border-bottom-color: transparent !important;
  }
  [role="row"]:last-of-type td {
    border-bottom-color: transparent !important;
  }
  th[role="presentation"] {
    border-right-color: transparent !important;
  }
  .fc-theme-standard th[role="columnheader"] {
    border-right-color: transparent !important;
  }
  .fc-theme-standard td[role="columnheader"] {
    border-right-color: transparent !important;
  }
  th[class="fc-timegrid-axis"] {
    border-right-color: transparent !important;
  }

  //   .fc table {
  //   font-size: 0.9em;
  // }

  .fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
    font-size: 0.8em;
    font-weight: 500;
  }

  .fc {
    /* the calendar root */
    // max-width: 96%;
    // padding: 10px 0px 0px 0px;
    // margin: 0 auto;
    // display: flex;
    position: relative;
  }

  // .fc-button-group {
  //   &:last-child {
  //     column-gap: 5px;
  //   }
  // }

  .fc .fc-button-primary {
    background: transparent;
    color: #000000;
    border-color: #dce1ec;
    font-size: 14px;
    border: none;
    // margin: 4px 0px;
  }

  .fc .fc-button-primary:not(:disabled).fc-button-active,
  .fc .fc-button-primary:not(:disabled):hover {
    background: $secondary3;
    // background: none;
    color: $primary;
    border-color: #dce1ec;
    font-size: 14px;
    text-decoration: underline 2px black;
  }

  .fc .fc-button-primary:disabled {
    background: rgb(247, 248, 251);
    color: #c5c5c5;
    border-color: #dce1ec;
    font-size: 14px;
  }

  .fc .fc-daygrid-day.fc-day-today {
    background-color: rgba(#ebebeb, 0.6);
    background-color: var(--fc-today-bg-color, rgba(#ebebeb, 0.6));
  }

  .fc .fc-timegrid-col.fc-day-today {
    background-color: rgba(#ebebeb, 0.2);
    background-color: var(--fc-today-bg-color, rgba(#ebebeb, 0.2));
  }

  // .fc-col-header {
  //   background-color: rgba(#ebebeb, 0.8);
  //   background-color: var(--fc-today-bg-color, rgba(#ebebeb, 0.8));
  // }

  .fc .fc-toolbar.fc-header-toolbar {
    // padding: 0.5em 0em 0em 0.7em;
    margin-bottom: 0.4em;
  }

  .fc .fc-toolbar-title {
    font-size: 1.2em;
    margin: 0;
    font-weight: 600;
    &:nth-word(2) {
      font-weight: 300;
    }
  }
  .fc-toolbar-title:nth-word(2) {
    font-weight: 300;
  }

  .fc-header-toolbar-title.fc-toolbar-chunk:nth-child(2) {
    font-weight: 300;
  }
}

// .list__column-wrapper {
//   overflow-y: scroll;
// }

.modaldiv {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-end;
}

.list__column {
  box-sizing: border-box;
  background-color: $body;
  border-radius: 0px 0px 8px 8px;
  margin-right: -10px;
  position: relative;
  z-index: 0;
  // height: calc(100vh - 100px);
  width: 180px;
  min-width: 180px;
  padding: 15px 12px;
  padding-right: 22px;
  overflow-y: auto;
  overflow-x: hidden;
  // overflow-x: hidden;
  // box-shadow: 1px 1px 1px rgba(54, 54, 54, 0.1);
  // 2px 2px 2px rgba(54, 54, 54, 0.2), inset 1px 1px 1px rgba(54, 54, 54, 0.1),
  // inset 2px 2px 2px rgba(54, 54, 54, 0.2);
  ul {
    list-style-type: none;
  }
}

.list__column::-webkit-scrollbar {
  display: none;
}
.list__column::-webkit-scrollbar-track {
  height: 0px;
}

.list__column-description {
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  margin-bottom: 24px;
  h4 {
    text-align: center;
    font-weight: 500;
    text-decoration: underline solid 2px;
  }
  p {
    font-size: 11px;
    color: $secondary;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
}

.calendar__settings__win {
  .retroBox {
    padding: 10px 15px !important;
    border-radius: 12px 0px 12px 12px;
  }
  .bl__content {
    right: 20px;
    top: 95px;
    // width: 150px;
    position: absolute;
  }
  .bl__layer {
    background: transparent;
    backdrop-filter: none;
  }
}

.calendar_settings-buttons {
  // position: absolute;
  display: flex;
  flex-direction: column;
  right: 0px;
  row-gap: 30px;
  width: 200px;
  transition: all 0.3s ease;
  height: 100%;
}

.calendar_filters-buttons {
  // position: absolute;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  right: 0px;
  row-gap: 20px;
  width: max-content;
  // margin-left: -5px;
  transition: all 0.3s ease;
  height: calc(100% - 180px);
  z-index: 50;
  .bll__content {
    margin-top: -15px;
    .retroBox {
      width: unset;
    }
  }
}
.calendar_filter-btn {
  border: 1px solid $secondary2;
  // height: 24px;
  padding: 5px 8px;
  border-radius: 0px 5px 5px 0px;
  width: fit-content;
  max-width: 120px;
  text-align: start;
  // white-space: nowrap;
  display: flex;
  flex-direction: row;
  row-gap: 8px;
  align-items: center;
  font-weight: 500;
}
.calendar_filter-btn-active {
  background: white;
  border: 1px solid $secondary2;
  // height: 24px;
  padding: 5px 8px;
  border-radius: 0px 5px 5px 0px;
  width: max-content;
  color: black;
  font-weight: 600;
  margin-left: -10px;
  box-shadow: 2px -2px 4px -2px #00000021;
  // white-space: nowrap;
  max-width: 120px;
  text-align: start;
  display: flex;
  flex-direction: row;
  row-gap: 8px;
  align-items: center;
  z-index: 1;
}
// .calendar_filters-buttons-off {
//   // position: absolute;
//   display: flex;
//   flex-direction: column;
//   right: 0px;
//   row-gap: 20px;
//   padding: 50px 20px;
//   margin-right: -200px;
//   width: 200px;
//   transition: all 0.3s ease;
// }

.listcolumn__hide {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  height: 100px;
  width: 30px;
  left: 170px;
  top: 420px;
  padding: 6px 0px;
  color: $primary;
  border-radius: 0px 6px 6px 0px;
  border: 1px solid $secondary2;
  cursor: pointer;
  z-index: 50;
  background-color: $body;
  box-shadow: 2px 2px 8px 1px #00000021;
  svg {
    opacity: 1;
    align-self: center;
    &:hover {
      opacity: 0.7;
    }
  }
  span {
    margin-bottom: 12px;
    display: inline-block;
    transform: rotate(-90deg);
    font-size: 12px;
    font-weight: 600;
  }
  &:hover {
    transform: scale(1.05);
    transition: all 0.1s ease;
    background-color: white;
  }
}

.addtask__plus {
  position: absolute;
  height: 56px;
  width: 56px;
  right: 114px;
  bottom: 30px;
  padding: 6px 12px;
  color: $primary;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 50;
  background-color: white;
  box-shadow: 2px 2px 8px 1px #00000021;
  svg {
    opacity: 1;
    &:hover {
      opacity: 0.7;
    }
  }
}

.calcolumn__hide {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  height: 100px;
  width: 30px;
  right: 130px;
  top: 270px;
  padding: 6px 0px;
  color: $primary;
  border-radius: 6px 0px 0px 6px;
  border: 1px solid $secondary2;
  cursor: pointer;
  z-index: 50;
  background-color: $body;
  box-shadow: 2px 2px 8px 1px #00000021;
  svg {
    opacity: 1;
    align-self: center;
    &:hover {
      opacity: 0.7;
    }
  }
  span {
    margin-bottom: 12px;
    display: inline-block;
    transform: rotate(-90deg);
    font-size: 12px;
    font-weight: 600;
  }
  &:hover {
    transform: scale(1.05);
    transition: all 0.1s ease;
    background-color: white;
  }
}

.custom__calendar {
  width: 100%;
  // width: calc(100% - 200px);
  display: inline-block;
  // height: 100%;
  height: calc(100vh - 100px);
  // padding: 150px;
  background: white;
  // padding: 0px 5px;
  padding-right: 5px;
  position: relative;
  border-radius: 0 8px 8px 8px;
  z-index: 0;
  box-shadow: 2px 2px 8px 1px #00000021;
  // overflow-y: scroll;
}

#external-events .fc-event {
  position: relative;
  color: black;
  white-space: normal;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 6px 8px 4px 9px;
  margin-bottom: 8px;
  background: white;
  align-items: center;
  border-radius: 8px;
  transition: 0.2s ease all;
  cursor: grab;
}

.custom__button1 {
  background: black;
}

.this_project-items {
  // background-color: #303030;
  // border: #303030;
  color: white;
}
.other_project-items {
  background-color: #49617e;
  border: #a12f2f;
}

.ProjectsButton {
  .fc-button {
    color: #a12f2f;
  }
}

.fc-icon-print::before {
  font-family: "Material Icons";
  content: "\e8ad";
  font-size: 24px;
}

.fc-WorkHoursButton-button {
  padding: 0 3px !important;
}
.fc-ProjectsButton-button {
  padding: 0 3px !important;
  &:active {
    background-color: #a12f2f;
  }
}

.cal__allprojects {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  .cal__seekhide-tip {
    height: 0px;
    display: none;
    opacity: 0;
    transition: all 0.6s ease;
  }
  &:hover {
    .cal__seekhide-tip {
      height: 60px;
      display: flex;
      opacity: 1;
      transition: all 0.6s ease;
    }
  }
}
.cal__allprojects {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  .cal__seekhide-tip {
    height: 0px;
    display: none;
    opacity: 0;
    transition: all 0.6s ease;
  }
  &:hover {
    .cal__seekhide-tip {
      height: 60px;
      display: flex;
      opacity: 1;
      transition: all 0.6s ease;
    }
  }
}
.cal__gCal {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  // .cal__gCal-tip {
  //   height: 0px;
  //   display: none;
  //   opacity: 0;
  //   transition: all 0.6s ease;
  // }
  // &:hover {
  //   .cal__gCal-tip {
  //     height: 240px;
  //     display: flex;
  //     flex-direction: column;
  //     opacity: 1;
  //     transition: all 0.6s ease;
  //     ul {
  //       margin-left: 1em;
  //     }
  //     li {
  //       list-style: disc outside none;
  //       display: list-item;
  //       margin-left: 1em;
  //       font-size: 11px;
  //       padding: 4px;
  //     }
  //   }
  // }
}
.cal__gCal-tip {
  // height: 240px;
  display: flex;
  flex-direction: column;
  opacity: 1;
  transition: all 0.6s ease;
  ul {
    margin-left: 1em;
  }
  li {
    list-style: disc outside none;
    display: list-item;
    margin-left: 1em;
    font-size: 11px;
    padding: 4px;
  }
}
.cal__gCal-header {
  display: flex;
  column-gap: 10px;
  margin-bottom: 30px;
  align-items: center;
  justify-content: space-between;
  h3 {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
}

.cal__gCal-form {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  // padding-right: 20px;
  // padding: 0px 10px;
  justify-content: space-between;
  align-items: center;
  input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid $secondary3;
    outline: none;
    padding: 5px 10px;
  }
  .cal__gCal-clearBtn {
    right: 0px;
    // // right: 20px;
  }
}
.cal__gCal-line {
  display: flex;
  width: 100%;
  column-gap: 15px;
  justify-content: space-around;
  align-items: center;
  input[type="color"] {
    width: 24px;
    height: 24px;
    // border-radius: 50%;
    // background: #000000;
    border: none;
    outline: none;
    padding: 0;
  }
  button {
    display: flex;
    align-items: center;
    background: $secondary3;
  }
  label {
    font-size: 0.7em;
  }
}
.cal__gCal-label {
  display: flex;
  align-items: center;
  column-gap: 5px;
  margin-left: 10px;
  font-size: 0.8em;
  text-decoration: underline 2px $secondary2;
  margin: -2px 10px -6px 10px;
  color: $secondary2;
}
.cal__gCal-radiobtn {
  display: flex;
  column-gap: 3px;
}
.cal__gCal-Input {
  display: flex;
  flex-direction: column;
  column-gap: 5px;
  padding-right: 20px;
  justify-content: space-between;
  align-items: center;
  input {
    width: 130px;
    border-radius: 5px;
    border: 1px solid $secondary3;
    outline: none;
    padding: 2px 3px;
  }
  .cal__gCal-clearBtn {
    right: 0px;
    // // right: 20px;
  }
}
.cal__print-btn {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
  padding: 3px 5px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    color: black;
    background: $secondary3;
  }
}
.cal__allprojects-btn {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
  // padding: 3px 5px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    color: black;
    background: $secondary3;
  }
}
.cal__weekend-btn {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
  padding: 3px 5px;
  border-radius: 8px;
  &:hover {
    color: black;
    background: $secondary3;
  }
}
.cal__unplanned-btn {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
  padding: 3px 5px;
  border-radius: 8px;
  &:hover {
    color: black;
    background: $secondary3;
  }
}
.cal__activehours {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  .cal__activehours-set {
    height: 0px;
    display: none;
    opacity: 0;
    transition: all 0.6s ease;
  }
  &:hover {
    .cal__activehours-set {
      height: 200px;
      display: flex;
      opacity: 1;
      transition: all 0.6s ease;
    }
  }
}
.cal__activehours-btn {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
  padding: 3px 5px;
  border-radius: 8px;
  &:hover {
    color: black;
    background: $secondary3;
  }
}
.cal__activehours-set {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 10px;

  button {
    border: 1px solid $secondary2;
    background: white;
    border-radius: 4px;
    padding: 3px 5px;
    color: $primary;
    width: 100%;
    &:hover {
      color: black;
      background: $secondary3;
    }
  }
}

.cal__slotpickers {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 5px;
  input {
    border-radius: 4px;
    border: 1px solid $secondary2;
    outline: none;
    padding: 1px 4px;
    // background: $secondary3;
    // border-bottom: 4px solid $secondary2;
  }
}

// .cal__event-done {
//   color: $secondary2;
//   text-decoration: line-through;
//   .fc-event {
//     background-color: blue;
//   }
//   .fc-timegrid-event-harness {
//     background-color: blue;
//     text-decoration: line-through;
//   }
//   .fc-list-event-dot {
//     background: #a12f2f;
//     // border-color: #a12f2f;
//     border-color: none;
//   }
// }
.cal__event-done {
  // background-color: gray;
  // border-color: green;
  // .fc-event-time {
  //   color: $secondary3;
  // }
  .fc-event-title.fc-sticky {
    // color: $secondary3;
    text-decoration: line-through;
  }
  .fc-event-title.fc-sticky::before {
    content: "\2714  ";
  }
  .fc-event-title::before {
    content: "\2713  ";
  }

  // color: $secondary2;
  // text-decoration: line-through;
  // .fc-event {
  //   background-color: blue;
  // }
  // .fc-timegrid-event-harness {
  //   background-color: blue;
  //   text-decoration: line-through;
  // }
  // .fc-list-event-dot {
  //   background: #a12f2f;
  //   // border-color: #a12f2f;
  //   border-color: none;
  // }
}
.gCal-events {
  // background-color: #1c4991e3;
  // border-color: #1c4991e3;
  // color: #1c4991e3;
  // .fc-event-time {
  //   color: $secondary3;
  // }
  .fc-event-title.fc-sticky {
    color: $secondary3;
  }
  .fc-event-title.fc-sticky::before {
    content: "🇬 ";
  }

  .fc-daygrid-event-dot {
    border: 4px solid #1c4991e3;
  }
  .fc-event-title::before {
    content: "🇬 ";
  }

  // color: $secondary2;
  // text-decoration: line-through;
  // .fc-event {
  //   background-color: blue;
  // }
  // .fc-timegrid-event-harness {
  //   background-color: blue;
  //   text-decoration: line-through;
  // }
  // .fc-list-event-dot {
  //   background: #a12f2f;
  //   // border-color: #a12f2f;
  //   border-color: none;
  // }
}

.holiday-events {
  .fc-event-title.fc-sticky::before {
    content: "🇺🇸 ";
  }
}

.calexternal_duration {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  p {
    text-align: center;
    width: 40px;
    font-size: 11px;
    border: 1px solid $secondary3;
    border-radius: 8px;
    padding: 1px 2px;
    background: $body;
  }
}

.cal__timezone-form {
  display: flex;
  margin-top: 5px;
  input {
    border-radius: 4px;
    border: 1px solid $secondary2;
    outline: none;
    padding: 5px 8px;
    max-width: 100%;
    // background: $secondary3;
    // border-bottom: 4px solid $secondary2;
  }
}

.cal__timezones {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-top: 12px;
  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    border: 1px solid $secondary2;
    // background: white;
    padding: 5px;
  }
  .timezone-active {
    border: 1px solid black;
    font-weight: 550;
    background: white;
  }
}

@media (max-width: 500px) {
  .calendarView {
    padding: unset;
    height: calc(100vh - 100px);
  }
  .listcolumn__hide {
top: 200px;
  }
  .custom__calendar {
    // width: 100%;
    flex: 1;
  }
  .calendar__wrapper {
    height: calc(100vh - 110px);
    position: relative;
    .fc .fc-toolbar.fc-header-toolbar {
      padding: 0em 0em 0em 0em;
      margin-bottom: 0em;
      display: flex;
      flex-direction: row;
      padding-top: 10px;
      row-gap: 6px;
    }
    .fc .fc-button-primary {
      font-size: 14px;
      padding: 6px;
    }
    .fc .fc-button-primary:not(:disabled).fc-button-active {
      font-size: 12px;
      padding: 4px;
    }
    .fc .fc-toolbar-title {
      font-size: 16px;
      font-weight: 600;
      // &:first-letter {
      //   font-size: 20px;
      // }
    }
    .fc-toolbar-chunk {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      button {
        padding: 0px;
      }
    }
    // h2::first-letter {
    //   font-size: 30px;
    // }
    .fc-dayGridMonth-button {
      padding: 0px;
    }
    .fc-button {
      padding: 0px;
    }
  }
  .addtask__plus {
    position: fixed;
    // height: 52px;
    // width: 52px;
    padding: none;
    // left: calc(100vw - 150px);
    right: 20px;
    padding: 5px 15px;
    bottom: 128px;
    align-items: center;
    justify-content: center;
    // top: 300px;
    // left: 150px
  }
  .calendar_filters-buttons {
    // display: none;
    height: 100%;
    position: absolute;
    background: white;
    box-shadow: 2px 2px 8px 1px #00000021;
  }
  .list__column {
    height: calc(100vh - 130px);
  }
  .calcolumn__hide {
    top: 150px;
    right: 120px;
  }
}
