@import "../../theme.styles.scss";

.recentStations {
  .rs__item {
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
    button {
      background: transparent;
      &:hover {
        background: rgb(240, 240, 240);
      }
      svg {
        margin-right: 5px;
      }
    }
  }
}

.recentStations__noStations {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  p {
    text-align: center;
    margin-bottom: 10px;
  }
}
.recentStations__noBtn {
  display: flex;
  justify-content: flex-end;
}
.recentStations__create {
  button {
    svg {
      margin-right: 5px;
      color: rgba(0, 0, 0, 0.2);
    }
  }
}

