@import "../../theme.styles.scss";
.stationIteminFolder {
  display: flex;
  margin-bottom: 5px;
  width: 145px;
  // min-width: 120px;
  // width: 150px;
  align-items: flex-start;
  a {
    text-align: start;
  }
  .EmojiIcon {
    width: 2em;
    margin-right: 0.2em;
    margin-left: 0;
  }
  // &:last-child {
  //   margin-bottom: 0;
  // }
  .retroButton {
    justify-content: flex-start;
    box-shadow: none;
    // width: 100%;
    border-radius: 6px 0px 0px 6px;
    // background: $secondary2;
    padding: none;
    padding-left: 7px;
    padding-right: 10px;
    max-width: 145px;
    svg {
      margin-right: 10px;
      font-weight: 200;
      opacity: 1;
    }
    &:hover {
      background: #f0f0f0;
    }
  }
}
.stationIteminFolder-active {
  margin-bottom: 5px;
  // min-width: 120px;
  // width: 150px;
  .EmojiIcon {
    width: 2em;
    margin-right: 0.2em;
    margin-left: 0;
  }
  // &:last-child {
  //   margin-bottom: 0;
  // }
  .retroButton {
    justify-content: flex-start;
    box-shadow: none;
    width: 100%;
    border-radius: 6px 0px 0px 6px;
    // background: $secondary2;
    padding-left: 7px;
    svg {
      margin-right: 10px;
      font-weight: 200;
      opacity: 1;
    }
    &:hover {
      background: #f0f0f0;
    }
  }
}

@media (max-width: 500px) {
  .stationIteminFolder {
    // position: absolute;
    width: auto;
    margin-bottom: 0px;
    .retroButton {
      border-radius: 6px;
      // margin-bottom: 0px;
      height: 30px;
      // width: 120px;
    }
  }
}
