@import "../../../theme.styles.scss";
.retroButton {
  font-size: 12px;
  font-weight: 600;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid $secondary2;
  color: #1b1b1b;
  height: 36px;
  cursor: pointer;
  box-shadow: inset 0px 0px 0 0 rgba(0, 0, 0, 0.3);
  transition: 0.2s ease all;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  .children {
    transition: 0.2s ease all;
    display: flex;
    justify-content: center;
    align-items: center;
    // svg {
    //   width: 15px;
    //   height: 15px;
    // }
  }
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.3);
    /*    padding-top: 1px; */
    .children {
      margin-top: 0px;
    }
    .charAtFull {
      opacity: 1;
    }
  }
  &:active {
    box-shadow: 1px 0 1px rgba(204, 204, 204, 0.5),
      2px 0 2px rgba(204, 204, 204, 0.6), 4px 0 4px rgba(204, 204, 204, 0.7);
    /*     padding-top: 2px; */
    color: $primary;
    transform: scale(0.99);
    border-right: 0px;

    .children {
      margin-top: 6px;
    }
  }
}

.small {
  height: 27px;
  font-size: 10px;
}
.gray {
  background: none;
  color: #999;
  border: none;
  box-shadow: none;
  &:active {
    transform: scale(1);
    box-shadow: none;
    .children {
      margin-top: 0px;
    }
  }
  &:hover {
    transform: scale(1);
    color: #333;
    box-shadow: none;
    .children {
      margin-top: 0px;
    }
  }
}
.create {
  width: 100%;
  background: white;
  border: 1px dotted $bodyGray;
  color: #333;
  font-weight: 100;
  padding: 27px;
}
.cancel {
  background: none;
  color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-weight: 400;
  cursor: not-allowed;
  &:hover {
    box-shadow: none;
    .children {
      margin-top: 0px;
    }
  }
}
.box {
}
.charAt {
  position: relative;
  pointer-events: none;
  font-weight: 800;
  font-size: 15px;
  align-items: center;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
  .charAtFull {
    position: absolute;
    height: 36px;
    background: rgba(255, 255, 255, 0.9);
    font-family: "Noto Sans JP", sans-serif;
    padding: 7px 10px;
    font-size: 0.8rem;
    text-transform: none;
    left: 23px;
    top: -7.3px;
    // bottom: 0px;
    justify-content: center;
    align-items: left;
    white-space: nowrap;
    border-radius: 0px 6px 6px 0px;
    border: 1px solid black;
    font-weight: 800;
    opacity: 0;
    box-shadow: 1px 1px 6px 1px #00000021;;
    // box-shadow reference for buttons
  }
}

.flat {
  box-shadow: none;
  width: 100%;
  height: 100%;
  background: $body;
  // background: #eff2f5;
  color: #333;
  justify-content: flex-start;
  border-radius: 10px;
  &:hover {
  }
  &:active {
    box-shadow: 1px 0 1px rgba(204, 204, 204, 0.5),
      2px 0 2px rgba(204, 204, 204, 0.6), 4px 0 4px rgba(204, 204, 204, 0.7);
    /* padding-top: 6px; */
    color: $primary;
    transform: scale(0.99);
  }
  svg {
    opacity: 0.8;
  }
}
.flat2 {
  box-shadow: none;
  width: 100%;
  background: $body;
  color: $secondary2;
  justify-content: flex-start;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 6px 6px 0px 0px;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.3);
    transform: scale(1.01);
  }
  &:active {
    box-shadow: rgba(0, 0, 0, 0.3);
    /* padding-top: 6px; */
    color: rgba(255, 255, 255, 0.5);
    transform: scale(0.99);
  }
  svg {
    color: $primary;
    opacity: 0.8;
    margin-right: 0px;
    width: 20px !important;
    height: 20px !important;
  }
}
.danger {
  background: $danger;
  color: white;
}
.info {
  background: none;
  border-radius: 6px;
  &:hover {
    background: #f0f0f0;
  }
}
.success {
  background: $success;
}
.warning {
  background: $warning;
}
