@import "../../../theme.styles.scss";

.habit {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 250;
  color: #333;
  align-items: flex-end;
  padding-bottom: 50px;
  overflow-y: auto;
}

.habit-backdrop {
  // background: rgba(0, 0, 0, 0.2);
  // -webkit-backdrop-filter: blur(0.2px);
  // backdrop-filter: blur(0.2px);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
}

.habit-content {
  background: white;
  -webkit-backdrop-filter: blur(0.2px);
  backdrop-filter: blur(0.2px);
  padding: 40px 40px 30px 40px;
  border-radius: 5px;
  // width: 100%;
  // height: 100%;
  position: absolute;
  box-shadow: rgb(0 0 0 / 20%) 0px 9px 30px -10px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 40%;
  max-width: 450px;
  z-index: 200;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.activitycal-container {
  display: flex;
  padding-left: 36px;
  // width: 100%;
}

.habit-form-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    width: 20px;
    height: 20px;
  }
}

.habit-form-header-link {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.habit-form {
  display: flex;
  column-gap: 12px;
  input[type="number"] {
    padding-left: 10px;
  }
}

.habit-templates {
  margin-bottom: 15px;
  overflow-y: auto;
}

.habitinfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 15px;
  cursor: pointer;
}
.habitline {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.habitsettings {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 20px;
  padding-top: 5px;
  input {
    border-radius: 5px;
    border: 1px solid $secondary2;
    // width: 100%;
    min-height: 28px;
  }
  select {
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid $secondary2;
  }
}

.habitnameinput {
  // width: 100%;
  height: 32px;
  padding: 5px 10px;
  font-size: 18px;
  font-weight: 400;
}

.habitoptions {
  display: flex;
  column-gap: 10px;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.habitbtns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  column-gap: 20px;
}

.HabitEmojiPick {
  text-align: center;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  span {
    font-size: x-large;
    background: $secondary3;
    border-radius: 100%;
    padding: 5px;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
  }
  h5 {
    font-size: 14px;
    font-weight: 500;
  }
}

.habit-templates details {
  width: 100%;
  margin: 0 auto;
  // background: #282828;
  margin-bottom: 0.5rem;
  box-shadow: 0 0.1rem 0.8rem -0.5rem rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  overflow: hidden;
  .habitline {
    padding-left: 2rem;
  }
}

.habit-templates summary {
  padding: 0.5rem 1rem;
  display: flex;
  width: 100%;
  // background: #333;
  padding-left: 2.2rem;
  position: relative;
  cursor: pointer;
}

.habit-templates summary:before {
  content: "\203A";
  align-items: center;
  // color: black;
  // border-width: 0.4rem;
  // border-style: solid;
  // border-color: transparent transparent transparent black;
  position: absolute;
  // top: 1.3rem;
  left: 1rem;
  transform: rotate(0);
  transform-origin: 0.2rem 50%;
  transition: 0.5s transform ease;
}

/* THE MAGIC 🧙‍♀️ */
.habit-templates details[open] > summary:before {
  transform: rotate(90deg);
}

.habit-templates details summary::-webkit-details-marker {
  display: none;
}

.habit-templates details > ul {
  padding-bottom: 1rem;
  margin-bottom: 0;
}

.habit__exit__button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  margin-top: 4px;
  color: $secondary2;
  align-self: end;
  stroke-width: 5px;
  transform: rotate(-45deg);
  svg {
    width: 36px;
    height: 36px;
  }
  :hover {
    color: $primary;
  }
}

.habit-history summary {
  padding: 0.5rem 1rem;
  display: flex;
  width: 100%;
  // background: #333;
  padding-left: 2.2rem;
  position: relative;
  cursor: pointer;
}

.habit-history summary:before {
  content: "\203A";
  align-items: center;
  // color: black;
  // border-width: 0.4rem;
  // border-style: solid;
  // border-color: transparent transparent transparent black;
  position: absolute;
  font-size: 1em;
  // top: 1.3rem;
  left: 1rem;
  transform: rotate(0);
  transform-origin: 0.2rem 50%;
  transition: 0.5s transform ease;
}

/* THE MAGIC 🧙‍♀️ */
.habit-history details[open] > summary:before {
  transform: rotate(90deg);
}

.habit-history details summary::-webkit-details-marker {
  display: none;
}

@media (max-width: 500px) {
  .habit {
    padding-bottom: 42px;
  }
  .habit-content {
    height: calc(100vh - 120px);
    width: 100vw;
    padding: 30px 20px;
    padding-top: 50px;
  }
  .habit-form {
    padding: 0px;
    column-gap: 10px;
  }
}
