@import "../../../../theme.styles.scss";
.taskList__logic {
  position: relative;
  &:hover {
    .tl__drag {
      opacity: 0.2;
    }
  }
}
.taskList {
  position: relative;
  display: flex;
  // width: inherit;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $body;
  padding: 5px 10px;
  background: white;
  border-radius: 8px;
  margin-bottom: 5px;

  &:last-child {
    border: none;
  }
  &:hover {
    .tl__drag {
      opacity: 0.2;
      &:hover {
        opacity: 0.5;
      }
    }
  }

  .tl__task {
    padding-right: 20px;
    height: 40px;
    align-items: center;
    display: flex;
    p {
      padding-left: 10px;
      margin-left: 5px;
    }
  }
  .tl__modules {
    display: flex;
  }
  .tl__clickable {
    position: absolute;
    width: 83%;
    height: 100%;
    width: 83%;
    left: 36px;
    // cursor: pointer;
  }
  .bl__content {
    width: 70%;
    min-width: 500px;
    max-width: 1000px;
    .retroBox {
      width: 100%;
    }
  }
}
.task__done {
  .tl__task {
    p {
      opacity: 0.6;
      text-decoration: line-through;
    }
  }
  .tl__modules {
    opacity: 0.6;
    filter: grayscale(100%);
    pointer-events: none;
  }
}
.tl__drag {
  position: absolute;
  left: -24px;
  opacity: 0;
  top: 13px;
  transition: 0.2s ease;
}
.task__listcard {
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  i {
    margin-right: 10px;
    cursor: pointer;
    color: $primary;
    margin-top: -1px;
  }
  svg {
    width: 17px;
    height: 17px;
    color: $primary;
    transition: 0.2s ease;
    &:hover {
      color: black;
      transform: scale(1.1);
    }
  }
  p {
    text-decoration: none;
    color: $primary;
    font-weight: 400;
  }
}

.task__listcard-done {
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  i {
    margin-right: 10px;
    cursor: pointer;
    // color: $secondary;
    margin-top: -1px;
  }
  svg {
    // color: $secondary;
    transition: 0.2s ease;
    &:hover {
      color: black;
      transform: scale(1.1);
    }
  }
  p {
    text-decoration: line-through;
    // color: $secondary;
  }
}

.sl__subtasks {
  .subtasks__label-connect {
    display: none;
  }
}

@media (max-width: 500px) {
  .tl__modules {
    display: none !important;
  }
  .task__listcard {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
