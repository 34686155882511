@import "../../theme.styles.scss";

.chess__main {
  display: flex;
  justify-content: flex-start;
  // width: 100vw;
  height: calc(100vh - 80px);
  padding-bottom: 20px;
  transition: all 0.2 ease;
  padding-right: 50px;
  // column-gap: 90px;
}

.chess__options {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: center;
  // width: 100%;
  height: 650px;
  padding: 20px 0px;
  cursor: pointer;
  transition: all 0.2 ease;
  // background: $secondary3;
  // border-left: 1px solid $secondary2;
  // border-bottom: 1px solid $secondary2;
  // border-radius: 0 0 0 20px;
  box-sizing: border-box;
  padding-top: 30px;
  &:hover {
    color: black;
  }
}
.chess__option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100px;
  height: 170px;
  border-top: 1px solid $secondary2;
  border-right: 1px solid $secondary2;
  border-bottom: 1px solid $secondary2;
  border-left: 1px solid $secondary3;
  border-radius: 0px 20px 20px 0px;
  cursor: pointer;
  color: $secondary2;
  transition: all 0.5s ease;
  background: $secondary3;
  h4 {
    margin-top: 20px;
    margin-bottom: 6px;
  }
  p {
    display: none;
  }
  &:hover {
    background: $body;
    color: black;
    transition: all 0.5s ease;
    width: 110px;
    box-shadow: 4px 2px 6px 0px #00000021;
    p {
      display: inline;
      // transition: all 2s ease;
    }
  }
}
.chess__option-active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100px;
  height: 170px;
  border-top: 1px solid $secondary2;
  border-right: 1px solid $secondary2;
  border-bottom: 1px solid $secondary2;
  border-left: 1px solid $body;
  border-radius: 0px 20px 20px 0px;
  cursor: pointer;
  color: $primary;
  transition: all 0.5s ease;
  background: white;
  box-shadow: 4px 2px 6px 0px #00000021;
  h4 {
    margin-top: 20px;
    margin-bottom: 6px;
  }
  p {
    display: none;
  }
  &:hover {
    background: $body;
    color: black;
    transition: all 0.5s ease;
    width: 110px;
    p {
      display: inline;
    }
  }
}

.chess__table-decoration {
  position: absolute;
  bottom: 30px;
  right: 5%;
  width: 200px;
  height: 200px;
  opacity: 0.5;
  background-repeat: no-repeat;
  transform: rotate(180deg);
  // background-image: url(../../../public/media/coffeering.svg);
  z-index: -1;
}

.othergames-container {
  display: flex;
  width: 100%;
}
