@import "../../theme.styles.scss";
.spaceData {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  padding-top: 10px;
}
.sd__data,
.sd__something {
  flex: 1;
}

.sd__data {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  h4 {
    font-size: 14px;
    // margin-top: -10px;
  }
}
.sd__btn {
  display: flex;
  align-items: center;
  // background: $body;
  // border-radius: 10px;
  padding: 5px 10px;
  // margin-bottom: 5px;
  transition: 0.2s;
  height: 36px;
  &:hover {
    .sd__favorite {
      svg {
        opacity: 1;
      }
    }
  }
  button {
    width: 40px;
    height: 40px;
    // font-weight: 800;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.sd__btnClick {
  display: flex;
  align-items: center;
  transition: 0.2s;
  &:hover {
    transform: scale(1.03);
    h4 {
      text-decoration-line: line-through;
    }
  }
  &:active {
    transform: scale(0.97);
  }
  button {
    font-size: 18px;
    text-transform: uppercase;
  }
}
.sd__favorite {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  svg {
    transition: 0.2s ease all;
    color: #dadada;
    cursor: pointer;
    opacity: 0;
    &:hover {
      transform: scale(1.2);
      color: $warning;
    }
    &:active {
      transform: scale(0.95);
    }
  }
}
.sd__favoriteActive {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  svg {
    color: $warning !important;
  }
}

.sd__createNewSpace {
  margin-top: 10px;
  .retroButton.create {
    height: 36px;
    justify-content: start;
    padding-left: 18px;
  }
  button {
    // width: 75%;
    background: transparent;
    // margin-left: 20px;
    color: $primary;
    opacity: 0.8;
    height: 36px;
    border-width: 0px;
    transition: all 0.2s ease;
    &:hover {
      color: black;
      opacity: 1;
      transform: scale(1.05);
      background: none;
    }
  }
  svg {
    color: $bodyGray;
    margin-right: 10px;
  }
}

// .stations__createStation {
//   button {
//     // width: 75%;
//     background: transparent;
//     // margin-left: 20px;
//     color: $primary;
//     opacity: 0.8;
//     height: 36px;
//     border-width: 0px;
//     transition: all 0.2s ease;
//     &:hover {
//       color: black;
//       opacity: 1;
//       transform: scale(1.05);
//       background: none;
//     }
//   }
// }
