@import "../../../theme.styles.scss";
.person__notification {
  display: flex;
  flex-direction: row;
  // align-items: center;
  z-index: 90;
  column-gap: 8px;
  font-size: 12px;
  width: 100%;
  // box-shadow: 2px 2px 8px 1px #00000021;
  svg {
    margin-top: 5px;
  }
}

.person__notification-msg {
  display: flex;
  flex-direction: column;
  column-gap: 10px;
  background: white;
  border: 1px solid $secondary2;
  border-radius: 6px;
  padding: 5px 10px;
  width: 100%;
  line-height: 1.6em;
  b {
    font-weight: 600;
  }
}
.person__notification-item {
  display: flex;
  flex-direction: column;
  column-gap: 10px;
  background: black;
}

@media (max-width: 500px) {
  .person__notification {
    svg {
      width: 18px;
      height: 18px;
    }
  }
  .person__notification-msg {
    p {
      font-size: 14px;
    }
  }
}
