@import "../../theme.styles.scss";
.largeTask {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  .bl__content {
    width: 25% !important;
  }
  width: 100%;
  .loadModule {
    border-bottom: 1px solid $body;
    padding: 10px 5px;
    &:last-child {
      border-bottom: none;
    }
  }
  .retroBox {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px !important;
  }
}

.lt__taskStuff {
  background: $body;
  padding: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid $bodyGray;
  form {
    position: relative;
    width: 100%;
  }
  input {
    border: none;
    background: transparent;
    font-size: 18px;
    border: 1px dotted $body;
    padding: 5px 10px;
    margin-left: -10px;
    border-radius: 8px;
    width: 100%;
    &:hover {
      border: 1px dotted gray;
    }
    &:focus {
      border: 1px dotted gray;
    }
    &:active {
      border: 1px dotted gray;
    }
  }
  ::placeholder {
    color: #333;
    font-weight: 500;
  }
}

.lt__modules {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-gap: 10px;
}

.lt__writeUpdate {
  background: $body;
}
.lt__writeUpdate-send {
  display: flex;
  padding: 20px;
  border-top: 1px solid rgb(224, 224, 224);
}
.lt__writeUpdate-message {
  position: relative;
  width: 100%;
  margin-left: 10px;
  border-radius: 10px;
  textarea {
    padding: 10px;
    width: 100%;
    max-width: 100% !important;
    height: 100px;
    border: 1px solid rgb(228, 228, 228);
    border-radius: 10px;
    resize: none;
    transition: 0.2s ease;
    &:focus {
      outline: none;
      height: 150px;
      border: 1px solid rgb(179, 179, 179);
    }
  }
  .lt__writeUpdate-btn {
    position: absolute;
    right: 10px;
    bottom: 20px;
  }
}
.lt__writeUpdate-msgs {
  margin: 20px 0;
}

.lt__content {
  display: flex;
  z-index: 400;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

@media (max-width: 500px) {
  .largeTask {
    width: 100%;
  }
}
