@import "../../theme.styles.scss";

.ganttView {
  display: flex;
  padding: 0px 20px 0px 0px;
}

.ganttContainer {
  overflow-x: auto;
  background: white;
  padding-top: 10px;
  box-shadow: 2px 2px 8px 1px #00000021;
  border-radius: 0 8px 8px 8px;
  width: 100%;
}

.gantt__container {
  // padding: 10px 5px;
  height: calc(100vh - 140px);
  // height: 100%;
}
.noSwimlane {
  .gantt-swimlanes-container {
    display: none;
  }
}

.noWeekends {
  .weekend-highlight {
    display: none;
  }
}

.incoming {
  padding: 100px;
}

.gantt__upperrow {
  display: flex;
  column-gap: 10px;
  align-items: center;
  justify-content: space-between;
  h4 {
    color: $primary;
  }
  a {
    color: black;
    font-weight: 600;
  }
}

.arrows {
  border-radius: 10px;
}

.gantt__switch {
  display: flex;
  column-gap: 10px;
  align-items: center;
  span {
    color: $primary;
    font-size: 15px;
  }
}

.gantt__buttons {
  display: flex;
  width: 100%;
  column-gap: 20px;
  justify-content: flex-end;
  padding-right: 10px;
}

.gantt__buttons-dock {
  display: flex;
  width: 100%;
  column-gap: 15px;
  justify-content: flex-end;
}

.gantt__button {
  background: transparent;
  color: #000000;
  border: 1px solid $secondary2;
  border-bottom: none;
  font-size: 14px;
  text-align: center;
  padding: 6px 10px;
  border-radius: 4px 4px 0px 0px;
  cursor: pointer;
  font-weight: 400;
  font-family: "Noto Sans JP", sans-serif;
  &:hover {
    transform: scale(1.05);
    transition: all 0.1s ease;
    background-color: $secondary3;
  }
}
.gantt__button-active {
  background: white;
  color: #000000;
  border: 1px solid $secondary2;
  border-bottom: none;
  font-size: 14px;
  text-align: center;
  padding: 6px 10px;
  border-radius: 6px 6px 0px 0px;
  font-weight: 600;
  font-family: "Noto Sans JP", sans-serif;
  // text-decoration: 2px underline black;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
    transition: all 0.1s ease;
    background-color: $secondary3;
  }
}

.gantt__buttongroup {
  button {
    border-radius: 0px;
  }
  button:first-child {
    border-radius: 4px 0px 0px 0px;
    // border-right: none;
  }
  button:last-child {
    border-radius: 0px 4px 0px 0px;
    // border-left: none;
  }
}

.gantt__buttongroup-settings {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
  margin-top: 30px;
  button {
    border-radius: 6px;
    border: 1px solid $secondary2;
    padding: 6px;
    display: flex;
    column-gap: 5px;
    white-space: nowrap;
    width: 100%;
  }
}

.gantt__settings__win {
  .retroBox {
    padding: 10px !important;
    border-radius: 12px 0px 12px 12px;
  }
  .bl__content {
    right: 20px;
    top: 95px;
    width: 150px;
    position: absolute;
  }
  .bl__layer {
    background: transparent;
    backdrop-filter: none;
  }
}

.Switch_Toggle {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 30px;
  margin-right: 5px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $secondary2;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  &:hover {
    background-color: $secondary3;
  }
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $primary;
  &:hover {
    background-color: black;
  }
}

// input:focus + .slider {
//   box-shadow: 0 0 1px black;
// }

input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 5px;
}

.slider.round:before {
  border-radius: 18%;
}

.gantt {
  .today-highlight {
    fill: $primary;
    opacity: 0.1;
  }
}

.task g rect {
  // fill: #d32f2f !important;
  display: inline;
  min-width: 20px;
  overflow: visible;
}
.task .bar-progress {
  fill: #2b2e33;
  display: inline;
  min-width: 20px;
  overflow: visible;
}
// .task .bar-progress {
//   fill: #d32f2f !important;
// }

.milestone g rect {
  fill: black !important;
  position: relative;
  width: 20px;
  overflow: hidden;
  background: #522d5b;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
.popup-wrapper {
  box-shadow: 2px 2px 8px 1px #00000021;

  .title {
    color: black;
    border-bottom: 2px solid $primary;
  }
  .subtitle {
    color: $primary;
  }
}

.gantt-container .popup-wrapper {
  border-radius: 4px;
  background: white;
}

.gantt-container .popup-wrapper .title {
  border-bottom: 2px solid $primary;
}

.gantt-container .popup-wrapper .subtitle {
  color: $primary;
}
.bar-milestone {
  &:hover {
    .bar-progress {
      fill: $primary;
    }
  }
}

.bar-milestone .bar-progress {
  fill: $primary;
  display: inline;
  min-width: 20px;
  overflow: visible;
  &:hover {
    fill: $primary;
  }
}
.gantt .bar-wrapper.active .bar-progress {
  fill: #2d8fff;
  display: inline;
  min-width: 20px;
  overflow: visible;
}
.gantt {
  fill: #2d8fff;
  display: inline;
  min-width: 20px;
  overflow: visible;
  .bar-wrapper {
    &:hover {
      .bar-progress {
        fill: black;
      }
    }
  }
}

@media (max-width: 500px) {
  .ganttView {
    padding: unset;
    .gantt-swimlanes-container {
      display: none;
    }
  }
  // .ganttView {
  //   padding: 20px 5px;
  //   overflow-x: auto;
  //   height: unset;
  //   .gantt-swimlanes-container {
  //     display: none;
  //   }
  //   .svg {
  //     height: calc(100vh - 350px);
  //   }
  // }
  .gantt__buttons {
    column-gap: 10px;
    padding: 0px 8px;
    width: 100%;
    justify-content: space-between;
  }
  .gantt__buttongroup-settings {
    margin-top: 10px;
  }
}
