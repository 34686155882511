@import "../../theme.styles.scss";
.stationItem {
  margin-bottom: 5px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 5px;
  a {
    text-align: start;
  }
  .EmojiIcon {
    width: 2em;
    margin-right: 0.2em;
    margin-left: 0;
  }
  // &:last-child {
  //   margin-bottom: 0;
  // }
  .retroButton {
    justify-content: flex-start;
    box-shadow: none;
    border-radius: 6px 0px 0px 6px;
    // background: $secondary2;
    padding-left: 7px;
    padding-right: 10px;
    svg {
      margin-right: 10px;
      font-weight: 200;
      opacity: 1;
    }
    &:hover {
      background: white;
    }
  }
}

.stationItem_addtows {
  background: white;
  border: 1px solid $secondary2;
  border-top: none;
  display: flex;
  position: absolute;
  right: 0;
  padding: 2px 5px;
  align-items: flex-end;
  justify-content: flex-end;
}

.stationItem_sharedIcon {
color: $secondary2;
}

@media (max-width: 500px) {
  .stationItem {
    .retroButton {
      border-radius: 6px;
      margin-bottom: none;
      width: auto;
      padding-left: 0px;
    }
  }
}
