@import "../../theme.styles.scss";
.filter {
  // margin-left: 60px;
  display: flex;
  align-items: flex-start;
}
.filter__group-top {
  display: flex;
  align-items: center;
}

.filter__priority-fire {
  // width: 120px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.filter__time {
  display: flex;
  align-items: flex-end;
}
.filter__byPerson {
  // margin-top: 16px;
  display: flex;
  // align-self: end;
  // align-items: flex-end;
}
.filter__text {
  position: relative;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
  svg {
    color: $secondary2;
  }
}

.filter__types {
  display: flex;
  flex-direction: column;
  max-height: 40px;
  column-gap: 22px;
  p {
    font-size: 12px;
  }
}

@media (max-width: 500px) {
  .filter {
    // display: none;
    position: absolute;
    width: 100vw;
    height: 50px;
    left: 0px;
    padding: 0px;
    flex-wrap: 70%;
    background: $secondary3;
    z-index: 50;
    // transform: translateY(-50px);
  }
  .filter__types {
    // flex-direction: row;
  }
  // .filter__group-top {
  //   flex-direction: column;
  // }
  .filter__byPerson {
    padding-left: 0px;
  }
  .filter__priority-fire {
    margin-top: -15px;
    align-self: flex-end;
  }
  .filter__text {
    display: none;
  }
  .filter__time {
    align-self: flex-start;
    width: 145px;
  }
}
