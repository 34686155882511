.addSubtask {
  margin-left: 9.5px;
}
.subtasks__input {
  input {
    background: none;
  }
  ::placeholder {
    color: lightgray
  }
}
