@import "../../../theme.styles.scss";
.sec__resources {
  display: flex;
  padding: 60px 10% 90px 10%;
  flex-direction: column;
  row-gap: 20px;
  align-items: flex-start;
  background: white;
  p {
    font-size: 18px;
    line-height: 30px;
  }
  h2 {
    font-size: 32px;
    line-height: 50px;
    font-weight: 600;
  }
  h3 {
    font-size: 28px;
    line-height: 40px;
    font-weight: 400;
  }
  h4 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
  }
}

.sec__resources-heading {
  display: flex;
  flex-direction: column;
  text-align: start;
  h2 {
    font-size: 24px;
    line-height: 40px;
    font-weight: 600;
  }
  h3 {
    font-size: 20px;
    line-height: 36px;
    font-weight: 400;
  }
}

.sec__faq {
  display: grid;
  width: 100%;
  // grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  // box-shadow: $boxShadow-small;

  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    // grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    // grid-gap: 80px;
    grid-gap: 30px;
  }
}

@media (max-width: 500px) {
  .sec__resources {
    flex-direction: column;
    padding: 30px 20px 50px 20px;
  }
}

@media (max-width: 500px) {
  .sec__resources {
    flex-direction: column;
  }
}
