@import "../../theme.styles.scss";

.chbox-container {
  cursor: pointer;
}

.chbox-container input {
  display: none;
}

.chbox-container svg {
  overflow: visible;
}

.chbox_path {
  fill: none;
  stroke: $primary;
  stroke-width: 6;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease;
  stroke-dasharray: 241 9999999;
  stroke-dashoffset: 0;
}
.chbox_box {
  fill: none;
  stroke: $primary;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease;
  stroke-dasharray: 141 208;
  stroke-dashoffset: 2;
}

.chbox-container input:checked ~ svg .chbox_path {
  stroke-dasharray: 70.5096664428711 9999999;
  stroke-dashoffset: -262.2723388671875;
}
