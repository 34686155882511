@import "../../../theme.styles.scss";
.icon {
  border-color: white;
  border-right: 2px;
  .icon__button-active {
    border-width: 5px;
    &:active {
      border-radius: 10px 0px 6px 0px;
      // background: white;
    }
    .icon__button-null {
      border-radius: 10px 0px 6px 0px;
    }
  }
}

