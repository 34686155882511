.meet_video {
  background-color: #353b48;
  height: 100%;
  width: 100%;
  position: relative;
  object-fit: cover;
  border-radius: 5px;
}

.meet_participant {
  background-color: #353b48;
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 5px;
  min-height: 170px;
}

.meet_participantFS {
  background-color: #353b48;
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 5px;
  min-height: 300px;
}

.meet_muted {
  color: #fff;
  right: 10px;
  position: absolute;
  top: 10px;
  background: #353b48;
  border-radius: 50%;
  height: 22px;
  width: 22px !important;
}

.meet_hide {
  display: none;
}

.meet_avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  font-size: 50px;
  font-family: roboto, arial;
  color: white;
  text-align: center;
  background: orange;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.meet_name {
  color: white;
  bottom: 10px;
  position: absolute;
  left: 20px;
}

@media (max-width: 800px) {
  .meet_participant {
    min-height: 0px;
  }
  .meet_avatar {
    width: 80px;
    height: 80px;
    font-size: 50px;
  }
}
