@import "../../theme.styles.scss";

.TaskDetail {
  position: relative;
  min-width: 360px;
  // height: 25%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: end;
  top: 0;
  bottom: 0;
  left: 0;
  right: 5%;
  border-radius: 4px;
  z-index: 100;
  overflow-y: auto;
  box-shadow: 0px 1px 1px rgba(54, 54, 54, 0.1),
    0px 2px 2px rgba(54, 54, 54, 0.2), 0px 2px 3px rgba(54, 54, 54, 0.3);

  // box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 30px -10px;

  // background: $secondary3;
  // padding: 20px;
}

.TaskModal label {
  display: block;
}

// .TaskModal,
// .overlay {
//   width: 100vw;
//   height: 100vh;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   position: fixed;
// }
.TaskModal {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 80;
}

.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 90;
}

// .overlay {
//   background: rgba(49, 49, 49, 0.1);
// }

.modal-content {
  position: relative;
  width: 360px;
  // transform: translate(-50%, -50%);
  display: flex;
  max-height: calc(100vh - 111px);
  max-height: calc(100vh);
  // overflow-y: auto;
  // overflow-x: hidden;
  flex-direction: column;
  justify-content: space-between;
  // align-items: center;
  row-gap: 30px;
  background: white;
  padding: 8px 16px;
  border-radius: 4px;
  box-shadow: 4px 4px 12px 2px #00000021;
  z-index: 100;
}
.modal-content-full {
  position: relative;
  width: 360px;
  // transform: translate(-50%, -50%);
  display: flex;
  max-height: 100vh;
  // overflow-y: auto;
  // overflow-x: hidden;
  flex-direction: column;
  justify-content: space-between;
  // align-items: center;
  // row-gap: 10px;
  background: white;
  padding: 8px 16px 16px 16px;
  border-radius: 4px;
  box-shadow: 4px 4px 12px 2px #00000021;
  z-index: 100;
}

.TaskModal__Main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 0.6em;
}

.modal-content-sidemode {
  position: relative;
  // top: 34%;
  // right: 200px;
  // width: 400px;
  // transform: translate(-50%, -50%);
  overflow-y: visible;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1em;
  background: white;
  padding: 8px 16px;
  border-radius: 4px;
  input {
    // width: fit-content;
    border: 0.5px solid $secondary2;
    border-radius: 4px;
    padding: 0.4rem 0.7rem;
  }
}

// textarea {
//   justify-content: center;
// }

.modal_info-block {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 5px;
}

.modal_task_name {
  display: flex;
  width: 100%;
  overflow: hidden;
  textarea {
    border: 2px solid transparent;
    outline: none;
    padding: 7px 0px 0px 0px;
    color: black;
    height: 56px;
    // justify-content: center;
    // vertical-align: middle;
    resize: none;
    font-size: 17px;
    border-radius: 5px;
    transition: all 0.2s;
    &:focus {
      border: 2px solid transparent;
    }
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

// textarea:focus,
// input:focus {
//   outline: $primary solid 0.5px;
// }

.exit__button {
  background: none;
  margin-top: 4px;
  color: $secondary2;
  align-self: end;
  stroke-width: 5px;
  transform: rotate(-45deg);
  margin-right: -4px;
  svg {
    width: 40px;
    height: 40px;
  }
  :hover {
    color: $primary;
  }
}

.task__modal-notdone {
  display: flex;
  margin-top: 15px;
  i {
    margin-right: 5px;
    cursor: pointer;
    color: $primary;
  }
  svg {
    color: $primary;
    transition: 0.2s ease;
    &:hover {
      color: black;
      transform: scale(1.1);
    }
  }
  p {
    text-decoration: none;
    color: $primary;
  }
}

.task__modal-done {
  display: flex;
  margin-top: 15px;
  i {
    margin-right: 5px;
    cursor: pointer;
    color: $secondary;
  }
  svg {
    color: $secondary2;
    transition: 0.2s ease;
    &:hover {
      color: black;
      transform: scale(1.1);
    }
  }
  p {
    text-decoration: line-through;
    color: $secondary2;
  }
}

.tm__extra-column {
  position: relative;
  // left: calc(100% + 10px);
  top: 0;
  width: 100%;
  height: 100%;
  // height: calc(192% + 10px);
  display: flex;
  flex-direction: column;
  row-gap: 0.5em;
  justify-content: space-between;
  align-items: center;
  // text-align: center;
  // padding-top: 10px;
  // border-top: 1px solid $secondary3;
  // border-radius: 4px;
  // border-top: 1px solid $secondary2;
  // transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.more_icon {
  display: flex;
  flex-direction: row;
  background: none;
  color: $secondary2;
  align-self: end;
  align-items: center;
  height: 100%;
  // bottom: 8px;
  // position: absolute;
  p {
    padding-bottom: 6px;
    color: $secondary2;
  }
  :hover {
    color: $primary;
  }
  &:hover {
    .tooltip {
      opacity: 1;
      // width: 90px;
      right: 2px;
      top: 330px;
    }
  }
  font-size: 12px;
  // transition: all 3s ease;
}

.repeat_cancel-btn {
  display: flex;
  align-items: center;
  font-weight: 550;
}

.repeat_icon {
  background: none;
  color: $secondary2;
  padding-top: 4px;
  justify-self: flex-end;
  // align-self: start;
  // bottom: 8px;
  // position: absolute;
  :hover {
    color: $primary;
  }
  &:hover {
    .tooltip {
      opacity: 1;
      // width: 90px;
      right: -12px;
      top: -20px;
    }
  }
}

// .datepicker {
//   max-width: 100px;
// }

.save__button {
  // margin-top: 0.8em;
  // margin-bottom: 3em;
  display: flex;
  direction: column;
  column-gap: 2em;
}

// body.active-modal {
//   overflow-y: hidden;
// }

.Buttons__Row {
  display: flex;
  justify-content: space-between;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.TaskModal__Row {
  display: flex;
  direction: column;
  // border: 1px solid gray;
  border-radius: 3px;
  margin-bottom: 0px;
  position: relative;
  column-gap: 1em;
  align-items: center;
  justify-content: space-between;
}
.TaskModal__Row4 {
  display: flex;
  // direction: column;
  // border: 1px solid gray;
  border-radius: 3px;
  margin-bottom: 0px;
  position: relative;
  column-gap: 1em;
  align-items: center;
  justify-content: space-between;
}
.tm_breadcrumb {
  display: flex;
  column-gap: 1em;
}
.TaskModal__ModulesRow {
  display: flex;
  flex-direction: column;
  // border: 1px solid gray;
  border-radius: 3px;
  margin-bottom: 0px;
  position: relative;
  row-gap: 1em;
  align-items: flex-end;
  width: 100%;
  justify-items: center;
  justify-content: space-between;
  label {
    font-size: 9px;
  }
}
.TaskModal__Row1 {
  display: flex;
  // direction: row;
  // border: 1px solid gray;
  // padding: 0.2em;
  border-radius: 3px;
  margin-bottom: 0px;
  position: relative;
  // column-gap: 1em;
  align-items: center;
  justify-content: space-between;
  h4 {
    color: $secondary2;
    font-size: 16px;
    padding-top: 4px;
  }
}
.TaskModal__Row2 {
  display: flex;
  direction: row;
  // border: 1px solid gray;
  // padding: 0.2em;
  border-radius: 3px;
  margin-bottom: 0px;
  position: relative;
  // column-gap: 1em;
  // align-items: center;
}
.TaskModal__Row3 {
  display: flex;
  direction: row;
  // border: 1px solid gray;
  padding: 0.2em;
  border-radius: 3px;
  margin-bottom: 0px;
  position: relative;
  column-gap: 0.5em;
  // align-items: center;
  justify-content: flex-end;
  align-items: center;
  label {
    font-size: 14px;
  }
}
.tm__allday {
  display: flex;
  column-gap: 3px;
  input {
    accent-color: black;
  }
}
.tm__alldaytext {
  color: $secondary2;
}
.tm__milestone {
  display: flex;
  column-gap: 3px;
  input {
    accent-color: black;
  }
}
.tm__milestonetext {
  color: $secondary2;
}
.tm__dependency {
  display: flex;
  column-gap: 3px;
}
.tm__dependencytext {
  color: $secondary2;
}

.twocolumns {
  display: flex;
  flex-direction: row;
  width: 160px;
  column-gap: 0.5em;
  input {
    width: 160px;
    font-size: 12px;
    border: 0.5px solid $secondary2;
    border-radius: 4px;
    padding: 0.4rem 0.7rem;
  }
  label {
    font-size: 10px;
    color: $primary;
    padding: 2px 12px;
  }
}

.twocolumnsnarrow {
  display: flex;
  // flex-direction: column;
  width: 160px;
  column-gap: 0.5em;
  input {
    width: 160px;
    font-size: 12px;
    border: 0.5px solid $secondary2;
    border-radius: 4px;
    padding: 0.4rem 0.7rem;
  }
  label {
    font-size: 10px;
    color: $primary;
    padding: 2px 12px;
  }
}

.inverval_number {
  width: 4rem;
}

.tm_select {
  // margin-bottom: 1em;
  // padding: .25em;
  border: 0;
  border-bottom: 1px solid $secondary2;
  // font-weight: bold;
  // letter-spacing: .15em;
  font-weight: 400;
  border-radius: 0;
  option {
    margin-bottom: 10px;
    &:hover {
      background-color: white;
    }
  }
  &:focus,
  &:active {
    outline: 0;
    border-bottom-color: black;
  }
}

.tm_option {
  display: flex;
  column-gap: 10px;
font-weight: 400;
  // text-align: justify;
  // align-items: center;
  // justify-content: space-around;
}

// .datepicker {
//   position: relative;
//   right: 10px;
// }

.react-datepicker__day--selected {
  background: $secondary;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: $secondary;
}
.react-datepicker__close-icon::after {
  background-color: $secondary;
}
.react-datepicker__time-container--with-today-button {
  right: -85px;
}
// .react-datepicker__time-list {
//   padding: 0 !important;
// }
.react-datepicker {
  position: relative;
  // translate: -25%;
}

// .tm__Description-Subtask {
//   display: flex;
//   flex-direction: column;
//   justify-content: space-around;

//   row-gap: 10px;
//   button {
//     align-items: center;
//     padding: 0.4em 0.8em;
//     font-size: 9px;
//     // font-weight: 500;
//     // color: white;
//     // background: $secondary;
//     border-bottom: 2px solid $secondary2;
//     border-radius: 7px;
//     svg {
//       margin-right: 5px;
//     }
//   }
// }
.tm__subtasks {
  display: flex;
}

.tm__writeUpdate {
  display: flex;
  flex-direction: column;
  // max-height: calc(100vh - 323px);
  width: 100%;
  background: $secondary3;
  // padding: 20px 20px;
  // justify-content: center;
}
.tm__writeUpdate-send {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px 20px;
  column-gap: 10px;
  border-top: 1px solid rgb(224, 224, 224);
}
.tm__writeUpdate-message {
  position: relative;
  width: 100%;
  padding: none;

  textarea {
    padding: 10px;
    // width: 100%;
    max-width: 100% !important;
    height: 60px;
    border-top: 1px solid $secondary2;
    border-left: 1px solid $secondary2;
    border-right: 1px solid $secondary2;
    border-bottom: none;
    border-radius: 8px 8px 0px 0px;
    resize: none;
    transition: 0.2s ease;
    &:focus {
      outline: none;
      // height: 90px;
      // border-top: none;
    }
  }
  .tm__writeUpdate-btn {
    &:hover {
      a {
        font-weight: bold;
        color: black;
      }
    }
    .retroButton {
      border-radius: 0 0 10px 0;
      border: none;
      width: 90px;
      height: 32px;
      border-top: 1px solid $secondary2;
      &:hover {
        color: black;
        font-weight: bold;
        border-top: none;
        box-shadow: 3px 3px 4px rgba(204, 204, 204, 0.8);
      }
    }
  }
  form {
    height: 60px;
    &:focus {
      height: 90px;
    }
  }
}

.tm__messagewithReaction {
  display: flex;
  flex-direction: column;
}

.tm__messageLine {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  // padding: 5px 20px;
  .message__msg {
    background: $secondary3;
  }
  &:hover {
    .ch__EmojiPickIcon {
      svg {
        opacity: 0.6;
        &:hover {
          opacity: 1;
        }
      }
    }
    .ch__MessageDelete {
      svg {
        opacity: 0.6;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
.tm__messageLine-Self {
  display: flex;
  flex-direction: row-reverse;
  column-gap: 10px;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: flex-end;
  // padding-top: 5px;
  // padding-bottom: 5px;
  // padding-left: 20px;
  // padding-right: 16px;
  .message__msg {
    background: $secondary3;
  }
  &:hover {
    .ch__EmojiPickIcon {
      svg {
        opacity: 0.6;
        &:hover {
          opacity: 1;
        }
      }
    }
    .ch__MessageDelete {
      svg {
        opacity: 0.6;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.tm__PickerOn {
  position: relative;
  top: -380px;
  right: 47px;
  max-width: 100px;
  z-index: 150;
}

.tm__ReactionPickerOn {
  position: absolute;
  top: -100px;
  right: 250px;
  max-width: 100px;
  z-index: 150;
}

.tm__messageReaction {
  position: relative;
  left: 45px;
  align-self: start;
  bottom: 12px;
  font-size: 18px;
  margin-top: 5px;
}
.tm__messageReaction-Self {
  position: relative;
  left: 45px;
  align-self: flex-start;
  bottom: 12px;
  font-size: 18px;
  margin-top: 5px;
}

.tm__writeUpdate-buttongroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 10px 10px;
  border-left: 1px solid $secondary2;
  border-right: 1px solid $secondary2;
  border-bottom: 1px solid $secondary2;
}
.tm__writeUpdate-buttongroup-sub {
  display: flex;
  justify-content: space-between;
  background: white;
  width: 100%;
  height: 32px;
  align-items: center;
  border-radius: 0 0px 0 10px;
  padding-left: 5px;
  padding-right: 6em;
  border-top: 1px solid $secondary2;
  border-right: 1px solid $secondary2;
  .tm__EmojiPick {
    margin-top: 0px;
  }
}

.tm__writeUpdate-msgs {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
}

.tm__description-priority {
  display: flex;
  column-gap: 6px;
  // &:hover {
  //   .tooltip {
  //     opacity: 1;
  //     // width: 90px;
  //     // right: -12px;
  //     // top: 60px;
  //   }
  // }
}

.tm__description-box {
  position: relative;
  width: 100%;
  border-radius: 6px;
  overflow-y: auto;
  ::placeholder {
    // color: $secondary2;
    font-size: 12px;
    opacity: 0.8;
  }
  textarea {
    padding-left: 4px;
    width: 100%;
    max-width: 100% !important;
    height: 36px;
    // border: 1px solid rgb(228, 228, 228);
    border: none;
    border-radius: 6px;
    resize: none;
    transition: 0.2s ease;

    &:focus {
      outline: none;
      height: 100px;
      // border: 1px solid rgb(179, 179, 179);
      border: none;
    }
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.TaskModal__module-priority {
  display: flex;
  align-items: flex-end;
}

.TaskModal__module {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
  .avatar {
    width: 36px;
    height: 36px;
  }
}

.tm__button {
  .retroButton {
    &:hover {
      background: $secondary3;
    }
  }
}

.tm__delete {
  .retroButton {
    &:hover {
      background: $danger;
      color: white;
    }
  }
}

// .tm__content {
//   display: flex;
//   z-index: 400;
//   flex-direction: column;
//   justify-content: space-between;
//   height: 100%;
// }
// .tm__durationest {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   background: $body;
//   border: 1px solid $bodyGray;
//   border-radius: 10px;
//   align-items: center;
//   ::placeholder {
//     font-size: 8px;
//   }
//   input {
//     width: 40px;
//     text-align: center;
//     padding: 0.1rem 0.1rem;
//     padding-left: 0.3rem;
//     border: none;
//     outline: none;
//     background: none;
//     color: #888;
//     font-size: 11px;
//     font-weight: 100;
//   }
//   button {
//     background: none;
//     color: $primary;
//     padding-right: 2px;
//     padding-top: 2px;
//   }
// }

// .duration__container {
//   width: 100%;
//   position: relative;
//   // right: 0px;
//   // top: 40px;
//   display: grid;
//   grid-template-columns: 50px 50px 50px 50px 50px;
//   row-gap: 10px;
//   column-gap: 10px;
//   background: $body;
//   border: 1px solid $secondary2;
//   border-radius: 10px;
//   padding: 10px;
//   z-index: 60;
//   box-shadow: $boxShadow-small !important;
// }
// .duration__tag {
//   background: $secondary3;
//   text-align: center;
//   justify-content: center;
//   align-items: center;
//   border-radius: 10px;
//   font-size: 10px;
//   padding: 0.5em;
//   cursor: pointer;
//   &:hover {
//     background-color: $secondary2;
//   }
// }

@media (max-width: 500px) {
  .modal-content {
    width: 100%;
    margin-top: 70px;
  }
  .modal-content-full {
    width: 100%;
    padding-bottom: 40px;
    overflow-y: auto;
    // height: unset;
    height: 100vh;
  }
  .exit__button {
    margin-right: -8px;
    svg {
      width: 45px;
      height: 45px;
    }
  }
  .twocolumns input {
    width: calc(50vw - 22px);
  }
  .tm__description-box {
    textarea::placeholder {
      font-size: 16px;
    }
  }
  .TaskModal__module {
    label {
      font-size: 12px;
    }
  }
  .tm__milestone {
    column-gap: 6px;
    input {
      width: 16px;
      height: 16px;
    }
    label {
      font-size: 12px;
    }
  }
  .tm__allday {
    column-gap: 6px;
    input {
      width: 16px;
      height: 16px;
    }
    label {
      font-size: 14px;
    }
    .tm__alldaytext {
      font-size: 14px;
    }
  }
  .tm_breadcrumb {
    .tm_select {
      font-size: 16px;
    }
  }
  .twocolumns {
    label {
      font-size: 12px;
    }
  }
  .TaskModal__module {
    .avatar {
      width: 32px;
      height: 32px;
    }
  }
  .TaskModal__Main {
    height: calc(100vh - 150px);
    justify-content: space-between;
  }
}
