.boardNewStatus {
  width: 100%;
}
.bns__header {
  margin-top: 10px;
  margin-left: 10px;
  display: flex;

  svg {
    opacity: 0.1;
    transition: 0.2s ease;
    cursor: pointer;
    margin-top: 3px;
    &:hover {
      opacity: 0.3 !important;
    }
  }
  input {
    opacity: 1;
    background: transparent;
    color: rgb(253, 171, 61);
    font-weight: 800;
    font-size: 21px;
  }
}
.bns__fakeContainer {
  background: rgba(0, 0, 0, 0.2);
  height: 120px;
  border-radius: 10px;
  transition: 0.2s ease;
}
.bns__fakeAddTask {
  margin: 20px;
  opacity: 0.1;
}
