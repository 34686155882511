@import "../../theme.styles.scss";

.chess-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 30px 40px;
  column-gap: 30px;
  background: white;
  border-radius: 0px 8px 8px 8px;
  box-shadow: 0 5px 20px 0 rgba(21, 7, 38, 0.08);
}

.chess__buttongroup {
  display: flex;
  flex-direction: column;
  padding: 0px 30px;
  row-gap: 15px;
  width: 135px;
  //   margin-right: 30px;
}

.chess__button {
  display: flex;
  justify-content: flex-start;
  background: transparent;
  color: #000000;
  border: 1px solid $secondary2;
  font-size: 14px;
  text-align: center;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  svg {
    margin-right: 5px;
  }
  &:hover {
    transform: scale(1.05);
    transition: all 0.1s ease;
    background-color: $secondary3;
  }
}

.chess__theme-settings {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}

.chess__themebutton {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5px;
  background: transparent;
  color: #000000;
  //   border: 1px solid $secondary2;
  text-align: center;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  svg {
    margin-right: 5px;
  }
  label {
    font-size: 10px;
  }
  input {
    cursor: pointer;
  }
  &:hover {
    transition: all 0.1s ease;
    background-color: $secondary3;
  }
}

.chess__forest {
  input:checked {
    background-color: #779952;
  }
  &:hover {
    transition: all 0.1s ease;
    background-color: #779952;
  }
}
.chess__sea {
  input {
    &:checked {
      background: black;
    }
  }
  &:hover {
    transition: all 0.1s ease;
    background-color: #4b7399;
  }
}
.chess__mono {
  input {
    &:checked {
      background: black;
    }
  }
  &:hover {
    transition: all 0.1s ease;
    background-color: $secondary3;
  }
}
.chess__coffee {
  input {
    &:checked {
      background: black;
    }
  }
  &:hover {
    transition: all 0.1s ease;
    background-color: #b58863;
  }
}

.chess__gameover-message {
  display: flex;
  flex-direction: column;
  padding: 60px 0px;
  row-gap: 15px;
  transition: all 0.3s ease-in;
  h4 {
    text-decoration: underline;
    background: #4b7399;
    padding: 5px;
    color: white;
    border-radius: 4px;
    align-items: center;
    text-align: center;
    border: 3px solid #eae9d2;
    outline: 3px solid #4b7399;
  }
}
