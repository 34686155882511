@import "../../theme.styles.scss";
.message {
  display: flex;
  align-items: flex-start;
  border-radius: 10px;
  // padding: 5px 2px;
  // margin-bottom: 10px;

  .avatar {
    margin-right: 10px;
    width: 25px;
    height: 25px;
    margin-top: 6px;
  }
}
.message__msg {
  padding: 5px 8px;
  background: white;
  border-radius: 10px 4px 10px 4px;
}
.message__user {
  display: flex;
  align-items: center;
  column-gap: 8px;
  // justify-content: space-between;
  // .message__user-name {
  //   margin-right: 20px;
  // }
}
.message__user-time {
  p {
    font-size: x-small;
    color: rgb(167, 166, 166);
  }
}
