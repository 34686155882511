@import "../../theme.styles.scss";

.conceptView {
  display: flex;
  padding: 0px 20px 10px 0px;
  height: calc(100vh - 91px);
}

.conceptContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.newConceptModal {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.conceptTab__InputRow {
  display: flex;
  column-gap: 15px;
  input {
    flex: 1;
    padding: 5px 8px;
    border-radius: 6px;
    border: 1px solid $primary;
    max-width: 70%;
  }
}

.files__drives {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  .cal__gCal-tip {
    height: 0px;
    display: none;
    opacity: 0;
    transition: all 0.6s ease;
    font-size: 12px;
  }
  &:hover {
    .cal__gCal-tip {
      height: 240px;
      display: flex;
      flex-direction: column;
      opacity: 1;
      transition: all 0.6s ease;
      ul {
        margin-left: 1em;
      }
      li {
        list-style: disc outside none;
        display: list-item;
        margin-left: 1em;
        font-size: 11px;
        padding: 4px;
      }
    }
  }
}
.files__gDrive-list {
  p {
    font-size: 14px;
  }
}
.files__gDrive-ul {
  font-size: 14px;
  padding-left: 20px;
}
// .cal__gCal-Input {
//   display: flex;
//   flex-direction: row;
//   column-gap: 5px;
//   padding-right: 20px;
//   justify-content: space-between;
//   align-items: center;
//   input {
//     width: 130px;
//     border-radius: 5px;
//     border: 1px solid $secondary3;
//     outline: none;
//     padding: 2px 3px;
//   }
//   .cal__gCal-clearBtn {
//     right: 0px;
//   }
// }

.filesListCol-showas {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.filesListCol-showas-options {
  display: flex;
  column-gap: 10px;
}

.filesListCol-radio-line {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.filesListCol {
  width: 200px;
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  overflow-y: auto;
}

.files__list {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.files__list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.files__list-title {
  display: flex;
  column-gap: 5px;
}

.files__list-btngroup {
  display: flex;
  column-gap: 20px;
  button {
    display: flex;
  }
}
.files__savebtn {
  svg {
    width: 36px;
    height: 36px;
  }
}

.files__gDrive-Form {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 10px;
  input {
    border-radius: 6px;
    padding: 5px 10px;
  }
  li {
    font-size: 12px;
  }
}

// .cal__allprojects-btn {
//   display: flex;
//   flex-direction: row;
//   column-gap: 5px;
//   align-items: center;
//   padding: 3px 5px;
//   border-radius: 8px;
//   cursor: pointer;
//   &:hover {
//     color: black;
//     background: $secondary3;
//   }
// }

.concept_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 5px;
  overflow: hidden;
  // overflow-y: auto;
}

.drive_container {
  display: flex;
  flex-direction: column;
  // width: calc(100% - 200px);
  // padding: 30px;
  row-gap: 10px;
  // min-height: 42vh;
  summary {
    background: white;
    box-shadow: $boxShadow-small;
    width: fit-content;
    padding: 10px 30px;
    border-radius: 12px 12px 0px 0px;
    font-weight: 500;
  }
}

.iframe_container {
  display: flex;
  // width: calc(100% - 200px);
  height: 100%;
  background: white;
  box-shadow: $boxShadow-small;
  border-radius: 0px 12px 12px 12px;
}

iframe {
  border: none;
}

// .files__gDrive-addsection {
//   display: flex;
//   flex-direction: column;
//   width: 100%;
// }

.files__gDrive-addsection summary {
  padding: 0.5rem 1rem;
  display: flex;
  width: 100%;
  background: white;
  border-radius: 6px;
  padding-left: 2.2rem;
  position: relative;
  cursor: pointer;
}

.files__gDrive-addsection summary:before {
  content: "\203A";
  align-items: center;
  // color: black;
  // border-width: 0.4rem;
  // border-style: solid;
  // border-color: transparent transparent transparent black;
  position: absolute;
  // top: 1.3rem;
  left: 1rem;
  transform: rotate(0);
  transform-origin: 0.2rem 50%;
  transition: 0.5s transform ease;
}

/* THE MAGIC 🧙‍♀️ */
.files__gDrive-addsection details[open] > summary:before {
  transform: rotate(90deg);
}

.files__gDrive-addsection details summary::-webkit-details-marker {
  display: none;
}

.excalidraw {
  --color-primary-light: #1a82c4;
  --color-primary: #1a82c4;
  --color-primary-darker: #ffffff;
}

// .concept_container .excalidraw {
//   --color-primary-light: #1a82c4;
//   // --color-primary: #1a82c4;
//   --color-primary-darker: #ffffff;
// }

// .concept_container-active .excalidraw {
//   --color-primary-light: #1a82c4;
//   // --color-primary: #1a82c4;
//   --color-primary-darker: #ffffff;
// }

// .concept_container-active .excalidraw.theme--dark {
//   --color-primary: pink;
// }

.concept__header-tabs {
  position: relative;
  display: flex;
  column-gap: 5px;
  height: 28px;
  // justify-content: flex-end;
  align-items: flex-end;
  // transform: translateY(-36px);
  overflow-x: auto;
  overflow-y: hidden;
}

.concept__header-tabs::-webkit-scrollbar {
  display: none;
}

.conceptTab__Inputs {
  display: flex;
  column-gap: 10px;
}
.conceptTab__Input-radio {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.widgetheader {
  form {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
  input[type="text"] {
    max-width: 100px;
  }
}

.concept_tab-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border: 1px solid $secondary2;
  border-top: 1px solid #1a82c4;
  background: #1a82c4;
  color: white;
  padding: 5px 10px;
  border-radius: 0px 0px 5px 5px;
  align-items: center;
  box-shadow: rgb(0 0 0 / 20%) 4px 5px 10px -5px;
  // border: 1px solid black;
  // display: flex;
  justify-content: space-between;
  column-gap: 10px;
  width: fit-content;
  min-width: fit-content;
  z-index: 5;
  cursor: pointer;
  // border: 1px solid black;
  // z-index: 5;
  .files__list-btngroup {
    svg {
      opacity: 0;
      color: white;
      transition: all 0.2s ease-in;
    }
  }
  &:hover {
    .files__list-btngroup {
      svg {
        opacity: 100;
        color: white;
        transition: all 0.2s ease-in;
      }
    }
  }
  .files__list-title h5 {
    font-size: 14px;
    font-weight: 550;
  }
}

.concept_tab-header-nonactive {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border: 1px solid $secondary2;
  // border-top: 1px solid white;
  // border-top: none;
  background: white;
  padding: 5px 10px;
  border-radius: 0px 0px 5px 5px;
  align-items: center;
  box-shadow: rgb(0 0 0 / 20%) 4px 5px 10px -5px;
  // border: 1px solid black;
  // display: flex;
  justify-content: space-between;
  column-gap: 10px;
  width: fit-content;
  min-width: fit-content;
  z-index: 5;
  cursor: pointer;
  // border: 1px solid black;
  // z-index: 5;
  font-weight: 550;
  .files__list-btngroup {
    font-weight: 550;
    svg {
      opacity: 0;
      transition: all 0.2s ease-in;
    }
  }
  &:hover {
    .files__list-btngroup {
      svg {
        opacity: 100;
        transition: all 0.2s ease-in;
      }
    }
  }
  .files__list-title h5 {
    font-size: 14px;
    font-weight: 500;
  }
}

.addconcept_tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 0px 10px;
  border: 1px solid $secondary2;
  border-radius: 0px 0px 5px 5px;
  height: 28px;
  box-shadow: rgb(0 0 0 / 20%) 4px 5px 10px -5px;
  form {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
  input[type="text"] {
    max-width: 120px;
    margin-left: 5px;
    padding: 0px 4px;
  }
}

.concept_container {
  height: 0;
  // border-radius: 8px 8px 0px 8px;
}

// .concept_container:not(:first-child) {
//   height: calc(100vh - 150px);
//   margin-top: -100%;
// }

.concept_container-active {
  height: calc(100vh - 130px);
  // outline: 1px solid $secondary3;
  border-radius: 0 8px 8px 0px;
  .excalidraw-container {
    border-radius: 0 8px 8px 0px;
    box-shadow: 2px 2px 8px 1px #00000021;
  }
  // display: flex;
  // align-items: center;
  // justify-content: center;
}

@media (max-width: 500px) {
  .conceptView {
    height: calc(100vh - 130px);
    flex-direction: column-reverse;
    row-gap: 30px;
    padding: unset;
  }
  iframe {
    border: none;
  }
  .iframe_container {
    height: 150vh;
  }
  .filesListCol {
    flex-direction: row;
    column-gap: 30px;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    overflow-y: unset;
  }
  .drives_container {
    width: 100%;
  }
  .drive_container {
    padding: 20px;
    width: 100%;
  }
  .filesListCol-showas-options {
    flex-direction: column;
    row-gap: 20px;
  }
  .files__gDrive-addsection {
    max-width: 160px;
  }
  .conceptTab__InputRow {
    flex-direction: column;
    row-gap: 20px;
    input {
      max-width: unset;
    }
  }
  .conceptTab__Inputs {
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
    justify-content: space-between;
  }
}
