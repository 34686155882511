@import "../../../theme.styles.scss";
.sec__features {
  display: flex;
  padding: 100px 10%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 60px;
  width: 100vw;
  overflow: hidden;
  p {
    font-size: 17px;
    line-height: 30px;
  }
  h2 {
    font-size: 28px;
    line-height: 40px;
    font-weight: 600;
  }
  h3 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
  }
  h4 {
    font-size: 20px;
    line-height: 32px;
    font-weight: 600;
  }
}

.sec_feature {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  div {
    width: 800px;
  }
  section {
    width: 800px;
  }
}

.sec_feature-stacked {
  display: flex;
  padding: 40px 0px;
  // :nth-child(even) {
  //   flex-direction: row-reverse;
  // }
  :first-child {
    z-index: 5;
  }
  :nth-child(2) {
    z-index: 4;
    margin-top: 1rem;
  }
  :nth-child(3) {
    z-index: 3;
    margin-top: 2rem;
  }
  :nth-child(4) {
    z-index: 2;
    margin-top: 3rem;
  }
  :nth-child(5) {
    z-index: 1;
    margin-top: 4rem;
  }
}

.sec_feature-stacked-card {
  display: flex;
  border-radius: 12px;
  background: white;
  box-shadow: $boxShadow-small;
  padding: 20px 30px;
  transition: 0.4s;
  width: 50%;
  column-gap: 30px;
  &:hover {
    z-index: 6;
  }
  height: 80%;
  // div {
  //   width: 50%;
  //   height: 100%;
  // }
}

.sec_feature-stacked-card:not(:first-child) {
  margin-left: -200px;
  flex-direction: row-reverse;
}

.sec_feature-stacked-card:hover,
.sec_feature-stacked-card:focus-within {
  transform: translateY(-0.5rem);
  ~ .card {
    transform: translateX(2rem);
  }
}
// .sec_feature-stacked-card:not(:last-child):hover,
// .sec_feature-stacked-card:not(:last-child):focus-within {
//   transform: translateY(-1rem);
//   ~ .card {
//     transform: translateX(2rem);
//   }
// }

.sec_feature-stacked-card-inner {
  width: 40%;
  column-gap: 30px;
}
.sec_feature-stacked-card-inner-content {
  width: 60%;
  a {
    font-weight: 600;
  }
}

// .itemm {
//   height: 40rem;
//   width: 30rem;
//   padding: 40px;
// }
// .itemm img {
//   width: 100%;
//   height: 100%;
//   border-radius: 2rem;
//   // pointer-events: none;
// }

// .inner-carousel {
//   display: flex;
//   background: lightblue;
//   width: 130vw;
//   column-gap: 10px;
//   padding: 40px 60px;
// }

// .carousel {
//   cursor: grab;
//   overflow: hidden;
//   width: 0px;
//   background: rgb(230, 183, 113);
// }

.itemm {
  height: 40rem;
  width: 30rem;
  padding: 40px;
}
.itemm img {
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  // pointer-events: none;
}

.inner-carousel {
  display: flex;
  background: lightblue;
  width: fit-content;
  column-gap: 10px;
  padding: 40px 60px;
}

.carousel {
  cursor: grab;
  overflow: hidden;
  width: max-content;
  background: rgb(230, 183, 113);
  width: 100%;
}

.sec_feature-card {
  border-radius: 12px;
  background: white;
  box-shadow: $boxShadow-small;
  width: 300px;
  height: 360px;
  padding: 20px 30px;
  // display: flex;
}

@media (max-width: 768px) {
  .sec__features {
    padding: 50px 20px;
    padding-top: 420px;
  }
  .sec_feature-stacked {
    flex-direction: column;
    width: 100%;
    row-gap: 40px;
    // padding: 0px 0px;
  }
  .sec_feature-stacked-card:not(:first-child) {
    margin-left: 0px;
  }
  .sec_feature-stacked-card:hover,
  .sec_feature-stacked-card:focus-within {
    transform: translateY(none);
    ~ .card {
      transform: translateX(none);
    }
  }
  .sec_feature-stacked-card {
    width: 100%;
    :nth-child(3) {
      flex-direction: row-reverse;
    }
    :nth-child(5) {
      flex-direction: row-reverse;
    }
  }
  .sec_feature-stacked-card:nth-child(2) {
    margin-top: 0rem;
  }
  .sec_feature-stacked-card:nth-child(3) {
    margin-top: 0rem;
  }
  .sec_feature-stacked-card:nth-child(4) {
    margin-top: 0rem;
  }
  .sec_feature-stacked-card:nth-child(5) {
    margin-top: 0rem;
  }
  .sec_feature-stacked-card:nth-child(3) {
    flex-direction: row;
  }
  .sec_feature-stacked-card:nth-child(5) {
    flex-direction: row;
  }
}

@media (max-width: 500px) {
  .sec__features {
    flex-direction: column;
  }
}
