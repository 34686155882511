@import "../../theme.styles.scss";

.invitetoPremium {
//   .retroBox {
//     position: relative;
//     width: 70%;
//     justify-content: center;
//     left: 140px;
//     bottom: 140px;
//   }
    .retroBox {
      position: relative;
      max-width: 50%;
      top: 100px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  b {
    color: black;
    font-weight: 600;
  }
  i {
    color: black;
    font-weight: 600;
  }
  a {
    color: black;
    font-weight: 600;
  }
  p {
    font-size: 16px;
    line-height: 1.6;
    color: $primary;
    margin-bottom: 20px;
  }
  h2 {
    margin-bottom: 30px;
    text-align: center;
  }
  img {
    max-height: 270px;
    max-width: 320px;
    height: 100%;
  }
}
.invPremium__inner {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  align-items: center;
}
.cs__createPop {
  width: 550px;
}
.cs__create-btn {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 500px) {
  .cs__createPop {
    width: 94% !important;
  }
}
