@import "../../theme.styles.scss";
.noSpaceData {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  max-width: 200px;
  padding: 40px 20px;
  svg {
    opacity: 0.1;
    margin-bottom: 15px;
  }

  p {
    color: #999;
    span {
      color: $info;
      opacity: 0.8;
      &:hover {
        color: $info;
        opacity: 1;
      }
    }
  }
}
.nsd__createBtn {
  position: absolute;
  bottom: -13px;
  right: -20px;
  button {
    height: 40px;
  }
}
.nsd__span {
  cursor: pointer;
  &:hover {
    color: black;
  }
}
