@import "../../../theme.styles.scss";

.client {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 250;
  color: #333;
  align-items: flex-end;
  padding-bottom: 50px;
  overflow-y: auto;
}

.client-backdrop {
  // background: rgba(0, 0, 0, 0.2);
  // -webkit-backdrop-filter: blur(0.2px);
  // backdrop-filter: blur(0.2px);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
}

.client-content {
  background: white;
  -webkit-backdrop-filter: blur(0.2px);
  backdrop-filter: blur(0.2px);
  padding: 50px 40px 30px 40px;
  border-radius: 5px;
  // width: 100%;
  // height: 100%;
  position: absolute;
  box-shadow: rgb(0 0 0 / 20%) 0px 9px 30px -10px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 40%;
  max-width: 450px;
  z-index: 200;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.activitycal-container {
  display: flex;
  padding-left: 36px;
  // width: 100%;
}

.client-form-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    width: 20px;
    height: 20px;
  }
}

.client-form-header-link {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.client-form {
  display: flex;
  column-gap: 10px;
  input {
    padding-left: 10px;
  }
  .client-progress-icon {
    margin-top: 6px;
    svg {
      width: 32px;
      height: 32px;
    }
  }
}

.client-templates {
  overflow-y: auto;
}

.clientinfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 5px;
  cursor: pointer;
}
.clientline {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.clientsettings {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 20px;
  // padding-top: 10px;
  input {
    border-radius: 5px;
    border: 1px solid $secondary2;
    width: 100%;
  }
  textarea {
    border-radius: 5px;
    border: 1px solid $secondary2;
    width: 100%;
    padding: 5px 10px;
  }
  select {
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid $secondary2;
  }
}

.clientnameinput {
  // width: 100%;
  // padding: 5px 15px;
  font-size: 18px;
  font-weight: 400;
  input {
    padding: 5px 10px;
    height: 36px;
  }
}

.clientoptions {
  display: flex;
  column-gap: 10px;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  input {
    min-height: 24px;
  }
}

.clientbtns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  column-gap: 20px;
}

.ClientEmojiPick {
  text-align: center;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  span {
    font-size: x-large;
    background: $secondary3;
    border-radius: 100%;
    padding: 5px;
    align-items: center;
    justify-content: center;
  }
  h5 {
    font-size: 14px;
    font-weight: 500;
  }
}

.client-templates details {
  width: 100%;
  margin: 0 auto;
  // background: #282828;
  margin-bottom: 0.5rem;
  box-shadow: 0 0.1rem 0.8rem -0.5rem rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  overflow: hidden;
  .clientline {
    padding-left: 2rem;
  }
}

.client-templates summary {
  padding: 0.5rem 1rem;
  display: flex;
  width: 100%;
  // background: #333;
  padding-left: 2.2rem;
  position: relative;
  cursor: pointer;
}

.client-templates summary:before {
  content: "\203A";
  align-items: center;
  // color: black;
  // border-width: 0.4rem;
  // border-style: solid;
  // border-color: transparent transparent transparent black;
  position: absolute;
  // top: 1.3rem;
  left: 1rem;
  transform: rotate(0);
  transform-origin: 0.2rem 50%;
  transition: 0.5s transform ease;
}

/* THE MAGIC 🧙‍♀️ */
.client-templates details[open] > summary:before {
  transform: rotate(90deg);
}

.client-templates details summary::-webkit-details-marker {
  display: none;
}

.client-templates details > ul {
  padding-bottom: 1rem;
  margin-bottom: 0;
}

.client__exit__button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  margin-top: 4px;
  color: $secondary2;
  align-self: end;
  stroke-width: 5px;
  transform: rotate(-45deg);
  svg {
    width: 36px;
    height: 36px;
  }
  :hover {
    color: $primary;
  }
}

.client-history summary {
  padding: 0.5rem 0rem;
  display: flex;
  width: 100%;
  // background: #333;
  // padding-left: 2.2rem;
  position: relative;
  cursor: pointer;
}

.client-history summary:after {
  content: "\203A";
  align-items: center;
  // color: black;
  // border-width: 0.4rem;
  // border-style: solid;
  // border-color: transparent transparent transparent black;
  // position: absolute;
  // top: 1.3rem;
  // right: 1rem;
  margin-left: 10px;
  transform: rotate(0);
  transform-origin: 0.2rem 50%;
  transition: 0.5s transform ease;
}

/* THE MAGIC 🧙‍♀️ */
.client-history details[open] > summary:after {
  transform: rotate(90deg);
}

.client-history details summary::-webkit-details-marker {
  display: none;
}

.client-archive-btn {
  display: flex;
  align-items: center;
  // width: 50px;
  justify-content: center;
}

.client__projects-sec {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.client__projects {
  display: flex;
  column-gap: 20px;
  font-size: 0.8em;
  overflow-x: auto;
}

.client__projects-header {
  display: flex;
  column-gap: 20px;
  justify-content: space-between;
  align-items: center;
}



.client__projects::-webkit-scrollbar {
  display: none;
}

.client__projects::-webkit-scrollbar-track {
  height: 0px;
}

.client__project {
  display: flex;
  column-gap: 10px;
  background: $secondary3;
  border-radius: 4px;
  align-items: center;
  padding: 3px 5px;
  white-space: nowrap;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 14px;
      height: 14px;
    }
  }
}

@media (max-width: 500px) {
  .client {
    padding-bottom: 42px;
  }
  .client-content {
    height: calc(100vh - 80px);
    width: 100vw;
    padding: 30px 20px;
    padding-top: 50px;
  }
  .client-form {
    padding: 0px;
    column-gap: 10px;
  }
}
