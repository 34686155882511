@import "../../theme.styles.scss";
.welcomePage {
  display: flex;
  width: 100vw;
  flex-direction: column;
  overflow-y: show;
  overflow-x: hidden;
  .firsthalf {
    // position: absolute;
    left: 0px;
    width: 50vw;
    display: flex;
    // width: 60vw;
    // height: 100vh !important;
    // overflow: hidden;
    img {
      width: 400px;
      // width: 100%;
      object-fit: cover;
      border-radius: 12px;
    }
  }
  .secondhalf {
    // width: 50%;
    // position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0px;
    width: 40vw;
    height: 100vh !important;
    padding: 20px, 20px;
    z-index: 5;
    // padding: 20px;
    background: linear-gradient(
      200deg,
      rgba(197, 197, 197, 0.5) 30%,
      rgba(22, 22, 22, 0) 100%
    );
  }

  ul#scene {
    height: 70%;
    width: 100%;
    left: -50px;
    position: absolute !important;
    bottom: -10px;
    li {
      width: 100%;
      height: 100%;
      img {
        height: 100%;
      }
    }
  }
  img {
  }
  .layer1 {
    img {
      position: absolute;
      left: 0%;
    }
  }
  .layer2 {
    img {
      position: absolute;
      left: 0%;
    }
  }
  .layer3 {
    img {
      position: absolute;
      left: 0%;
    }
  }
  .layer4 {
    img {
      position: absolute;
      left: 0%;
    }
  }
  .layer-rocket {
    img {
      position: absolute;
    }
  }
  .layer-startUp-li {
  }
  .layer-startUp-container {
    // height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 30px;
  }

  .layer-startUp {
    z-index: 200;
    pointer-events: all;
    background: $body;
    padding: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    button {
    }
    h1 {
      font-size: 70px;
      margin-bottom: 0px;
      color: $primary;
      font-family: "Spartan", monospace;
    }
    h3 {
      color: $secondary;
      color: $primary;
      font-size: 24px;
    }
    h4 {
      color: $secondary;
      color: $primary;
      font-size: 12px;
    }
    img {
      width: 300px;
      object-fit: cover;
    }
  }
  .retroBox {
    padding: 0 !important;
  }
}
.welcomePage__message {
  margin-bottom: 40px;
  align-items: center;
  text-align: center;
  h3 {
    font-size: 45px;
  }
  h5 {
    margin-bottom: 10px;
  }
}

.wp__btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    width: fit-content;
    height: 40px;
  }
  span {
    margin: 0 30px;
  }
}
.wp__btns-register {
  button {
    background: $secondary;
    color: $body;
    div {
      font-size: 16px;
      font-weight: 550;
    }
  }
}
.wp__btns-try {
  button {
    color: $primary;
  }
}

.welcomePage__window {
  width: 40%;
  position: absolute;
  height: 100%;
  z-index: 200;
  top: 0;
  right: 0;
  background: white;
}

// #product {
//   padding-top: 130px;
// }

.footer__socials {
  display: flex;
  width: 200px;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  svg {
    width: 1.2em;
    height: 1.2em;
    &:hover {
      cursor: pointer;
      color: #1a82c4;
    }
  }
}
.footer__bottom {
  display: flex;
  height: 50px;
  margin-top: 30px;
  justify-content: space-between;
  // border-top: 1px solid #ebebeb;
  align-items: center;
  svg {
    &:hover {
      cursor: pointer;
      color: #1a82c4;
    }
  }
  button {
    font-size: 16px;
  }
}

.welcomePage {
  details {
    background-color: #fff;
    color: #303030;
    font-size: 1.5rem;
  }

  summary {
    padding: 0.5em 1.3rem;
    list-style: none;
    display: flex;
    justify-content: space-between;
    transition: height 1s ease;
  }

  summary::-webkit-details-marker {
    display: none;
  }

  summary:after {
    content: "\002B";
  }

  details[open] summary {
    border-bottom: 1px solid #aaa;
    margin-bottom: 0.5em;
  }

  details[open] summary:after {
    content: "\00D7";
  }

  details[open] div {
    padding: 0.5em 1em;
  }
}

.nav-item {
  align-self: center;
}

.nav-item a:hover {
  padding-bottom: 6px;
  border-bottom: 3px solid #1a82c4;
  cursor: pointer;
}

.nav-item .active {
  padding-bottom: 6px;
  border-bottom: 3px solid #1a82c4;
  transition: all 0.2s;
}

.nav-item .nash-active .active {
  padding-bottom: 6px;
  border-bottom: 3px solid #1a82c4;
  transition: all 0.2s;
}

.nav-item-btns {
  display: flex;
  align-items: center;
  column-gap: 20px;
  padding: 20px 0px;
  margin: 0px 0px 0px 20px;
  //  align-self: center;
  .retroButton {
    div {
      font-weight: 600;
    }
  }
  @media (max-width: 768px) {
    display: none;
    // flex-direction: column;
    // row-gap: 30px;
    // margin: 0px 20px;
  }
}

.navbar__mobile-signbtns {
  // width: 100%;
  display: none;
  flex-direction: row;

  @media (max-width: 768px) {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    column-gap: 20px;
    align-items: center;
    justify-content: flex-end;
    margin-right: 60px;
  }
}

@media (max-width: 760px) {
  .welcomePage .layer-startUp {
    padding: 60px 40px;
  }
}

@media (max-width: 500px) {
  .welcomePage {
    flex-direction: column;
    // .firsthalf {
    //   height: 40vh !important;
    //   width: 100vw;
    // }
    .secondhalf {
      height: 60vh !important;
      width: 100vw;
    }
    ul#scene {
      display: none;
    }
  }
  .layer-startUp-container {
    height: 90%;
  }
  .antLogy__logo-container {
    width: 92% !important;
    img {
      object-fit: cover;
    }
  }
}
